import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { UnikeyCredentialsInputDTORuntype, UnikeyResponseBodyDTORuntype } from './unikeyBackendTypes';

export const unikeyEndpoints = {
    'GET /3rdparty/unikey/credentials': endpoint({
        description: 'Returns credentials for Unikey integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: UnikeyResponseBodyDTORuntype,
    }),
    'POST /3rdparty/unikey/credentials': endpoint({
        description: 'Uploads credentials for Unikey API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: UnikeyCredentialsInputDTORuntype,
        responseBody: UnikeyResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/unikey/credentials'],
    }),
    'PATCH /3rdparty/unikey/credentials': endpoint({
        description: 'Updates credentials for Unikey API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: UnikeyCredentialsInputDTORuntype,
        responseBody: UnikeyResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/unikey/credentials'],
    }),
    'DELETE /3rdparty/unikey/credentials': endpoint({
        description: 'Deletes existing credentials for Unikey API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/unikey/credentials'],
    }),
};
