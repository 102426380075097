import { t, Trans } from '@lingui/macro';
import {
    compareByStringKey,
    formatCurrency,
    formatDecimal,
    formatLocalDate,
    isPresent,
    uniqBy,
} from '@luminovo/commons';
import {
    ColumnBuilder,
    DataGrid,
    DataGridColumn,
    DataGridReadonlyContextProvider,
    useDataGridContext,
} from '@luminovo/data-grid';
import {
    chainComparators,
    colorSystem,
    FieldSelect,
    SecondaryButton,
    SecondaryIconButton,
    TertiaryIconButton,
    Text,
} from '@luminovo/design-system';
import { Validated } from '@luminovo/fields';
import { ComplianceStatus, ItemClass, OtsFullPart, Packaging, PartLite, RegionsEnum } from '@luminovo/http-client';
import {
    formatComplianceStatus,
    formatPackaging,
    formatPartLite,
    formatQuantity,
    formatRegionAsName,
} from '@luminovo/sourcing-core';
import * as icons from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { FormFieldConfiguration, FormFieldsConfiguration, PdfOfferLineItem } from '../types';
import { columnId, convertOtsFullPartToPartLite } from './PdfOfferImporter/model';
import { OfferImporterState, RowAction } from './PdfOfferImporter/state';
import { formatLeadTimeUnit, LeadTimeUnit, Region, scrollToRegions, usePdfViewerState } from './PdfViewer';
import { convertPdfOfferLineItemToPartialOfferLineItem } from './utils/convertPdfOfferLineItemToPartialOfferLineItem';

function getCellStyle(validated?: Validated<unknown>) {
    if (!validated) {
        return { backgroundColor: 'transparent' };
    }
    if (validated.status === 'error') {
        return { backgroundColor: colorSystem.red[1] };
    }
    if (validated.status === 'success' && validated.message) {
        return { backgroundColor: colorSystem.yellow[1] };
    }
    return { backgroundColor: 'transparent' };
}

type DispatchRowAction = (action: RowAction) => void;

export function DataGridPdfOfferLine({
    rows,
    requestedParts,
    formState,
}: {
    rows: PdfOfferLineItem[];
    requestedParts: OtsFullPart[];
    formState: OfferImporterState;
}): JSX.Element {
    const [, pdfViewerDispatch] = usePdfViewerState();

    const columnPart = createColumnPart(requestedParts);
    const dispatch = useSetAtom(formState.fields.$rows);
    const formFieldsConfiguration = useAtomValue(formState.$formFieldsConfiguration);
    const defaultCurrency = useAtomValue(formState.fields.$defaultCurrency).value;
    const validFrom = useAtomValue(formState.fields.$validFrom).value;
    const validUntil = useAtomValue(formState.fields.$validUntil).value;

    const columnDefs = getVisibleColumns({
        formFieldsConfiguration,
        requestedParts,
    });

    return (
        <DataGridReadonlyContextProvider rowData={rows}>
            <style>
                {`
                    .readonly-header {
                        background: linear-gradient(to bottom right, ${colorSystem.neutral[0]}, ${colorSystem.neutral[1]});
                    }
                `}
            </style>
            <Box
                sx={{
                    display: 'flex',
                    padding: 1,
                    gap: 1,
                    alignItems: 'center',
                    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
                }}
            >
                <ErrorFilters rows={rows} />
                <DataGridSelection formState={formState} />

                <span style={{ flex: 1 }} />
                <ButtonToggleLayout state={formState} />

                <ButtonAddRow formState={formState} />
            </Box>
            <DataGrid
                context={{
                    dispatch,
                    defaultCurrency,
                    validFrom,
                    validUntil,
                }}
                rowSelection={{
                    mode: 'multiRow',
                    selectAll: 'filtered',
                }}
                getRowId={(params) => params.data.rowId}
                onAsyncTransactionsFlushed={(data) => {
                    const updatedRows: PdfOfferLineItem[] = data.results
                        .flatMap((result) => result.update)
                        .map((update) => update?.data)
                        .filter(isPresent);
                    const partialOfferLineItems = updatedRows.map((item) =>
                        convertPdfOfferLineItemToPartialOfferLineItem(item),
                    );
                    dispatch({
                        type: 'updateRows',
                        rows: partialOfferLineItems,
                    });
                }}
                onCellFocused={({ column, rowIndex, api }) => {
                    if (!column || typeof column === 'string' || rowIndex === null) {
                        return;
                    }

                    const gridRow = api.getDisplayedRowAtIndex(rowIndex);

                    const row = gridRow?.data;
                    const source = row?.source;
                    if (!row || !source) {
                        return;
                    }

                    function highlightRegion(regions: Region[]) {
                        scrollToRegions(regions);
                        pdfViewerDispatch({
                            type: 'setMode',
                            mode: {
                                type: 'inspect',
                                attribute: 'part',
                                highlightBox: source?.boundingBox,
                                pageNumber: source?.pageNumber ?? 1,
                                selectedRegionIds: regions.map((r) => r.id),
                            },
                        });
                    }

                    const { part, moq, mpq, unitPrice, standardFactoryLeadTime, stock, packaging } = source;
                    if (column.getColId() === columnPart.colId && part) {
                        highlightRegion(part.regions);
                    }
                    if (column.getColId() === columnMoq.colId && moq) {
                        highlightRegion(moq.regions);
                    }
                    if (column.getColId() === columnMpq.colId && mpq) {
                        highlightRegion(mpq.regions);
                    }
                    if (column.getColId() === columnUnitPrice.colId && unitPrice) {
                        highlightRegion(unitPrice.regions);
                    }
                    if (column.getColId() === columnLeadTime.colId && standardFactoryLeadTime) {
                        highlightRegion(standardFactoryLeadTime.regions);
                    }
                    if (column.getColId() === columnLeadTimeUnit.colId && standardFactoryLeadTime) {
                        highlightRegion(standardFactoryLeadTime.regions);
                    }
                    if (column.getColId() === columnStock.colId && stock) {
                        highlightRegion(stock.regions);
                    }
                    if (column.getColId() === columnPackaging.colId && packaging) {
                        highlightRegion(packaging.regions);
                    }
                }}
                columnDefs={columnDefs}
            />
        </DataGridReadonlyContextProvider>
    );
}

function ButtonAddRow({ formState }: { formState: OfferImporterState }) {
    const dispatch = useSetAtom(formState.fields.$rows);
    const { api, setQuickFilter } = useDataGridContext<PdfOfferLineItem>();
    return (
        <SecondaryButton
            startIcon={<icons.Add fontSize="inherit" />}
            size="medium"
            onClick={() => {
                setQuickFilter('');
                dispatch({ type: 'addEmptyRowBelow' });
                api?.ensureNodeVisible((row) => {
                    // essentially, ensure the first row is visible
                    return true;
                }, 'bottom');
            }}
        >
            <Trans>Add row</Trans>
        </SecondaryButton>
    );
}

function FilterButton({
    label,
    count,
    onToggle,
    selected,
    icon,
    value,
}: {
    label: string;
    count: number;
    value: string;
    onToggle: (value: string) => void;
    selected: boolean;
    icon: React.ReactNode;
}) {
    return (
        <Tooltip title={selected ? t`Clear filter` : t`Filter by: ${value}`}>
            <SecondaryButton
                sx={{
                    borderRadius: 5,
                    backgroundColor: selected ? colorSystem.primary[5] : colorSystem.neutral.white,
                    color: selected ? colorSystem.neutral[0] : undefined,
                    transition: 'all 0.2s ease',
                    transform: selected ? 'scale(1.02)' : 'scale(1)',
                    boxShadow: selected ? `0 2px 4px rgba(0,0,0,0.15)` : 'none',
                    '&:hover': {
                        backgroundColor: selected ? colorSystem.primary[6] : colorSystem.neutral[1],
                        color: selected ? colorSystem.neutral[0] : undefined,
                        border: selected
                            ? `1px solid ${colorSystem.primary[7]}`
                            : `1px solid ${colorSystem.neutral[2]}`,
                    },
                    border: selected ? `1px solid ${colorSystem.primary[7]}` : `1px solid ${colorSystem.neutral[2]}`,
                }}
                onClick={() => {
                    if (selected) {
                        onToggle('');
                    } else {
                        onToggle(value);
                    }
                }}
                size="medium"
                startIcon={selected ? <icons.Clear /> : icon}
            >
                {label} {formatDecimal(count)}
            </SecondaryButton>
        </Tooltip>
    );
}

function ErrorFilters({ rows }: { rows: PdfOfferLineItem[] }) {
    const { setQuickFilter, quickFilter } = useDataGridContext<PdfOfferLineItem>();
    const topErrors = getTopErrors(rows);
    const countErrors = rows.filter((row) => row.row.status === 'error').length;
    const countOk = rows.filter((row) => row.row.status === 'success').length;

    return (
        <>
            <FilterButton
                icon={<icons.CheckCircle color="success" />}
                value={t`Ready to bid`}
                onToggle={(quickFilter) => {
                    setQuickFilter(quickFilter);
                }}
                selected={quickFilter === t`Ready to bid`}
                label={t`Ready to bid`}
                count={countOk}
            />
            <FilterButton
                icon={<icons.Error color="error" />}
                value={t`Error`}
                onToggle={(quickFilter) => {
                    setQuickFilter(quickFilter);
                }}
                selected={quickFilter === t`Error`}
                label={t`All errors`}
                count={countErrors}
            />
            {topErrors.map((error) => (
                <FilterButton
                    icon={<icons.Error color="error" />}
                    onToggle={(quickFilter) => {
                        setQuickFilter(quickFilter);
                    }}
                    value={error.message}
                    selected={quickFilter === error.message}
                    key={error.message}
                    label={error.message}
                    count={error.rows.length}
                />
            ))}
        </>
    );
}

function DataGridSelection({ formState }: { formState: OfferImporterState }) {
    const dispatch = useSetAtom(formState.fields.$rows);
    const { api } = useDataGridContext<PdfOfferLineItem>();
    const [selectedRows, setSelectedRows] = useState<PdfOfferLineItem[]>([]);

    React.useEffect(() => {
        api?.addEventListener('selectionChanged', (event) => {
            if (event.type === 'selectionChanged') {
                const selectedRows = event.api.getSelectedRows();
                setSelectedRows(selectedRows);
            }
        });
    }, [api]);

    if (selectedRows.length === 0) {
        return <></>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                backgroundColor: colorSystem.primary[2],
                paddingY: '4px',
                paddingX: 1,
                borderRadius: 5,
                boxSizing: 'border-box',
                border: `1px solid ${colorSystem.primary[7]}`,
            }}
        >
            <Tooltip title={t`Clear selection`}>
                <TertiaryIconButton
                    size="small"
                    onClick={() => {
                        api?.deselectAll('all');
                    }}
                >
                    <icons.ClearRounded fontSize="inherit" color={'action'} />
                </TertiaryIconButton>
            </Tooltip>
            <Text color={colorSystem.neutral[8]} variant="body-small">
                {selectedRows.length} row selected
            </Text>

            <Tooltip title={t`Delete rows`}>
                <TertiaryIconButton
                    size="small"
                    onClick={() => {
                        dispatch({
                            type: 'deleteRow',
                            rowIds: selectedRows.map((row) => row.rowId),
                        });
                    }}
                >
                    <icons.Delete fontSize="inherit" color={'action'} />
                </TertiaryIconButton>
            </Tooltip>
            <Tooltip title={t`Duplicate rows`}>
                <TertiaryIconButton
                    size="small"
                    onClick={() => {
                        dispatch({
                            type: 'duplicateRow',
                            rowIds: selectedRows.map((row) => row.rowId),
                        });
                    }}
                >
                    <icons.CopyAll fontSize="inherit" color={'action'} />
                </TertiaryIconButton>
            </Tooltip>
        </Box>
    );
}

function ButtonToggleLayout({ state }: { state: OfferImporterState }) {
    const [showPdfPanel, setShowPdfPanel] = useAtom(state.$showPdfPanel);
    const [pdfViewerState] = usePdfViewerState();

    if (!pdfViewerState.pdfDocumentProxy) {
        return <></>;
    }

    return (
        <Tooltip title={t`Toggle layout`}>
            <SecondaryIconButton
                size="medium"
                onClick={() => {
                    setShowPdfPanel(!showPdfPanel);
                }}
            >
                <icons.ViewComfy fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
}

function getVisibleColumns({
    formFieldsConfiguration,
    requestedParts,
}: {
    formFieldsConfiguration: FormFieldsConfiguration;
    requestedParts: OtsFullPart[];
}) {
    const columnPart = createColumnPart(requestedParts);

    function toggleColumn(column: DataGridColumn<PdfOfferLineItem>, formFieldConfiguration: FormFieldConfiguration) {
        if (!formFieldConfiguration.visible) {
            return null;
        }
        return column;
    }

    // Create filtered arrays of visible columns for each group
    const requestColumns = [
        toggleColumn(columnRequestedPart, formFieldsConfiguration.requestedPart),
        toggleColumn(columnPartDescription, formFieldsConfiguration.partDescription),
        toggleColumn(columnRequiredQuantity, formFieldsConfiguration.requiredQuantity),
        toggleColumn(columnPotentialQuantity, formFieldsConfiguration.potentialQuantity),
        toggleColumn(columnRecipients, formFieldsConfiguration.recipients),
        toggleColumn(columnTargetPrice, formFieldsConfiguration.targetPrice),
        toggleColumn(columnCustomerName, formFieldsConfiguration.customerName),
    ].filter(isPresent);

    const offerColumns = [
        toggleColumn(columnBid, formFieldsConfiguration.bid),
        toggleColumn(columnPart, formFieldsConfiguration.part),
        toggleColumn(columnUnitPrice, formFieldsConfiguration.unitPrice),
        toggleColumn(columnMoq, formFieldsConfiguration.moq),
        toggleColumn(columnMpq, formFieldsConfiguration.mpq),
        toggleColumn(columnStock, formFieldsConfiguration.stock),
        toggleColumn(columnPackaging, formFieldsConfiguration.packaging),
        toggleColumn(columnLeadTime, formFieldsConfiguration.standardFactoryLeadTime),
        toggleColumn(columnLeadTimeUnit, formFieldsConfiguration.standardFactoryLeadTimeUnit),
        toggleColumn(columnNotes, formFieldsConfiguration.notes),
        toggleColumn(columnCurrency, formFieldsConfiguration.currency),
        toggleColumn(columnNcnr, formFieldsConfiguration.ncnr),
        toggleColumn(columnSupplierPartNumber, formFieldsConfiguration.supplierPartNumber),
        toggleColumn(columnValidFrom, formFieldsConfiguration.validFrom),
        toggleColumn(columnValidUntil, formFieldsConfiguration.validUntil),
        toggleColumn(columnCancellationWindow, formFieldsConfiguration.cancellationWindow),
        toggleColumn(columnCancellationTimeUnit, formFieldsConfiguration.cancellationTimeUnit),
        toggleColumn(columnItemClass, formFieldsConfiguration.itemClass),
        toggleColumn(columnOneTimeCost, formFieldsConfiguration.oneTimeCost),
    ].filter(isPresent);

    const partVoteColumns = [
        toggleColumn(columnReach, formFieldsConfiguration.reach),
        toggleColumn(columnRohs, formFieldsConfiguration.rohs),
        toggleColumn(columnEccnNumbers, formFieldsConfiguration.eccnNumbers),
        toggleColumn(columnHtsCode, formFieldsConfiguration.htsCode),
        toggleColumn(columnCountryOfOrigin, formFieldsConfiguration.countryOfOrigin),
    ].filter(isPresent);

    // Count non-empty groups
    const nonEmptyGroups = [requestColumns.length > 0, offerColumns.length > 0, partVoteColumns.length > 0].filter(
        Boolean,
    ).length;

    // If only one group has columns or no groups have columns, flatten the structure
    if (nonEmptyGroups <= 1) {
        return [columnIndex, ...requestColumns, ...offerColumns, ...partVoteColumns, columnStatus(), columnActions];
    }

    // Otherwise use grouped structure, only including non-empty groups
    const groupedColumns = [];

    if (requestColumns.length > 0) {
        groupedColumns.push({
            headerName: t`Request`,
            headerClass: 'readonly-header',
            children: requestColumns,
        });
    }

    if (offerColumns.length > 0) {
        groupedColumns.push({
            headerName: t`Offer`,
            children: offerColumns,
        });
    }

    if (partVoteColumns.length > 0) {
        groupedColumns.push({
            headerName: t`Part information`,
            children: partVoteColumns,
        });
    }

    return [columnIndex, ...groupedColumns, columnStatus(), columnActions];
}

const columnBuilder = new ColumnBuilder<PdfOfferLineItem>();

const columnIndex = columnBuilder.rowIndex();

const columnStatus = () => {
    const statusReadyToBid = {
        color: 'green' as const,
        label: t`Ready to bid`,
    };
    const statusWarning = {
        color: 'yellow' as const,
        label: t`Warning`,
    };
    const statusError = {
        color: 'red' as const,
        label: t`Error`,
    };

    return columnBuilder.status({
        colId: 'status',

        tooltipValueGetter: (params) => params.data?.row.message ?? '',
        quickFilters: [
            {
                label: () => t`Ready to bid`,
                value: statusReadyToBid,
            },
            {
                label: () => t`Warning`,
                value: statusWarning,
            },
            {
                label: () => t`Error`,
                value: statusError,
            },
        ],
        valueGetter: (params) => {
            const row = params.data?.row;
            if (!row) {
                return null;
            }
            if (row.status === 'error') {
                return statusError;
            }
            if (row.status === 'success' && row.message) {
                return statusWarning;
            }
            return statusReadyToBid;
        },
    });
};

const columnBid = columnBuilder.boolean({
    colId: columnId('bid'),
    label: () => t`Bid`,
    valueGetter: (params) => params.data?.bid?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const newValue = params.newValue ?? false;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'bid',
            value: newValue,
        });
        return true;
    },
    onUpdateRow: (row: PdfOfferLineItem, newValue: boolean) => {
        return {
            ...row,
            bid: {
                status: 'pending',
                value: newValue,
            },
        };
    },

    getQuickFilterText: (params) => {
        return params.data?.bid?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.bid);
    },
});

const compareParts = chainComparators(
    compareByStringKey((part: OtsFullPart) => part.manufacturer.name),
    compareByStringKey((part: OtsFullPart) => part.mpn),
);

const createColumnPart = (partOptions: OtsFullPart[]): DataGridColumn<PdfOfferLineItem, PartLite> => ({
    colId: columnId('part'),
    label: () => t`Part`,
    valueGetter: ({ data }) => {
        return data?.part?.value;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.part);
    },
    comparator: compareByStringKey((part: PartLite | null | undefined) => formatPartLite(part)),
    valueFormatter: ({ value }) => {
        return formatPartLite(value);
    },
    cellEditor: ({
        value,
        onValueChange,
    }: {
        value: PartLite | null | undefined;
        onValueChange: (value: PartLite | null) => void;
    }) => {
        const uniqueParts = uniqBy(partOptions, (part) => part.id)
            .sort(compareParts)
            .map((part) => {
                return convertOtsFullPartToPartLite(part);
            });
        return (
            <FieldSelect
                selectOnFocus
                autoFocus
                autoSelect
                disableClearable
                size="small"
                getOptionLabel={(option) => formatPartLite(option)}
                getOptionKey={(option) => option.id}
                options={uniqueParts}
                value={value ?? null}
                onChange={onValueChange}
            />
        );
    },
    filterValueGetter: (params) => {
        const part = params.data?.part.value;
        if (!part) {
            return '';
        }
        return formatPartLite(part);
    },
    getQuickFilterText: (params) => {
        return [formatPartLite(params.value), params.data?.part?.message ?? ''].join(' ');
    },
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const part = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'part',
            value: part,
        });
        return true;
    },
});

const readonlyCellStyle = {
    background: `linear-gradient(to bottom right, ${colorSystem.neutral[0]}, ${colorSystem.neutral[1]})`,
};

const columnPartDescription = columnBuilder.text({
    colId: columnId('partDescription'),
    headerClass: 'readonly-header',
    label: () => t`Part description`,
    width: 140,
    valueGetter: (params) => params.data?.quoteRequestLineItem?.description,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRequestedPart = columnBuilder.text({
    colId: columnId('requestedPart'),
    headerClass: 'readonly-header',
    label: () => t`Requested part`,
    valueGetter: (params) => {
        const requestedPart = params.data?.quoteRequestLineItem?.requested_part;
        if (!requestedPart) {
            return t`Unable to link the offer to a request item.`;
        }
        return formatPartLite(requestedPart);
    },
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRequiredQuantity = columnBuilder.decimal({
    colId: columnId('requiredQuantity'),
    headerClass: 'readonly-header',
    label: () => t`Required quantity`,
    valueFormatter: ({ node }) => formatQuantity(node?.data?.quoteRequestLineItem?.required_quantity),
    valueGetter: (params) => params.data?.quoteRequestLineItem?.required_quantity.quantity,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnPotentialQuantity = columnBuilder.decimal({
    colId: columnId('potentialQuantity'),
    headerClass: 'readonly-header',
    label: () => t`Potential quantity`,
    valueFormatter: ({ node }) => formatQuantity(node?.data?.quoteRequestLineItem?.potential_quantity),
    valueGetter: (params) => params.data?.quoteRequestLineItem?.potential_quantity.quantity,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRecipients = columnBuilder.text({
    colId: columnId('recipients'),
    headerClass: 'readonly-header',
    label: () => t`Recipients`,
    valueGetter: (params) => params.data?.quoteRequestLineItem?.recipients,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnTargetPrice = columnBuilder.monetaryValue({
    colId: columnId('targetPrice'),
    headerClass: 'readonly-header',
    label: () => t`Target price`,
    valueGetter: (params) => params.data?.quoteRequestLineItem?.target_price,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnCustomerName = columnBuilder.text({
    colId: columnId('customerName'),
    headerClass: 'readonly-header',
    label: () => t`Customer name`,
    valueGetter: (params) => params.data?.quoteRequestLineItem?.customers,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnUnitPrice = columnBuilder.unitPriceDecimal({
    colId: columnId('unitPrice'),
    label: () => t`Unit price`,
    valueFormatter: ({ value, data, context }) => {
        if (value === null || value === undefined) {
            return '-';
        }
        const currency = data?.currency.value ?? context?.defaultCurrency;
        return formatCurrency(value, currency, 'unit-price');
    },
    valueGetter: (params) => params.data?.unitPrice?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId,
            attribute: 'unitPrice',
            value: params.newValue ?? undefined,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.unitPrice?.message ?? '';
    },
    tooltipValueGetter: (params) => {
        return params.data?.unitPrice?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.unitPrice);
    },
});

const columnMoq = columnBuilder.moq({
    colId: columnId('moq'),
    label: () => t`MOQ`,
    valueGetter: (params) => params.data?.moq?.value,
    valueSetter: (params) => {
        const moq = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'moq',
            value: moq,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.moq?.message ?? '';
    },
    tooltipValueGetter: (params) => {
        return params.data?.moq?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.moq);
    },
});

const columnMpq = columnBuilder.mpq({
    colId: columnId('mpq'),
    label: () => t`MPQ`,
    valueGetter: (params) => params.data?.mpq?.value,
    valueSetter: (params) => {
        const mpq = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'mpq',
            value: mpq,
        });
        return true;
    },
    tooltipValueGetter: (params) => {
        return params.data?.mpq?.message ?? '';
    },
    getQuickFilterText: (params) => {
        return params.data?.mpq?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.mpq);
    },
});

const columnStock = columnBuilder.integer({
    colId: columnId('stock'),
    label: () => t`Stock`,
    valueGetter: (params) => params.data?.stock?.value,
    valueSetter: (params) => {
        const stock = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'stock',
            value: stock,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.stock?.message ?? '';
    },
    tooltipValueGetter: (params) => {
        return params.data?.stock?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.stock);
    },
});

const columnPackaging = columnBuilder.enum({
    colId: columnId('packaging'),
    label: () => t`Packaging`,
    options: Object.values(Packaging),
    valueFormatter: ({ value }) => {
        return formatPackaging(value);
    },
    valueGetter: (params) => params.data?.packaging?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const packaging = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'packaging',
            value: packaging,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return [formatPackaging(params.value), params.data?.packaging?.message ?? ''].join(' ');
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.packaging);
    },
    onUpdateRow: (row: PdfOfferLineItem, newValue: Packaging) => {
        return {
            ...row,
            packaging: {
                status: 'pending',
                value: newValue,
            },
        };
    },
    tooltipValueGetter: (params) => {
        return params.data?.packaging?.message ?? '';
    },
});

const columnLeadTime = columnBuilder.integer({
    colId: columnId('standardFactoryLeadTime'),
    label: () => t`Factory lead time`,
    valueGetter: (params) => params.data?.standardFactoryLeadTime.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const leadTime = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'standardFactoryLeadTime',
            value: leadTime,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.standardFactoryLeadTime.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.standardFactoryLeadTime);
    },
});

const columnLeadTimeUnit = columnBuilder.enum({
    colId: columnId('standardFactoryLeadTimeUnit'),
    label: () => t`Lead time unit`,
    valueFormatter: ({ value }) => {
        if (!value) {
            return '-';
        }
        return formatLeadTimeUnit(value);
    },
    options: [LeadTimeUnit.Days, LeadTimeUnit.Weeks, LeadTimeUnit.Months],
    valueGetter: (params) => params.data?.standardFactoryLeadTimeUnit?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const leadTimeUnit = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'standardFactoryLeadTimeUnit',
            value: leadTimeUnit,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.standardFactoryLeadTimeUnit?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.standardFactoryLeadTimeUnit);
    },
    onUpdateRow: (row: PdfOfferLineItem, newValue: LeadTimeUnit) => {
        return {
            ...row,
            standardFactoryLeadTimeUnit: {
                status: 'pending',
                value: newValue,
            },
        };
    },
    tooltipValueGetter: (params) => {
        return params.data?.standardFactoryLeadTimeUnit?.message ?? '';
    },
});

const columnNotes = columnBuilder.text({
    colId: columnId('notes'),
    label: () => t`Notes`,
    valueGetter: (params) => params.data?.notes?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const notes = params.newValue ?? undefined;

        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'notes',
            value: notes,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.notes?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.notes);
    },
    tooltipValueGetter: (params) => {
        return params.data?.notes?.message ?? '';
    },
});

const columnCurrency = columnBuilder.currency({
    colId: columnId('currency'),
    label: () => t`Currency`,
    valueFormatter: ({ value, context }) => {
        const defaultCurrency = context?.defaultCurrency;
        if (!value && defaultCurrency) {
            return defaultCurrency;
        }
        return value;
    },
    valueGetter: (params) => params.data?.currency?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const currency = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'currency',
            value: currency,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.currency?.message ?? '';
    },
    cellStyle: ({ data }) => {
        if (!data?.currency.value) {
            return { color: colorSystem.neutral[5] };
        }
        return getCellStyle(data?.currency);
    },
    tooltipValueGetter: (params) => {
        return params.data?.currency?.message ?? '';
    },
});

const columnNcnr = columnBuilder.boolean({
    colId: columnId('ncnr'),
    label: () => t`NCNR`,
    valueGetter: (params) => params.data?.ncnr?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const ncnr = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'ncnr',
            value: ncnr,
        });
        return true;
    },
    onUpdateRow: (row: PdfOfferLineItem, newValue: boolean) => {
        return {
            ...row,
            ncnr: { status: 'pending', value: newValue },
        };
    },
    getQuickFilterText: (params) => {
        return params.data?.ncnr?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.ncnr);
    },
    tooltipValueGetter: (params) => {
        return params.data?.ncnr?.message ?? '';
    },
});

const columnReach = columnBuilder.enum({
    colId: columnId('reach'),
    label: () => t`REACH`,
    valueFormatter: ({ value }) => {
        return formatComplianceStatus(value);
    },
    options: Object.values(ComplianceStatus),
    valueGetter: (params) => params.data?.reach?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const reach = params.newValue;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'reach',
            value: reach ?? ComplianceStatus.Unknown,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.reach?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.reach);
    },
    tooltipValueGetter: (params) => {
        return params.data?.reach?.message ?? '';
    },
});

const columnSupplierPartNumber = columnBuilder.text({
    colId: columnId('supplierPartNumber'),
    label: () => t`Supplier part number`,
    valueGetter: (params) => params.data?.supplierPartNumber?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const supplierPartNumber = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'supplierPartNumber',
            value: supplierPartNumber,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.supplierPartNumber?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.supplierPartNumber);
    },
    tooltipValueGetter: (params) => {
        return params.data?.supplierPartNumber?.message ?? '';
    },
});

const columnRohs = columnBuilder.enum({
    colId: columnId('rohs'),
    label: () => t`RoHS`,
    valueFormatter: ({ value }) => {
        return formatComplianceStatus(value);
    },
    options: Object.values(ComplianceStatus),
    valueGetter: (params) => params.data?.rohs?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const rohs = params.newValue;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'rohs',
            value: rohs ?? ComplianceStatus.Unknown,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.rohs?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.rohs);
    },
    tooltipValueGetter: (params) => {
        return params.data?.rohs?.message ?? '';
    },
});

const columnEccnNumbers = columnBuilder.text({
    colId: columnId('eccnNumbers'),
    label: () => t`ECCN numbers`,
    valueGetter: (params) => params.data?.eccnNumbers?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const eccnNumbers = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'eccnNumbers',
            value: eccnNumbers,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.eccnNumbers?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.eccnNumbers);
    },
    tooltipValueGetter: (params) => {
        return params.data?.eccnNumbers?.message ?? '';
    },
});

const columnHtsCode = columnBuilder.text({
    colId: columnId('htsCode'),
    label: () => t`HTS code`,
    valueGetter: (params) => params.data?.htsCode?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const htsCode = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'htsCode',
            value: htsCode,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.htsCode?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.htsCode);
    },
    tooltipValueGetter: (params) => {
        return params.data?.htsCode?.message ?? '';
    },
});

const columnCountryOfOrigin = columnBuilder.enum<RegionsEnum>({
    colId: columnId('countryOfOrigin'),
    label: () => t`Country of origin`,
    options: Object.values(RegionsEnum).sort((a, b) => a.localeCompare(b)),
    valueFormatter: ({ value }) => {
        return value ? formatRegionAsName(value) : '-';
    },
    valueGetter: (params) => params.data?.countryOfOrigin?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const countryOfOrigin = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'countryOfOrigin',
            value: countryOfOrigin,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.countryOfOrigin?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.countryOfOrigin);
    },
    tooltipValueGetter: (params) => {
        return params.data?.countryOfOrigin?.message ?? '';
    },
});

const columnValidFrom = columnBuilder.localDate({
    colId: columnId('validFrom'),
    label: () => t`Valid from`,
    valueFormatter: ({ value, context }) => {
        if (!value) {
            return context?.validFrom;
        }
        return formatLocalDate(value);
    },
    valueGetter: (params) => params.data?.validFrom?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const validFrom = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'validFrom',
            value: validFrom,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.validFrom?.message ?? '';
    },
    cellStyle: ({ data }) => {
        if (!data?.validFrom.value) {
            return { color: colorSystem.neutral[5] };
        }
        return getCellStyle(data?.validFrom);
    },
    tooltipValueGetter: (params) => {
        return params.data?.validFrom?.message ?? '';
    },
});

const columnOneTimeCost = columnBuilder.decimal({
    colId: columnId('oneTimeCost'),
    label: () => t`One-time cost`,
    valueFormatter: ({ value, data, context }) => {
        if (value === null || value === undefined) {
            return '-';
        }
        const currency = data?.currency.value ?? context?.defaultCurrency;
        return formatCurrency(value, currency, 'unit-price');
    },
    // we only support a single one-time cost at the moment
    valueGetter: (params) => params.data?.oneTimeCost?.value?.[0]?.amount,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const oneTimeCost = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'oneTimeCost',
            value: oneTimeCost === undefined ? undefined : [{ amount: oneTimeCost, label: t`One-time cost` }],
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.oneTimeCost?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.oneTimeCost);
    },
    tooltipValueGetter: (params) => {
        return params.data?.oneTimeCost?.message ?? '';
    },
});

const columnValidUntil = columnBuilder.localDate({
    colId: columnId('validUntil'),
    label: () => t`Valid until`,
    valueFormatter: ({ value, context }) => {
        if (!value) {
            return context?.validUntil;
        }
        return formatLocalDate(value);
    },
    valueGetter: (params) => params.data?.validUntil?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const validUntil = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'validUntil',
            value: validUntil,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.validUntil?.message ?? '';
    },
    cellStyle: ({ data }) => {
        if (!data?.validUntil.value) {
            return { color: colorSystem.neutral[5] };
        }
        return getCellStyle(data?.validUntil);
    },
    tooltipValueGetter: (params) => {
        return params.data?.validUntil?.message ?? '';
    },
});

const columnCancellationWindow = columnBuilder.integer({
    colId: columnId('cancellationWindow'),
    label: () => t`Cancellation window`,
    valueGetter: (params) => params.data?.cancellationWindow?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const cancellationWindow = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'cancellationWindow',
            value: cancellationWindow,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.cancellationWindow);
    },
    tooltipValueGetter: (params) => {
        return params.data?.cancellationWindow?.message ?? '';
    },
});

const columnCancellationTimeUnit = columnBuilder.enum({
    colId: columnId('cancellationTimeUnit'),
    label: () => t`Cancellation time unit`,
    valueGetter: (params) => params.data?.cancellationTimeUnit?.value,
    options: [LeadTimeUnit.Days, LeadTimeUnit.Weeks, LeadTimeUnit.Months],
    valueFormatter: ({ value }) => {
        if (!value) {
            return '-';
        }
        return formatLeadTimeUnit(value);
    },
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const cancellationTimeUnit = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'cancellationTimeUnit',
            value: cancellationTimeUnit,
        });
        return true;
    },
    tooltipValueGetter: (params) => {
        return params.data?.cancellationTimeUnit?.message ?? '';
    },
});

const columnItemClass = columnBuilder.enum({
    colId: columnId('itemClass'),
    label: () => t`Item class`,
    valueGetter: (params) => params.data?.itemClass?.value,
    options: Object.values(ItemClass),
    valueFormatter: ({ value }) => {
        return value ?? '-';
    },
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const itemClass = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'itemClass',
            value: itemClass,
        });
        return true;
    },
    getQuickFilterText: (params) => {
        return params.data?.itemClass?.message ?? '';
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.itemClass);
    },
    tooltipValueGetter: (params) => {
        return params.data?.itemClass?.message ?? '';
    },
});

const columnActions = columnBuilder.actions({
    label: () => ``,
    valueGetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const row = params.data;
        if (!row) {
            return [];
        }
        return [
            {
                label: t`Delete`,
                onClick: () => {
                    dispatch({
                        type: 'deleteRow',
                        rowIds: [row.rowId],
                    });
                },
            },
            {
                label: t`Duplicate`,
                onClick: () => {
                    dispatch({
                        type: 'duplicateRow',
                        rowIds: [row.rowId],
                    });
                },
            },
            {
                label: t`Add empty row below`,
                onClick: () => {
                    dispatch({
                        type: 'addEmptyRowBelow',
                        rowId: row.rowId,
                    });
                },
            },
        ];
    },
});

/**
 * Gets the top 5 errors from the rows.
 */
export function getTopErrors(rows: PdfOfferLineItem[]): Array<{
    message: string;
    rows: PdfOfferLineItem[];
}> {
    const errorMap = new Map<string, PdfOfferLineItem[]>();

    for (const row of rows) {
        for (const validated of [
            row.cancellationTimeUnit,
            row.cancellationWindow,
            row.countryOfOrigin,
            row.currency,
            row.eccnNumbers,
            row.htsCode,
            row.itemClass,
            row.moq,
            row.mpq,
            row.ncnr,
            row.notes,
            row.packaging,
            row.part,
            row.reach,
            row.rohs,
            row.standardFactoryLeadTime,
            row.standardFactoryLeadTimeUnit,
            row.stock,
            row.supplierPartNumber,
            row.unitPrice,
            row.validFrom,
            row.validUntil,
        ]) {
            if (validated.status === 'error') {
                if (!errorMap.has(validated.message)) {
                    errorMap.set(validated.message, []);
                }
                errorMap.get(validated.message)?.push(row);
            }
        }
    }

    const sortedErrors = Array.from(errorMap.entries())
        .map(([message, rows]) => ({ message, rows }))
        .sort((a, b) => b.rows.length - a.rows.length);

    return sortedErrors.slice(0, 5);
}
