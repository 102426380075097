import React, { createContext, useContext, useState } from 'react';
import { OfferImporterState } from './state';

interface PdfOfferImporterContextType {
    state: OfferImporterState;
}

const PdfOfferImporterContext = createContext<PdfOfferImporterContextType | undefined>(undefined);

interface PdfOfferImporterStateProps {
    initialFormState: OfferImporterState;
    children: React.ReactNode;
}

export function PdfOfferImporterState({ initialFormState, children }: PdfOfferImporterStateProps): JSX.Element {
    const [state] = useState<OfferImporterState>(() => initialFormState);

    const contextValue: PdfOfferImporterContextType = {
        state,
    };

    return <PdfOfferImporterContext.Provider value={contextValue}>{children}</PdfOfferImporterContext.Provider>;
}

export function usePdfOfferImporterState(): PdfOfferImporterContextType {
    const context = useContext(PdfOfferImporterContext);

    if (context === undefined) {
        throw new Error('usePdfOfferImporterState must be used within a PdfOfferImporterState');
    }

    return context;
}
