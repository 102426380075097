import * as z from 'zod';

import {
    ApprovalStatusRuntype,
    ComplianceStatus,
    ComplianceStatusRuntype,
    EmissionDataRuntype,
    MonetaryValueBackendRuntype,
    QualificationRuntype,
    QuantityUnitDTORuntype,
    RohsVersionRuntype,
} from '../backendTypes';
import { ManufacturerDTORuntype } from '../manufacturer';
import { PackagingRuntype } from '../offer/Packaging';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export enum StandardPartTypes {
    Generic = 'Generic',
    OffTheShelf = 'OffTheShelf',
    Ipn = 'Ipn',
}

export type StandardPartDTO = z.infer<typeof StandardPartDTORuntype>;
export const StandardPartDTORuntype = z.object({
    type: z.nativeEnum(StandardPartTypes),
    data: z.string(), // The part ID
});

export enum PartIdTypes {
    Generic = 'Generic',
    OffTheShelf = 'OffTheShelf',
    Ipn = 'Ipn',
    Custom = 'Custom',
    CustomComponent = 'CustomComponent',
    RawSpecification = 'RawSpecification',
}

export type PartDTO = z.infer<typeof PartDTORuntype>;
export const PartDTORuntype = z.object({
    type: z.nativeEnum(PartIdTypes),
    data: z.string(), // The part ID
});

export type CandidateCpn = z.infer<typeof CandidateCpnRuntype>;
export const CandidateCpnRuntype = z.object({
    value: z.string(),
    revision: z.string().nullable(),
});

export function formatCandidateCpn(cpn: CandidateCpn): string {
    if (cpn.revision !== null) {
        return `${cpn.value}`;
    }
    return `${cpn.value}: ${cpn.revision}`;
}

export function isCandidateCpn(cpn?: CandidateCpn | string | undefined | null): cpn is CandidateCpn {
    return !!cpn && typeof cpn !== 'string' && 'value' in cpn && 'revision' in cpn;
}

export const ColumnOriginRuntype = z.object({
    column: z.string(),
    candidate: z.union([z.string(), CandidateCpnRuntype]),
});

export enum CustomPartTypeEnum {
    PCB = 'PCB',
    Mechanical = 'Mechanical',
    Plastic = 'Plastic',
    Cable = 'Cable',
    Packaging = 'Packaging',
    Label = 'Label',
    Other = 'Other',
}

export const CustomPartTypeEnumRuntype = z.nativeEnum(CustomPartTypeEnum);

export type PartCategoryDTO = z.infer<typeof PartCategoryDTORuntype>;
export const PartCategoryDTORuntype = z.object({
    id: z.number(),
    name: z.string(),
    depth: z.number(),
    children: z.array(z.number()),
});

export type CustomPartType = z.infer<typeof CustomPartTypeRuntype>;
export const CustomPartTypeRuntype = z.union([
    z.object({
        name: z.literal(CustomPartTypeEnum.PCB),
        content: z.string().nullable(), // this is the pcbId
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Mechanical),
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Plastic),
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Cable),
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Packaging),
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Label),
    }),
    z.object({
        name: z.literal(CustomPartTypeEnum.Other),
    }),
]);

export enum PackageMountingEnum {
    SMT = 'SMT',
    THT = 'THT',
    PressFit = 'Press Fit',
    Other = 'Other',
}

// create and edit ots
export interface PackageUserInput extends z.infer<typeof PackageUserInputRuntype> {}
export const PackageUserInputRuntype = z.object({
    name: z.string().nullable().optional(),
    number_of_pins: z.number().nullable().optional(),
    mounting: z.nativeEnum(PackageMountingEnum).nullable().optional(),
});

export interface CustomPartUpdateInput extends z.infer<typeof CustomPartUpdateInputRuntype> {}
export const CustomPartUpdateInputRuntype = z.object({
    description: z.string().nullable(),
    type: CustomPartTypeRuntype.nullable(),
    reach_compliant: ComplianceStatusRuntype.nullable(),
    rohs_compliant: ComplianceStatusRuntype.nullable(),
    package: PackageUserInputRuntype.nullable(),
});

export type CustomPartInput = z.infer<typeof CustomPartInputRuntype>;
export const CustomPartInputRuntype = z.object({
    description: z.string().nullable(),
    type: CustomPartTypeRuntype,
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
});

export type PackageTag = z.infer<typeof PackageTagRuntype>;
export const PackageTagRuntype = z.union([
    z.literal('bom-importer-extractable'),
    z.literal('generic-part-creatable'),
    z.literal('user-selectable'),
    z.literal('migration'),
    z.literal('UserInput'),
    z.literal('not-description-extractable'),
]);

export interface PackageDTO extends z.infer<typeof PackageDTORuntype> {}
export const PackageDTORuntype = z.object({
    id: z.string(),
    name: z.string().nullable(),
    aliases: z.array(z.string()),
    number_of_pins: z.number().nullable().optional(),
    mounting: z.nativeEnum(PackageMountingEnum).nullable().optional(),
    tags: z.array(PackageTagRuntype),
});

export interface CustomFullPart extends z.infer<typeof CustomFullPartRuntype> {}
export const CustomFullPartRuntype = z.object({
    id: z.string(),
    description: z.null().or(z.string()),
    type: CustomPartTypeRuntype,
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
    package: PackageDTORuntype.nullable(),
});

export interface FileUrl extends z.infer<typeof FileUrlRuntype> {}
export const FileUrlRuntype = z.object({
    url: z.string(),
});

export enum LifecycleEnum {
    PreRelease = 'PreRelease',
    Active = 'Active',
    NotRecommendedForNewDesigns = 'NotRecommendedForNewDesigns',
    Aftermarket = 'Aftermarket',
    EndOfLife = 'EndOfLife',
    Obsolete = 'Obsolete',
    Acquired = 'Acquired',
    Unknown = 'Unknown',
}

export interface PackageFamily extends z.infer<typeof PackageFamilyRuntype> {}
export const PackageFamilyRuntype = z.object({
    name: z.string(),
    mounting: z.nativeEnum(PackageMountingEnum),
});

// Beware that origin for parts is different from origin for offers!
export enum OTSPartOriginEnum {
    Manual = 'Manual',
    Import = 'Import',
    Aggregate = 'Aggregate',
    DescriptionExtraction = 'DescriptionExtraction',
    // ----
    ArrowAPI = 'ArrowAPI',
    AvnetAPI = 'AvnetAPI',
    AvnetUsaAPI = 'AvnetUsaAPI',
    BuerklinAPI = 'BuerklinAPI',
    ChipAssistAPI = 'ChipAssistAPI',
    DigikeyAPI = 'DigikeyAPI',
    Element14API = 'Element14API',
    EveAPI = 'EveAPI',
    FarnellAPI = 'FarnellAPI',
    FutureAPI = 'FutureAPI',
    GudecoAPI = 'GudecoAPI',
    HeilindAPI = 'HeilindAPI',
    IHSImport = 'IHSImport',
    MasterAPI = 'MasterAPI',
    MouserAPI = 'MouserAPI',
    MyArrowAPI = 'MyArrowAPI',
    LcscAPI = 'LcscAPI',
    NewarkAPI = 'NewarkAPI',
    OctopartAPI = 'OctopartAPI',
    OnlineComponentsAPI = 'OnlineComponentsAPI',
    RsComponentsAPI = 'RsComponentsAPI',
    RochesterAPI = 'RochesterAPI',
    QuestComponentsAPI = 'QuestComponentsAPI',
    RutronikAPI = 'RutronikAPI',
    SchukatAPI = 'SchukatAPI',
    SluiceboxAPI = 'SluiceboxAPI',
    SosAPI = 'SosAPI',
    SourcengineAPI = 'SourcengineAPI',
    TiAPI = 'TiAPI',
    TtiAPI = 'TtiAPI',
    TmeAPI = 'TmeAPI',
    TrustedPartsAPI = 'TrustedPartsAPI',
    VenkelAPI = 'VenkelAPI',
    WeltronAPI = 'WeltronAPI',
    WuerthEisosAPI = 'WuerthEisosAPI',
    CorestaffAPI = 'CorestaffAPI',
    BlumeAPI = 'BlumeAPI',
    SamtecAPI = 'SamtecAPI',
    AlElektronikAPI = 'AlElektronikAPI',
    AvnetApacAPI = 'AvnetApacAPI',
    ChipCartAPI = 'ChipCartAPI ',
    WinSourceAPI = 'WinSourceAPI',
    IcHubAPI = 'IcHubAPI',
    UnikeyAPI = 'UnikeyAPI',
}

const OTSPartOriginEnumRuntype = z.nativeEnum(OTSPartOriginEnum);

const OctopartOriginDataRuntype = z.object({
    part_id: z.string(),
    manufacturer: z.object({
        manufacturer_id: z.string(),
        manufacturer_name: z.string(),
    }),
});

const IHSOriginDataRuntype = z.object({
    part_number: z.string(),
    manufacturer: z.object({
        short_manufacturer_name: z.string(),
        manufacturer_name: z.string(),
    }),
});

const SiliconExpertIdRuntype = z.object({
    se_id: z.string(),
});

const DescriptionExtractionDataRuntype = z.object({
    original_vote: z.string(),
    extraction_version: z.number(),
    origin: z.object({
        type: OTSPartOriginEnumRuntype,
    }),
});

const PartOriginRuntype = z.object({
    type: OTSPartOriginEnumRuntype,
    data: z.optional(
        z.union([
            OctopartOriginDataRuntype,
            SiliconExpertIdRuntype,
            IHSOriginDataRuntype,
            DescriptionExtractionDataRuntype,
            z.null(),
        ]),
    ),
});

export enum DielectricTypes {
    Ceramic = 'Ceramic',
}

export enum EccnGovernance {
    EAR = 'EAR',
    ITAR = 'ITAR',
}

export enum ConflictMineralStatus {
    Unknown = 'Unknown',
    Compliant = 'Compliant',
}

export interface Dielectric extends z.infer<typeof DielectricRuntype> {}
export const DielectricRuntype = z.object({
    type: z.nativeEnum(DielectricTypes).nullable(),
    dielectric: z.string().nullable(),
});

export interface TechnicalProperties extends z.infer<typeof TechnicalPropertiesRuntype> {}
const TechnicalPropertiesRuntype = z.object({
    capacitance: z.string().nullable(), // Strings because these values are decimal
    resistance: z.string().nullable(),
    power_rating: z.string().nullable(),
    tolerance: z.string().nullable(),
    temperature_coefficient: z.string().nullable(),
    voltage_rating: z.string().nullable(),
    dielectric: DielectricRuntype.nullable(),
});

const UsageDataRuntype = z.object({
    tenant: z.string(),
    part_is_used: z.boolean(),
    ipns: z.array(z.string()),
    inventory_stock: z.number(),
    has_ambiguous_stock: z.boolean().nullable(),
    used_assemblies: z.array(z.string()),
    used_assembly_count: z.number(),
    last_modified: z.string(),
});

export type PartEmissionData = z.infer<typeof PartEmissionDataRuntype>;
// If the user doesn't have the part emissions enabled for a part
// (because they never clicked on a button in the UI) then the status for the part will
// be `NotEnabled`. You can think of this status as a gate / screen that hides the
// "actual" part emission data for this part (because emission data on a part level is
// generally public in the DB, but we don't want to leak the actual status to tenants
// who haven't paid for it).
//
// Then the "actual" part emission status can be:
// * `Available` (then we have the CO2e data obviously)
// * `NotFetched` no tenant ever asked for the CO2e data for this part,
//      so we haven't fetched this info from the SluiceBox API yet
// * `NotAvailable` some tenant has requested the CO2e data for this part in the past,
//      but for whatever reason the SluiceBox API says that it cannot give us data for
//      this part
// * `Requested` tenant has asked for the CO2e data for this part, but it hasn't arrived
//      from SluiceBox API yet
const PartEmissionDataRuntype = z.union([
    z.object({
        type: z.literal('Available'),
        data: z.object({
            product_phase_gwp_in_kg_co2e: z.string(),
        }),
    }),
    z.object({
        type: z.literal('NotAvailable'),
    }),
    z.object({
        type: z.literal('NotEnabled'),
    }),
    z.object({
        type: z.literal('NotFetched'),
    }),
    z.object({
        type: z.literal('Requested'),
        data: z.object({
            requested_at: z.string(),
        }),
    }),
]);

export interface OffTheShelfPartVote extends z.infer<typeof OffTheShelfPartVoteRuntype> {}
export const OffTheShelfPartVoteRuntype = z.object({
    origin: PartOriginRuntype,
    reach_compliant: ComplianceStatusRuntype.nullable(),
    rohs_compliant: ComplianceStatusRuntype.nullable(),
    aecq_compliant: ComplianceStatusRuntype.nullable(),
    lifecycle: z.nativeEnum(LifecycleEnum).nullable(),
    lifecycle_yteol: z.number().nullable(),
});

export enum PreferenceStatusEnum {
    Preferred = 'Preferred',
    Blocked = 'Blocked',
}

export const PreferenceStatusEnumRuntype = z.nativeEnum(PreferenceStatusEnum);

export interface OtsFullPart extends z.infer<typeof OtsFullPartRuntype> {}
export const OtsFullPartRuntype = z.object({
    id: z.string(),
    manufacturer: ManufacturerDTORuntype,
    mpn: z.string(),
    mpn_aliases: z.array(z.string()),

    ipns: z.array(z.string()),

    origin: PartOriginRuntype,

    part_category: z
        .object({
            part_category: z.object({
                id: z.number(),
                name: z.string(),
            }),
            path: z.array(z.number()),
        })
        .nullable()
        .optional(),
    type: z.string().nullable().optional(),
    description: z.string().nullable(),

    reach_compliant: ComplianceStatusRuntype,
    reach_candidate_list_date: z.string().nullable(),
    rohs_compliant: ComplianceStatusRuntype,
    rohs_version: RohsVersionRuntype.nullable(),
    aecq_compliant: ComplianceStatusRuntype,
    eu_pop_compliant: ComplianceStatusRuntype,
    eu_pop_cas_numbers: z.array(z.string()),
    pfas_compliant: ComplianceStatusRuntype,
    pfas_cas_numbers: z.array(z.string()),
    china_rohs_compliant: ComplianceStatusRuntype,
    prop65_compliant: ComplianceStatusRuntype,
    hts_code: z.string().nullable(),
    taric: z.string().nullable(),
    qualifications: z.array(QualificationRuntype),
    svhc_cas_numbers: z.array(z.string()),
    country_of_origin: z.array(RegionsEnumRuntype),
    eccn_numbers: z.array(z.string()),
    eccn_governance: z.nativeEnum(EccnGovernance).nullable(),
    conflict_mineral_status: z.nativeEnum(ConflictMineralStatus),

    emissions_data: PartEmissionDataRuntype,

    lifecycle_status: z.nativeEnum(LifecycleEnum),
    lifecycle_yteol: z.number().nullable(),
    lifecycle_yteol_range: z.string().nullable(),
    last_buy_date: z.string().nullable(),
    last_delivery_date: z.string().nullable(),

    technical_properties: TechnicalPropertiesRuntype.nullable(),
    package: PackageDTORuntype.or(z.null()),
    spns: z.array(z.string()),

    manufacturer_product_url: z.string().nullable(),
    datasheet_url: z.string().nullable(),
    image_url: z.string().nullable(),
    preference_status: PreferenceStatusEnumRuntype.nullable(),
    usage_data: UsageDataRuntype.nullable(),
});

const GenericResistorWithFullPackageRuntype = z.object({
    resistance: z.string(),
    power_rating: z.string().nullable(),
    tolerance: z.string().nullable(),
    temperature_coefficient: z.string().nullable(),
    voltage_rating: z.string().nullable(),
    package: PackageUserInputRuntype.nullable(),
});

const GenericCapacitorWithFullPackageRuntype = z.object({
    capacitance: z.string(),
    dielectric: DielectricRuntype.nullable(),
    tolerance: z.string().nullable(),
    voltage_rating: z.string().nullable(),
    package: PackageUserInputRuntype.nullable(),
});

const GenericPartWithFullPackageRuntype = z.object({
    Resistor: GenericResistorWithFullPackageRuntype.optional(),
    Capacitor: GenericCapacitorWithFullPackageRuntype.optional(),
});

export const GenericPartWithIdAndFullPackageRuntype = z.object({
    id: z.string(),
    content: GenericPartWithFullPackageRuntype,
});

export const OffTheShelfPartWithFullPackageRuntype = z.object({
    id: z.string(),
    manufacturer: z.string(),
    mpn: z.string(),
    mpn_aliases: z.array(z.string()),

    part_type: z.string().nullable(),
    part_category: z.array(z.number()),
    description: z.string().nullable(),

    raw_part_category: z.array(z.string()).nullable(),

    additional_text_for_technical_parameter_extraction: z.string().nullable().optional(),
    origin_id: z.string().nullable().optional(),

    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,

    reach_candidate_list_date: z.string().nullable(),
    rohs_version: RohsVersionRuntype.nullable(),
    aecq_compliant: ComplianceStatusRuntype,
    eu_pop_compliant: ComplianceStatusRuntype,
    eu_pop_cas_numbers: z.array(z.string()),
    pfas_compliant: ComplianceStatusRuntype,
    pfas_cas_numbers: z.array(z.string()),
    china_rohs_compliant: ComplianceStatusRuntype,
    prop65_compliant: ComplianceStatusRuntype,
    hts_code: z.string().nullable(),
    taric: z.string().nullable(),
    qualifications: z.array(QualificationRuntype),
    svhc_cas_numbers: z.array(z.string()),
    country_of_origin: z.array(RegionsEnumRuntype),
    eccn_numbers: z.array(z.string()),
    eccn_governance: z.nativeEnum(EccnGovernance).nullable(),
    conflict_mineral_status: z.nativeEnum(ConflictMineralStatus),

    emission_data: PartEmissionDataRuntype,

    lifecycle_yteol: z.number().nullable(),
    lifecycle_yteol_range: z.string().nullable(),
    last_buy_date: z.string().nullable(),
    last_delivery_date: z.string().nullable(),

    technical_properties: TechnicalPropertiesRuntype.nullable(),
    package: PackageUserInputRuntype.nullable(),

    manufacturer_product_url: z.string().nullable(),
    datasheet_url: z.string().nullable(),
    image_url: z.string().nullable(),
});

export enum PartAlternativeSimilarityEnum {
    FormFitFunction = 'FormFitFunction',
    Recommended = 'Recommended',
    Functional = 'Functional',
}

export const PartAlternativeSimilarityEnumRuntype = z.nativeEnum(PartAlternativeSimilarityEnum);

export interface PartAlternative extends z.infer<typeof PartAlternativeRuntype> {}
export const PartAlternativeRuntype = z.object({
    off_the_shelf_part: OtsFullPartRuntype,
    similarity: PartAlternativeSimilarityEnumRuntype,
});

export interface OtsFormPostValues extends z.infer<typeof OtsFormPostValuesRuntype> {}
export const OtsFormPostValuesRuntype = z.object({
    mpn: z.string(),
    part_category: z.number().or(z.undefined()),
    manufacturer: z.string(),
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
    aecq_compliant: ComplianceStatusRuntype,
    last_buy_date: z.string().or(z.undefined()),
    lifecycle_status: z.nativeEnum(LifecycleEnum),
    description: z.string().or(z.undefined()),
    datasheet_url: z.string().or(z.undefined()),
    image_url: z.string().or(z.undefined()),
    manufacturer_product_url: z.string().or(z.undefined()),
    mpn_aliases: z.array(z.string()),
    package: PackageUserInputRuntype,
    qualifications: z.array(QualificationRuntype),
});

export const OTSPartPatchOriginRuntype = z.union([
    z.object({ type: z.literal('Manual') }),
    z.object({ type: z.literal('SupplierPortal'), data: z.string().optional() }),
]);

export interface OtsFormPatchValues extends z.infer<typeof OtsFormPatchValuesRuntype> {}
export const OtsFormPatchValuesRuntype = z.object({
    part_category: z.number().optional(),
    reach_compliant: ComplianceStatusRuntype.optional(),
    rohs_compliant: ComplianceStatusRuntype.optional(),
    aecq_compliant: ComplianceStatusRuntype.optional(),
    last_buy_date: z.union([z.string(), z.null(), z.undefined()]).optional(),
    lifecycle_status: z.nativeEnum(LifecycleEnum).optional(),
    description: z.union([z.string(), z.null(), z.undefined()]).optional(),
    datasheet_url: z.union([z.string(), z.null(), z.undefined()]).optional(),
    image_url: z.union([z.string(), z.null(), z.undefined()]).optional(),
    manufacturer_product_url: z.union([z.string(), z.null(), z.undefined()]).optional(),
    package: PackageUserInputRuntype.optional(),
    qualifications: z.array(QualificationRuntype).optional(),
    eccn_numbers: z.array(z.string()).optional(),
    hts_code: z.string().optional(),
    country_of_origin: z.array(RegionsEnumRuntype).optional(),
    origin: OTSPartPatchOriginRuntype,
});

export interface PartTypesDTO {
    items: string[];
}

export enum DielectricCeramicEnum {
    X8R = 'X8R',
    C0G = 'C0G / NP0',
    X7R = 'X7R',
    X5R = 'X5R',
    X8L = 'X8L',
    Y5V = 'Y5V',
    X6S = 'X6S',
    Z5U = 'Z5U',
    X7S = 'X7S',
    //NP0 = 'NP0', // Search Octopart and DB by C0G instead, as they are synonyms but C0G is a lot more common
    Y5U = 'Y5U',
    C0H = 'C0H',
    Y5R = 'Y5R',
    Y5P = 'Y5P',
    Y5T = 'Y5T',
    U2J = 'U2J',
    Z5V = 'Z5V',
    Z5P = 'Z5P',
    K4000 = 'K4000',
    X6T = 'X6T',
    X7T = 'X7T',
    Z5F = 'Z5F',
}

export enum GenericPartTypes {
    Resistor = 'Resistor',
    Capacitor = 'Capacitor',
}

export type GenericCapacitor = z.infer<typeof GenericCapacitorRuntype>;
// eslint-disable-next-line import/no-unused-modules
export const GenericCapacitorRuntype = z.object({
    type: z.literal(GenericPartTypes.Capacitor),
    technical_parameters: z.object({
        capacitance: z.string().nullable(), // Strings because these values are decimal
        package_id: z.string().nullable(),
        dielectric: DielectricRuntype.nullable(),
        tolerance: z.string().nullable(),
        voltage_rating: z.string().nullable(),
    }),
});

export type GenericResistor = z.infer<typeof GenericResistorRuntype>;
// eslint-disable-next-line import/no-unused-modules
export const GenericResistorRuntype = z.object({
    type: z.literal(GenericPartTypes.Resistor),
    technical_parameters: z.object({
        resistance: z.string().nullable(), // Strings because these values are decimal
        package_id: z.string().nullable(),
        power_rating: z.string().nullable(),
        tolerance: z.string().nullable(),
        temperature_coefficient: z.string().nullable(),
        voltage_rating: z.string().nullable(),
    }),
});
export type GenericPart = z.infer<typeof TechnicalParametersRuntype>;
export const TechnicalParametersRuntype = z.union([GenericCapacitorRuntype, GenericResistorRuntype]);
export interface GenericFullPart extends z.infer<typeof GenericFullPartRuntype> {}

export const GenericFullPartRuntype = z.object({
    id: z.string(),
    content: TechnicalParametersRuntype,
    matches: z.array(OtsFullPartRuntype),
    reach_compliant: z.nativeEnum(ComplianceStatus),
    rohs_compliant: z.nativeEnum(ComplianceStatus),
    aecq_compliant: z.nativeEnum(ComplianceStatus),
    lifecycle_status: z.nativeEnum(LifecycleEnum),
    lifecycle_yteol: z.number().nullable(),
    is_manufacturer_preferred: z.boolean(),
    emission_data: EmissionDataRuntype.nullable(),
});

/**
 * An IncompleteGenericFullPart is essentially a GenericPart with values missing. Once the missing values
 * are added the user can convert it into a GenericPart
 *
 * Use `isIncompleteGenericFullPart` to switch on IncompleteGenericFullPart
 */
export type IncompleteGenericFullPart = z.infer<typeof IncompleteGenericFullPartRuntype>;

export const IncompleteGenericFullPartRuntype = z.union([GenericCapacitorRuntype, GenericResistorRuntype]);

export type ConflictingTechnicalParameters = z.infer<typeof ConflictingTechnicalParametersRuntype>;
export const ConflictingTechnicalParametersRuntype = z.object({
    resistances: z.array(z.string()),
    tolerances: z.array(z.string()),
    power_ratings: z.array(z.string()),
    voltage_ratings: z.array(z.string()),
    temperature_coefficients: z.array(z.string()),
    packages: z.array(z.string()),
    capacitances: z.array(z.string()),
    dielectrics: z.array(z.string()),
});

export function isConflictingTechnicalParameters(
    v?: ConflictingTechnicalParameters | string | null | undefined | string[],
): v is ConflictingTechnicalParameters {
    return (
        !!v &&
        typeof v !== 'string' &&
        'resistances' in v &&
        'tolerances' in v &&
        'power_ratings' in v &&
        'capacitances' in v &&
        'packages' in v
    );
}

export enum PartOptionExtractionReasonWithoutContextEnum {
    MatchingMpn = 'MatchingMpn',
    NoManufacturerGiven = 'NoManufacturerGiven',
    MatchingIpn = 'MatchingIpn',
    MatchingCpn = 'MatchingCpn',
    MatchingSpn = 'MatchingSpn',
    // The MPN matches and but is already linked to an IPN part option
    MatchingMpnLinkedToIpn = 'MatchingMpnLinkedToIpn',
    // The matched parts were replaced by IPNs with the same linked parts
    MatchedPartsReplacedByIpn = 'MatchedPartsReplacedByIpn',
    // There is more than one IPN result for a candidate IPN
    ManyMatchingIpn = 'ManyMatchingIpn',
    // There is more than one IPN result for a candidate SPN
    ManyMatchingSpn = 'ManyMatchingSpn',
    // The IPN has status removed
    IpnRemoved = 'IpnRemoved',
    // There are multiple CPNs with different revisions matching but no revision is specified in the BOM
    MatchingCpnButRevisionUnclear = 'MatchingCpnButRevisionUnclear',
    // The IPN matches but there is a CPN in the BOM that points to a different IPN
    MatchingIpnButDifferentCpn = 'MatchingIpnButDifferentCpn',
    // The CPN matches but there is an IPN in the BOM that is different to the one of the CPN
    MatchingCpnButDifferentIpn = 'MatchingCpnButDifferentIpn',
    // The SPN matches but there is a CPN in the BOM that points to a different IPN
    MatchingSpnButDifferentCpn = 'MatchingSpnButDifferentCpn',
    // The SPN matches but there is another IPN in the BOM that is different to the one of the SPN
    MatchingSpnButDifferentIpn = 'MatchingSpnButDifferentIpn',
    // There is an explicit manufacturer-free indication
    ExplicitlyManufacturerFree = 'ExplicitlyManufacturerFree',
    MatchingTechSpecs = 'MatchingTechSpecs',
    ApprovalStatusChangedInPreviousImport = 'ApprovalStatusChangedInPreviousImport',
    AssemblyImport = 'AssemblyImport',
}

export enum PartOptionExtractionReasonEnum {
    // There is a matching manufacturer found via an alternative name
    MatchingManufacturerAlternativeName = 'MatchingManufacturerAlternativeName',
    // There is a matching manufacturer
    MatchingManufacturer = 'MatchingManufacturer',
    // The extracted generic part matches the technical parameters but some are conflicting
    MatchingTechSpecsWithConflicts = 'MatchingTechSpecsWithConflicts',
    // The part option was added with the quote import
    QuoteImport = 'QuoteImport',
}

export type PartOptionExtractionReason = z.infer<typeof PartOptionExtractionReasonRuntype>;
export const PartOptionExtractionReasonRuntype = z.union([
    z.object({
        name: z.nativeEnum(PartOptionExtractionReasonWithoutContextEnum),
    }),
    z.object({
        name: z.literal(PartOptionExtractionReasonEnum.MatchingTechSpecsWithConflicts),
        context: ConflictingTechnicalParametersRuntype,
    }),
    z.object({
        name: z.literal(PartOptionExtractionReasonEnum.MatchingManufacturer),
        context: z.string(),
    }),
    z.object({
        name: z.literal(PartOptionExtractionReasonEnum.MatchingManufacturerAlternativeName),
        context: z.string(),
    }),
    z.object({
        name: z.literal(PartOptionExtractionReasonEnum.QuoteImport),
        context: z.string().nullable().optional(),
    }),
]);

export enum OriginSetMethod {
    Automatic = 'Automatic',
    Manual = 'Manual',
    Previous = 'Previous',
}

export enum ColumnName {
    MPN = 'MPN',
    SupplierPartNumber = 'SupplierPartNumber',
    IPN = 'IPN',
    CPN = 'CPN',
    TechSpecs = 'TechSpecs',
    Previous = 'Previous',
}

export function formatColumnName(column: ColumnName): string {
    switch (column) {
        case ColumnName.CPN:
            return 'CPN';
        case ColumnName.IPN:
            return 'IPN';
        case ColumnName.MPN:
            return 'MPN';
        case ColumnName.SupplierPartNumber:
            return 'SPN';
        case ColumnName.TechSpecs:
            return 'Specs';
        case ColumnName.Previous:
            return 'Prev';
    }
}

export interface PartOptionOrigin extends z.infer<typeof PartOptionOriginRuntype> {}
export const PartOptionOriginRuntype = z.object({
    set_method: z.nativeEnum(OriginSetMethod).nullable(),
    column: z.nativeEnum(ColumnName).nullable().optional(),
    candidate: z.union([z.string(), CandidateCpnRuntype]).nullable().optional(),
    reasons: z.array(PartOptionExtractionReasonRuntype),
});

export enum PartSuggestionReasonEnum {
    PartialMpn = 'PartialMpn',
    ManufacturerMismatch = 'ManufacturerMismatch',
    PartOnlySuggestedBecauseManufacturerIsBlocked = 'PartOnlySuggestedBecauseManufacturerIsBlocked',
    CandidateMpnMatchedButNoManufacturerGiven = 'CandidateMpnMatchedButNoManufacturerGiven',
    GenericPartOnlySuggestedBecauseNonGenericPartOptionExists = 'GenericPartOnlySuggestedBecauseNonGenericPartOptionExists',
    GenericPartOnlySuggestedBecauseManufacturerGiven = 'GenericPartOnlySuggestedBecauseManufacturerGiven',
    GenericPartOnlySuggestedBecauseIpnGiven = 'GenericPartOnlySuggestedBecauseIpnGiven',
    GenericPartOnlySuggestedBecauseCpnGiven = 'GenericPartOnlySuggestedBecauseCpnGiven',
    GenericPartOnlySuggestedBecauseMpnGiven = 'GenericPartOnlySuggestedBecauseMpnGiven',
    GenericPartMissingRequiredParameter = 'GenericPartMissingRequiredParameter',
    GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree = 'GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree',
    IpnSuggestedBasedOnMpnCandidate = 'IpnSuggestedBasedOnMpnCandidate',
    IpnSuggestedBasedOnLinkedOrSuggestedParts = 'IpnSuggestedBasedOnLinkedOrSuggestedParts',
    GenericPartTechnicalParametersMatchThisIpn = 'GenericPartTechnicalParametersMatchThisIpn',
    IpnSuggestedBecauseOfBetterTechnicalParameters = 'IpnSuggestedBecauseOfBetterTechnicalParameters',
    // Part options were moved to suggestions because the previous import with the same data was changed to a custom part specification
    PreviousImportCustomPartSpecification = 'PreviousImportCustomPartSpecification',
    PreviousImportRemovedPartOption = 'PreviousImportRemovedPartOption',
    PreviousImportManuallyAddedPartOption = 'PreviousImportManuallyAddedPartOption',
    PreviousImportAutomaticallyAddedPartOption = 'PreviousImportAutomaticallyAddedPartOption',
    PreviousImportPreviouslyAddedPartOption = 'PreviousImportPreviouslyAddedPartOption',
    // This reason is now deprecated and no longer comes from the BomImporter.
    // In the bom importer we now approve both Mpn and generic part. as seen in this ticket: https://www.notion.so/luminovo/Building-Backlog-BOM-f9b31a8848bc4a05904107919350323f?p=c3dee61dd98842e0a20f150f337a2c17&pm=s
    // We are only keeping this for backward compatibility with boms imported prior to this change.
    Deprecated_PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart = 'PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart',
    ConflictingPartSpecificationTypes = 'ConflictingPartSpecificationTypes',
}

export type PartSuggestionReason = z.infer<typeof PartSuggestionReasonRuntype>;
export const PartSuggestionReasonRuntype = z.union([
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PartialMpn),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseNonGenericPartOptionExists),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.ManufacturerMismatch),
        context: z.string().nullable().optional(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PartOnlySuggestedBecauseManufacturerIsBlocked),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseManufacturerGiven),
        context: z.string().nullable().optional(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseIpnGiven),
        context: z.string().nullable().optional(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseCpnGiven),
        context: z.string().nullable().optional(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseMpnGiven),
        context: z.string().nullable().optional(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartMissingRequiredParameter),
        context: z.string(),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.CandidateMpnMatchedButNoManufacturerGiven),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree),
    }),
    z.object({
        name: z.literal(
            PartSuggestionReasonEnum.Deprecated_PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart,
        ),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.IpnSuggestedBasedOnMpnCandidate),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.IpnSuggestedBasedOnLinkedOrSuggestedParts),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.GenericPartTechnicalParametersMatchThisIpn),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.IpnSuggestedBecauseOfBetterTechnicalParameters),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PreviousImportCustomPartSpecification),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PreviousImportRemovedPartOption),
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PreviousImportManuallyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PreviousImportAutomaticallyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.PreviousImportPreviouslyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    z.object({
        name: z.literal(PartSuggestionReasonEnum.ConflictingPartSpecificationTypes),
        context: z.array(z.string()),
    }),
]);

export type PartSuggestionOrigin = z.infer<typeof PartSuggestionOriginRuntype>;
export const PartSuggestionOriginRuntype = z.union([
    z.object({
        column: z.literal(ColumnName.MPN),
        candidate: z.string(),
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
    z.object({
        column: z.literal(ColumnName.SupplierPartNumber),
        candidate: z.string(),
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
    z.object({
        column: z.literal(ColumnName.IPN),
        candidate: z.string(),
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
    z.object({
        column: z.literal(ColumnName.CPN),
        candidate: CandidateCpnRuntype,
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
    z.object({
        column: z.literal(ColumnName.TechSpecs).optional(),
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
    z.object({
        column: z.literal(ColumnName.Previous),
        candidate: z.union([z.string(), CandidateCpnRuntype]).nullable(),
        reasons: z.array(PartSuggestionReasonRuntype),
    }),
]);

export type PartOptionDTO = z.infer<typeof PartOptionDTORuntype>;
export const PartOptionDTORuntype = z.object({
    part: StandardPartDTORuntype,
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
});

export enum CustomOptionTypes {
    CustomPart = 'CustomPart',
    CustomComponent = 'CustomComponent',
}

export type CustomOptionVariantDTO = z.infer<typeof CustomOptionVariantDTORuntype>;
export const CustomOptionVariantDTORuntype = z.object({
    type: z.nativeEnum(CustomOptionTypes),
    data: z.string(), // custom part id or custom component id
});

export type CustomOptionDTO = z.infer<typeof CustomOptionDTORuntype>;
export const CustomOptionDTORuntype = z.object({
    part: CustomOptionVariantDTORuntype,
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
});

const SMTMountingTypesDTORuntype = z.object({
    imperial: z.array(z.string()),
    metric: z.array(z.string()),
});

export const PartMountingTypesDTORuntype = z.object({
    smt: SMTMountingTypesDTORuntype,
    tht: z.array(z.string()),
});

const ExternalParamsOctoPartRuntype = z.object({
    offset: z.number(),
});

export type PartSearchInput = z.infer<typeof PartSearchInputRuntype>;
const PartSearchInputRuntype = z.object({
    search_candidate: z.string(),
    search_request_number: z.number().nullable(),
    external_params: ExternalParamsOctoPartRuntype.nullable(),
    already_fetched_parts: z.number().nullable(),
    search_id: z.string().optional(),
    rfq_context: z.union([z.literal('WithinRfQ'), z.literal('OutsideRfQ')]),
    rfq_id: z.string().optional(),
});

export type BackendPartOfferSummary = z.infer<typeof BackendPartOfferSummaryDTO>;
export const BackendPartOfferSummaryDTO = z.object({
    part_id: z.string(),
    supplier_stock: QuantityUnitDTORuntype.nullable(),
    best_price_in_stock: MonetaryValueBackendRuntype.nullable(),
    median_price_in_stock: MonetaryValueBackendRuntype.nullable(),
    best_price: MonetaryValueBackendRuntype.nullable(),
    stock_last_update: z.string().nullable(),
});

export const MpnMatchesRuntype = z.record(z.string(), z.array(OtsFullPartRuntype));

export const IpnInventoryRuntype = z.object({
    ipn: z.string(),
    last_updated: z.string(),
    unit_of_measurement: QuantityUnitDTORuntype,
    unit_price: MonetaryValueBackendRuntype,
    available_stock: z.number(),
    total_stock: z.number().nullable(),
    packaging: PackagingRuntype.nullable(),
    standard_lead_time: z.number().nullable(),
});

export type OffTheShelfPartSearchFilterRequest = z.infer<typeof OffTheShelfPartSearchFilterRequestRuntype>;
export const OffTheShelfPartSearchFilterRequestRuntype = z.union([
    z.object({
        field: z.literal('capacitance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('resistance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('power-rating'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('tolerance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('temperature-coefficient'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('voltage-rating'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('dielectric-dielectric'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(DielectricCeramicEnum)),
    }),
    z.object({
        field: z.literal('manufacturer'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('package'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('country-of-origin'),
        operator: z.literal('equalsAny'),
        parameter: z.array(RegionsEnumRuntype),
    }),
    z.object({
        field: z.literal('rohs-compliance'),
        operator: z.literal('equalsAny'),
        parameter: z.array(ComplianceStatusRuntype),
    }),
    z.object({
        field: z.literal('reach-compliance'),
        operator: z.literal('equalsAny'),
        parameter: z.array(ComplianceStatusRuntype),
    }),
    z.object({
        field: z.literal('lifecycle'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(LifecycleEnum)),
    }),
    z.object({
        field: z.literal('last-buy-date'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])), // TODO - should be date
    }),
    z.object({
        field: z.literal('mpn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('ipn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('spn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('inventory-stock'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('ambiguous-stock'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.boolean()),
    }),
    z.object({
        field: z.literal('used-assemblies'),
        operator: z.literal('arrIncludesSome'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('part-is-used'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.boolean()),
    }),
    z.object({
        field: z.literal('description'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('part-category'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.number()),
    }),
]);

export type OffTheShelfPartSearchAggregationDTO = z.infer<typeof OffTheShelfPartSearchAggregationDTORuntype>;
export const OffTheShelfPartSearchAggregationDTORuntype = z.union([
    z.object({
        field: z.literal('manufacturers'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('part_category'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('reach_compliant'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('rohs_compliant'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('packages'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('lifecycle'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
]);

export type OffTheShelfPartSearchSortRequest = z.infer<typeof OffTheShelfPartSearchSortRequestRuntype>;
export const OffTheShelfPartSearchSortRequestRuntype = z.union([
    z.object({
        field: z.literal('inventory-stock'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    z.object({
        field: z.literal('number-of-used-assemblies'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    z.object({
        field: z.literal('lifecycle'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    z.object({
        field: z.literal('rohs-compliance'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    z.object({
        field: z.literal('reach-compliance'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
]);

export interface OffTheShelfPartSearchResponse extends z.infer<typeof OffTheShelfPartSearchResponseRuntype> {}
export const OffTheShelfPartSearchResponseRuntype = z.object({
    search_id: z.string(),
    total_count: z.number(),
    page_params: z.unknown().or(z.undefined()),
    aggregations: z.array(OffTheShelfPartSearchAggregationDTORuntype),
    page: z.array(OtsFullPartRuntype),
    highlights: z.record(z.string(), z.array(z.string())).optional(),
});

export interface StockInformationDTO extends z.infer<typeof StockInformationDTORuntype> {}
const StockInformationDTORuntype = z.object({
    inventory: z.object({
        quantity: QuantityUnitDTORuntype.nullable(),
        last_updated: z.string().nullable(),
    }),
    external: z.object({
        quantity: QuantityUnitDTORuntype.nullable(),
        last_updated: z.string().nullable(),
    }),
});

export interface EmissionDataRequestDTO extends z.infer<typeof EmissionDataRequestDTORuntype> {}

export const EmissionDataRequestDTORuntype = z.object({
    ots_part_ids: z.array(z.string()),
    generic_part_ids: z.array(z.string()),
});

export interface PartAvailability extends z.infer<typeof PartAvailabilityRuntype> {}
export const PartAvailabilityRuntype = z.object({
    part: StandardPartDTORuntype,
    stock: StockInformationDTORuntype.nullable(),
});

export const RFQContextualizedBulkIdsRuntype = z.union([
    z.object({
        ids: z.array(z.string()),
        rfq_context: z.literal('WithinRfQ'),
        rfq_id: z.string(),
    }),
    z.object({
        ids: z.array(z.string()),
        rfq_context: z.literal('OutsideRfQ'),
    }),
]);

export type LoadingOffersProgressDTO = z.infer<typeof LoadingOffersProgressDTORuntype>;
export const LoadingOffersProgressDTORuntype = z.object({
    total: z.number(),
    parts: z.array(
        z.object({
            id: z.string(),
            count: z.number(),
        }),
    ),
    custom_parts: z.record(z.string(), z.object({ count: z.number(), id: z.string() })),
    apis: z.array(
        z.object({
            name: z.string(),
            count: z.number(),
        }),
    ),
});

export interface SupportedPartsDTO extends z.infer<typeof SupportedPartsDTORuntype> {}
export const SupportedPartsDTORuntype = z.object({
    supplier: z.string(),
    part_ids: z.array(PartDTORuntype),
});

export enum PartLiteTypes {
    OffTheShelf = 'OffTheShelf',
    Generic = 'Generic',
    Ipn = 'Ipn',
    RawSpecification = 'RawSpecification',
    Custom = 'Custom',
    CustomComponent = 'CustomComponent',
    Unknown = 'Unknown',
}

export const OtsPartLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.OffTheShelf),
    id: z.string(),
    manufacturer: z.object({
        id: z.string(),
        name: z.string(),
    }),
    mpn: z.string(),
});

export const GenericPartLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.Generic),
    id: z.string(),
    content: z.object({
        type: z.nativeEnum(GenericPartTypes),
    }),
    matches: z.array(OtsPartLiteRuntype),
});

export const RawSpecificationLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.RawSpecification),
    id: z.string(),
    manufacturer: z.object({
        id: z.string().nullable(),
        name: z.string().nullable(),
    }),
    mpn: z.string().nullable(),
    description: z.string().nullable(),
});

export const OtsComponentLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.Ipn),
    id: z.string(),
    matches: z.array(z.union([OtsPartLiteRuntype, GenericPartLiteRuntype, RawSpecificationLiteRuntype])),
});
export type OtsComponentPartLiteDto = z.infer<typeof OtsComponentLiteRuntype>;

export const CustomPartLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.Custom),
    id: z.string(),
    description: z.null().or(z.string()),
    type: CustomPartTypeRuntype,
});

export const CustomComponentLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.CustomComponent),
    id: z.string(),
    description: z.null().or(z.string()),
    parts: z.array(CustomPartLiteRuntype),
});
export type CustomComponentPartLiteDto = z.infer<typeof CustomComponentLiteRuntype>;

/**
 * This part currently only exists in the frontend, it's used to represent an unmatched part -
 * a part that is not known in the catalog. The ID is just a placeholder, to make sure all parts
 * have an ID.
 */
export const UnknownPartLiteRuntype = z.object({
    kind: z.literal(PartLiteTypes.Unknown),
    id: z.string(),
    mpn: z.string(),
    manufacturer: z.object({
        name: z.string(),
    }),
});

export type PartLite = z.infer<typeof PartLiteRuntype>;
export const PartLiteRuntype = z.union([
    OtsPartLiteRuntype,
    GenericPartLiteRuntype,
    OtsComponentLiteRuntype,
    RawSpecificationLiteRuntype,
    CustomPartLiteRuntype,
    CustomComponentLiteRuntype,
    UnknownPartLiteRuntype,
]);
