import { t } from '@lingui/macro';
import { Column } from 'ag-grid-community';
import { HeaderComponentBoolean } from '../components/HeaderComponentBoolean';
import { HeaderComponentEnum } from '../components/HeaderComponentEnum';
import { DataGridBooleanColumn, DataGridRow } from '../types';

export function columnBoolean<TRow extends DataGridRow>(
    props: DataGridBooleanColumn<TRow>,
): DataGridBooleanColumn<TRow> {
    return {
        cellDataType: 'boolean',
        editable: true,
        cellEditor: false,
        headerName: 'Boolean',
        headerComponent: props.field ? HeaderComponentBoolean : HeaderComponentEnum,
        headerComponentParams: {
            options: [true, false],
            onUpdateRow: props.onUpdateRow,
        },
        valueFormatter: ({ value }) => (value ? t`Yes` : t`No`),

        onCellDoubleClicked: (event) => {
            const newValue = !event.value;
            event.node.setDataValue(event.column, newValue);
            alert(`double clicked: ${newValue}`);
        },
        ...props,
    };
}

export function getBooleanValue<TRow>(row: TRow, col?: Column | null): boolean {
    if (!col) {
        return false;
    }

    const field = col.getColDef().field;
    if (!field) {
        throw new Error('Column has no field');
    }

    return Boolean(
        // @ts-expect-error
        row[field],
    );
}
