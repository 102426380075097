import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface UnikeyCredentialsDTO extends z.infer<typeof UnikeyCredentialsDTORuntype> {}
export const UnikeyCredentialsDTORuntype = z.object({
    appid: z.string(),
    appkey: z.string(),
});

export interface UnikeyCredentialsInputDTO extends z.infer<typeof UnikeyCredentialsInputDTORuntype> {}
export const UnikeyCredentialsInputDTORuntype = z.object({
    appid: z.string().optional(),
    appkey: z.string().optional(),
});

export interface UnikeyResponseBodyDTO extends z.infer<typeof UnikeyResponseBodyDTORuntype> {}
export const UnikeyResponseBodyDTORuntype = z.object({
    data: UnikeyCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
