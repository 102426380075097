import { compareByString } from '@luminovo/commons';
import { Flexbox, StickyLayout, colorSystem } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import React from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { ManufacturingDatabaseTemplatePageLayout } from '../../../components/CostingTemplates/ManufacturingDatabasePage';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { UrlParams } from '../../../utils/routes';
import { AddManufacturingScenarioTemplateDialog } from '../ManufacturingScenarioTemplateCreation';
import ManufacturingScenarioTemplateView from './ManufacturingScenarioTemplateView';
import ManufacturingScenarioTemplatesColumn from './ManufacturingScenarioTemplatesColumn';

function LayoutTwoPanels({ left, right }: { left?: React.ReactNode; right: React.ReactNode }): JSX.Element {
    if (!left) {
        return <>{right}</>;
    }

    return (
        <>
            <PanelGroup direction="horizontal">
                <Panel defaultSize={25}>{left}</Panel>
                <PanelResizeHandle
                    hitAreaMargins={{ coarse: 0, fine: 0 }}
                    // eslint-disable-next-line spellcheck/spell-checker
                    className="panel-resize-handle"
                    style={{
                        marginLeft: '12px',
                        marginRight: '12px',
                        backgroundColor: colorSystem.neutral[2],
                        width: '2px',
                    }}
                ></PanelResizeHandle>
                <Panel defaultSize={75}>{right}</Panel>
            </PanelGroup>
        </>
    );
}

export const ManufacturingScenarioTemplateManagementPage = (
    props: UrlParams<'/manufacturing/template/:templateId'> | UrlParams<'/manufacturing/template'>,
) => {
    const templateIdFromUrl = 'templateId' in props.pathParams ? props.pathParams.templateId : undefined;
    const { data: templates = [] } = useHttpQuery(
        'GET /manufacturing-scenario-templates',
        { queryParams: {} },
        {
            select: (res) => res.data.sort((template1, template2) => compareByString(template1.name, template2.name)),
        },
    );

    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    const selectedTemplate: ManufacturingScenarioTemplateDTO | undefined =
        templates.find((c) => c.id === templateIdFromUrl) ?? templates[0];

    return (
        <ManufacturingDatabaseTemplatePageLayout>
            <LayoutTwoPanels
                left={
                    <StickyLayout style={{ paddingTop: '0px' }}>
                        <ManufacturingScenarioTemplatesColumn
                            templates={templates}
                            handleOnAddClick={() => setIsDialogOpen(true)}
                            selectedTemplateId={selectedTemplate?.id}
                        />
                    </StickyLayout>
                }
                right={
                    <Flexbox paddingBottom={'72px'} height={'fit-content'} flex={1}>
                        {selectedTemplate && <ManufacturingScenarioTemplateView template={selectedTemplate} />}
                    </Flexbox>
                }
            />
            <AddManufacturingScenarioTemplateDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </ManufacturingDatabaseTemplatePageLayout>
    );
};
