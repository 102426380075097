import { getToken } from '@luminovo/auth';
import { Currency, uniqBy } from '@luminovo/commons';
import {
    ComplianceStatus,
    http,
    ItemClass,
    OtsFullPart,
    Packaging,
    PartLite,
    PartLiteTypes,
    PdfAnalyzeResponse,
    QuoteRequestLineItemDTO,
    ValidFor,
} from '@luminovo/http-client';
import { QuoteRequest } from '../../components/PdfOfferImporter/types';
import { getPdfDocument, LeadTimeUnit, processPdf, RegionNetwork } from '../../components/PdfViewer';
import { compareByVerticalDistance } from '../../components/PdfViewer/model/RegionNetwork/compareByVerticalDistance';
import { findAttributeByType } from '../../components/PdfViewer/model/RegionNetwork/findAttributeByType';
import { infer, InferredAttribute } from '../../components/PdfViewer/model/RegionNetwork/infer';
import { Attribute, Attributes, AttributeValueOf, Region } from '../../components/PdfViewer/model/RegionNetwork/types';
import {
    ExtractedOfferLineItem,
    ExtractedValue,
    OfferFileParser,
    OfferFileParseResult,
    PartialOfferLineItem,
} from '../../types';

/**
 * This is called the "Legacy" PDF offer parser because it will soon be replaced by a new LLM-powered PDF parser.
 */
export class LegacyPdfOfferParser implements OfferFileParser {
    supportsFile(file: File): boolean {
        return file.type === 'application/pdf';
    }

    async parse({ file, quoteRequest }: { file: File; quoteRequest?: QuoteRequest }): Promise<OfferFileParseResult> {
        const url = URL.createObjectURL(file);
        const quoteRequestLineItemsPromise = fetchQuoteRequestLineItems({
            quoteRequestId: quoteRequest?.id,
            disabled: quoteRequest?.disableFetchingQuoteRequestLineItems,
        });
        const pdfDocument = await getPdfDocument(url);

        // Create form data for API request
        const form = new FormData();
        form.append('file', file);

        // Make the API call to analyze the PDF
        const analyzeResult: PdfAnalyzeResponse = await http(
            'POST /analyze/pdf',
            {
                queryParams: { type: 'Offer' },
                requestBody: form,
            },
            getToken(),
        );

        const quoteRequestLineItems = await quoteRequestLineItemsPromise;

        // Process the PDF analysis results with no expected parts
        const expectedParts: OtsFullPart[] = quoteRequest?.resolvedParts ?? [];
        const regionNetwork = processPdf({ analyzeResult }, { expectedParts });

        // Extract currency information
        const currency = this.inferCurrency(regionNetwork);
        const defaultCurrency = currency?.value ?? Currency.EUR;

        // Extract offer number
        const offerNumber = this.inferOfferNumber(regionNetwork);
        const extractedOfferNumber = offerNumber?.value ?? '';

        // Extract valid from date
        const validFrom = this.inferOfferDate(regionNetwork);
        const extractedValidFrom = validFrom?.value;

        // Extract all offer line items
        const extractedOfferLineItems = this.inferOfferLineItems(regionNetwork);

        // Map offer line items to the format needed for the result
        const rows: PartialOfferLineItem[] = extractedOfferLineItems.map(
            (extractedOfferLineItem, index: number): PartialOfferLineItem => ({
                rowId: `row-${index}`,
                bid: true,
                quoteRequestLineItem: findUniqueMatchOrUndefined(quoteRequestLineItems, extractedOfferLineItem),
                source: extractedOfferLineItem,
                currency: extractedOfferLineItem.currency.value,
                unitPrice: extractedOfferLineItem.unitPrice.value,
                moq: extractedOfferLineItem.moq.value,
                mpq: extractedOfferLineItem.mpq.value,
                packaging: extractedOfferLineItem.packaging.value,
                standardFactoryLeadTime: extractedOfferLineItem.standardFactoryLeadTime?.value?.value,
                standardFactoryLeadTimeUnit: extractedOfferLineItem.standardFactoryLeadTime?.value?.unit,
                stock: extractedOfferLineItem.stock.value,
                ncnr: extractedOfferLineItem.ncnr.value,
                notes: extractedOfferLineItem.notes.value ?? '',
                part: convertPartToPartLite(extractedOfferLineItem.part.value),
                reach: ComplianceStatus.Unknown,
                rohs: ComplianceStatus.Unknown,
                cancellationTimeUnit: LeadTimeUnit.Weeks,
                cancellationWindow: undefined,
                itemClass: ItemClass.Standard,
                validFrom: undefined,
                validUntil: undefined,
                oneTimeCost: undefined,
            }),
        );

        // Return the analysis result in the expected format
        return {
            pdfAnalyzeResponse: analyzeResult,
            pdfDocument,
            defaultCurrency,
            offerNumber: extractedOfferNumber || undefined,
            validFrom: extractedValidFrom,
            validFor: ValidFor.EveryCustomer,
            regionNetwork,
            rows,
        };
    }

    private inferCurrency(regionNetwork: RegionNetwork): ExtractedValue<Currency> | undefined {
        const currencyRegs = regionNetwork.findRegions({ attribute: 'currency' });

        const allCurrencies = currencyRegs
            .flatMap((currency) => currency.attributes)
            .flatMap((attr) => (attr.attr === 'currency' ? [attr.value] : []));

        // Group currencies by ID and count occurrences
        const currencyCount = new Map<Currency, number>();
        for (const currency of allCurrencies) {
            currencyCount.set(currency, (currencyCount.get(currency) || 0) + 1);
        }

        // Find currency with highest count
        let maxCount = 0;
        let mostCommonCurrency: Currency | undefined;
        for (const [currency, count] of currencyCount) {
            if (count > maxCount) {
                maxCount = count;
                mostCommonCurrency = currency;
            }
        }

        if (!mostCommonCurrency) {
            return undefined;
        }

        return {
            regions: currencyRegs,
            value: mostCommonCurrency,
            confidence: 1,
        };
    }

    private inferOfferNumber(regionNetwork: RegionNetwork): ExtractedValue<string> | undefined {
        const offerNumberRegs = regionNetwork.findRegions({ attribute: 'offerNumber' });

        const allOfferNumbers = offerNumberRegs
            .flatMap((offerNumber) => offerNumber.attributes)
            .flatMap((attr) => (attr.attr === 'offerNumber' ? [attr.value] : []));

        // If no offer numbers found, return undefined
        if (allOfferNumbers.length === 0) {
            return undefined;
        }

        // Group offer numbers and count occurrences
        const offerNumberCount = new Map<string, number>();
        for (const offerNumber of allOfferNumbers) {
            offerNumberCount.set(offerNumber, (offerNumberCount.get(offerNumber) || 0) + 1);
        }

        // Find offer number with highest count
        let maxCount = 0;
        let mostCommonOfferNumber: string | undefined;
        for (const [offerNumber, count] of offerNumberCount) {
            if (count > maxCount) {
                maxCount = count;
                mostCommonOfferNumber = offerNumber;
            }
        }

        if (!mostCommonOfferNumber) {
            return undefined;
        }

        return {
            value: mostCommonOfferNumber,
            regions: offerNumberRegs,
            confidence: 1,
        };
    }

    private inferOfferDate(regionNetwork: RegionNetwork): ExtractedValue<string> | undefined {
        const offerDateRegs = regionNetwork.findRegions({ attribute: 'offerDate' });

        const allOfferDates = offerDateRegs
            .flatMap((offerDate) => offerDate.attributes)
            .flatMap((attr) => (attr.attr === 'offerDate' ? [attr.value] : []));

        // If no offer dates found, return undefined
        if (allOfferDates.length === 0) {
            return undefined;
        }

        // Group offer dates and count occurrences
        const offerDateCount = new Map<string, number>();
        for (const offerDate of allOfferDates) {
            offerDateCount.set(offerDate, (offerDateCount.get(offerDate) || 0) + 1);
        }

        // Find offer date with highest count
        let maxCount = 0;
        let mostCommonOfferDate: string | undefined;
        for (const [offerDate, count] of offerDateCount) {
            if (count > maxCount) {
                maxCount = count;
                mostCommonOfferDate = offerDate;
            }
        }

        if (!mostCommonOfferDate) {
            return undefined;
        }

        return {
            value: mostCommonOfferDate,
            regions: offerDateRegs,
            confidence: 1,
        };
    }

    private isAttributableToRegion(region: Region, inferredAttrs: InferredAttribute[]): boolean {
        for (const attr of this.iterateAttributes(inferredAttrs)) {
            if (
                region.attributes.some((regionAttr) => regionAttr.attr === attr.attr && regionAttr.value === attr.value)
            ) {
                return true;
            }
        }
        return false;
    }

    private *iterateAttributes(inferredAttrs: InferredAttribute[]): Generator<Attribute> {
        for (const attr of inferredAttrs) {
            yield attr;
            if (attr.inferredFrom) {
                yield* this.iterateAttributes(attr.inferredFrom);
            }
        }
    }

    private inferOfferLineItems(regionNetwork: RegionNetwork): ExtractedOfferLineItem[] {
        return regionNetwork
            .findRegions({ attribute: 'part' })
            .map((partRegion): ExtractedOfferLineItem => {
                const part = partRegion.attributes.find((attr) => attr.attr === 'part')?.value ?? undefined;

                const regions = [
                    partRegion,
                    ...regionNetwork.findLinks({ from: partRegion }).map((link) => link.to),
                ].sort(compareByVerticalDistance(partRegion));
                const extractedAttrs = regions.flatMap((region) => region.attributes);
                const withInferredAttrs = infer(extractedAttrs);

                const inferAttribute = <T extends Attributes>(
                    type: T,
                    defaultValue?: AttributeValueOf<T>,
                ): ExtractedValue<AttributeValueOf<T>> => {
                    const attr = findAttributeByType(withInferredAttrs, type);
                    if (!attr) {
                        return { value: defaultValue, regions: [], confidence: 0 };
                    }

                    return {
                        value: (attr.value ?? defaultValue) as AttributeValueOf<T> | undefined,
                        regions: uniqBy(
                            regions.filter((region) => {
                                return this.isAttributableToRegion(region, [attr]);
                            }),
                            (x) => x.id,
                        ),
                        confidence: attr.confidence ?? 1,
                    };
                };

                const moq = inferAttribute('moq');
                const mpq = inferAttribute('mpq');
                const standardFactoryLeadTime = inferAttribute('standardFactoryLeadTime');
                const stock = inferAttribute('stock');
                const unitPrice = inferAttribute('unitPrice');
                const packaging: ExtractedValue<Packaging> = ((): ExtractedValue<Packaging> => {
                    const { regions, value, confidence } = inferAttribute('packaging');
                    if (value === 'none') {
                        return { regions, value: undefined, confidence };
                    }
                    return { regions, value, confidence };
                })();

                const boundingBox = [
                    [partRegion],
                    moq?.regions,
                    mpq?.regions,
                    standardFactoryLeadTime?.regions,
                    stock?.regions,
                    unitPrice?.regions,
                ]
                    .filter((x) => x !== undefined)
                    .flatMap((x) => x)
                    .sort((a, b) => a.pageNumber - b.pageNumber)
                    .reduce((a, b) => {
                        if (a.pageNumber !== b.pageNumber) {
                            return a;
                        }
                        return { ...a, box: a.box.merge(b.box) };
                    });

                return {
                    notes: { regions: [], value: '', confidence: 1 },
                    part: { value: part, regions: [partRegion], confidence: 1 },
                    currency: { regions: [], value: undefined, confidence: 0.5 },
                    moq: moq,
                    mpq: mpq,
                    standardFactoryLeadTime: standardFactoryLeadTime,
                    stock: stock,
                    unitPrice: unitPrice,
                    ncnr: { value: undefined, regions: [], confidence: 1 },
                    packaging: packaging,
                    boundingBox: boundingBox.box,
                    pageNumber: partRegion.pageNumber,
                };
            })
            .sort(
                (a, b) => (a.pageNumber ?? 0) - (b.pageNumber ?? 0) || (a.boundingBox.y ?? 0) - (b.boundingBox.y ?? 0),
            );
    }
}

function convertPartToPartLite(part?: OtsFullPart): PartLite | undefined {
    if (!part) {
        return undefined;
    }
    return {
        kind: PartLiteTypes.OffTheShelf,
        id: part.id,
        manufacturer: part.manufacturer,
        mpn: part.mpn,
    };
}

/**
 * A very naive matching function that checks if the part id matches.
 */
function isOfferMatchingQuoteRequestLineItem(
    quoteRequestLineItems: QuoteRequestLineItemDTO,
    extractedOfferLineItem: ExtractedOfferLineItem,
): boolean {
    const offeredPartId = extractedOfferLineItem.part.value?.id;
    const requestedPartId = quoteRequestLineItems.requested_part?.id;
    if (offeredPartId !== requestedPartId) {
        return false;
    }

    return true;
}

function findUniqueMatchOrUndefined(
    quoteRequestLineItems: QuoteRequestLineItemDTO[],
    extractedOfferLineItem: ExtractedOfferLineItem,
): QuoteRequestLineItemDTO | undefined {
    const matches = quoteRequestLineItems.filter((quoteRequestLineItem) =>
        isOfferMatchingQuoteRequestLineItem(quoteRequestLineItem, extractedOfferLineItem),
    );
    if (matches.length === 1) {
        return matches[0];
    }

    return undefined;
}

async function fetchQuoteRequestLineItems({
    quoteRequestId,
    disabled,
}: {
    quoteRequestId?: string;
    disabled?: boolean;
}): Promise<QuoteRequestLineItemDTO[]> {
    if (disabled || !quoteRequestId) {
        return [];
    }

    return http(
        'GET /quote-request/:id/line-items',
        {
            pathParams: { id: quoteRequestId },
        },
        getToken(),
    )
        .then((res) => res.items)
        .catch(() => []);
}
