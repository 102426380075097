import * as z from 'zod';

import { CurrencyRuntype, MonetaryValueBackendRuntype, OfferOriginEnum, QuantityUnitDTORuntype } from '../backendTypes';
import { SiteRuntype } from '../organizationSettings/InventorySiteBackendTypes';
import { StandardPartDTORuntype } from '../part';
import { PcbPanelSpecificationRuntype } from '../pcb';
import { SolutionDTORuntype } from '../solution';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { PackagingRuntype } from './Packaging';
import { PriceBreakAvailabilityRuntype } from './availablityBackendTypes';

export enum PriceType {
    ListPrice = 'ListPrice',
    ContractPrice = 'ContractPrice',
    QuotePrice = 'QuotePrice',
    CustomerNegotiatedPrice = 'CustomerNegotiatedPrice',
    PurchasePrice = 'PurchasePrice',
    StandardPrice = 'StandardPrice',
    TargetPrice = 'TargetPrice',
}
export const PriceTypeRuntype = z.nativeEnum(PriceType);

export interface OrbweaverOrigin extends z.infer<typeof OrbweaverOriginRuntype> {}
const OrbweaverOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.Orbweaver),
    id: z.string(),
    datasource_name: z.string(),
});
const ManualOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.Manual),
});

const WuerthOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.Wuerth),
});

const BetaLayoutOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.BetaLayout),
});

const QuoteRequestOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.QuoteRequest),
    quote_request_id: z.string().nullable(),
    quote_request_line_item_id: z.number().nullable(),
    quote_tracking_id: z.string().nullable(),
    negotiation_id: z.number().nullable(),
});

export interface ImportOrigin extends z.infer<typeof ImportOriginRuntype> {}
const ImportOriginRuntype = z.object({
    origin: z.literal(OfferOriginEnum.Import),
    imported_by: z.string().nullable().optional(),
    context: z.string().nullable().optional(),
    raw_mpn: z.string().nullable().optional(),
    raw_manufacturer: z.string().nullable().optional(),
});

const DirectApiOriginRuntype = z.object({
    origin: z.enum([
        OfferOriginEnum.APCT,
        OfferOriginEnum.Alba,
        OfferOriginEnum.AlElektronik,
        OfferOriginEnum.Arrow,
        OfferOriginEnum.AvnetAbacus,
        OfferOriginEnum.AvnetApac,
        OfferOriginEnum.AvnetEbv,
        OfferOriginEnum.AvnetSilica,
        OfferOriginEnum.AvnetUsa,
        OfferOriginEnum.Blume,
        OfferOriginEnum.Buerklin,
        OfferOriginEnum.ChipCart,
        OfferOriginEnum.Consigned,
        OfferOriginEnum.Corestaff,
        OfferOriginEnum.Digikey,
        OfferOriginEnum.Element14,
        OfferOriginEnum.Eve,
        OfferOriginEnum.Farnell,
        OfferOriginEnum.Future,
        OfferOriginEnum.Gatema,
        OfferOriginEnum.Gudeco,
        OfferOriginEnum.IBRRingler,
        OfferOriginEnum.Lcsc,
        OfferOriginEnum.Master,
        OfferOriginEnum.Mouser,
        OfferOriginEnum.MultiCB,
        OfferOriginEnum.MyArrow,
        OfferOriginEnum.Newark,
        OfferOriginEnum.Octopart,
        OfferOriginEnum.OnlineComponents,
        OfferOriginEnum.QuestComponents,
        OfferOriginEnum.Rochester,
        OfferOriginEnum.RsComponents,
        OfferOriginEnum.Rutronik,
        OfferOriginEnum.SafePcb,
        OfferOriginEnum.Samtec,
        OfferOriginEnum.Schukat,
        OfferOriginEnum.Sos,
        OfferOriginEnum.Sourcengine,
        OfferOriginEnum.Stackrate,
        OfferOriginEnum.Ti,
        OfferOriginEnum.Tme,
        OfferOriginEnum.TrustedParts,
        OfferOriginEnum.Tti,
        OfferOriginEnum.Venkel,
        OfferOriginEnum.Weltron,
        OfferOriginEnum.WinSource,
        OfferOriginEnum.WuerthEisos,
        OfferOriginEnum.Heilind,
        OfferOriginEnum.IcHub,
        OfferOriginEnum.Unikey,
    ]),
    id: z.string().optional(),
});

export type AllOrigins = z.infer<typeof AllOriginRuntype>;
export const AllOriginRuntype = z.union([
    ImportOriginRuntype,
    DirectApiOriginRuntype,
    OrbweaverOriginRuntype,
    ManualOriginRuntype,
    WuerthOriginRuntype,
    BetaLayoutOriginRuntype,
    QuoteRequestOriginRuntype,
]);

export type PriceBreakDTO = z.infer<typeof PriceBreakRuntype>;
const PriceBreakRuntype = z.object({
    moq: z.number(),
    unit_price: z.object({
        amount: z.string(),
        currency: CurrencyRuntype,
    }),
    mpq: z.number(),
});

export type OnOrderDTO = z.infer<typeof OnOrderRuntype>;
const OnOrderRuntype = z.object({
    quantity: z.number(),
    date: z.string().nullable(),
});

export interface PriceBreaksDTO extends z.infer<typeof PriceBreaksDTORuntype> {}
export const PriceBreaksDTORuntype = z.object({
    factory_quantity: z.number().nullable(),
    factory_lead_time_days: z.number().nullable(),
    on_order: z.array(OnOrderRuntype),
    stock: z.number().nullable(),
    total_stock: z.number().nullable(),
    price_breaks: z.array(PriceBreakRuntype),
});

export interface PricePointsDTO extends z.infer<typeof PricePointsDTORuntype> {}
const PricePointsDTORuntype = z.object({
    quantity: z.number(),
    unit_price: MonetaryValueBackendRuntype,
    availability: PriceBreakAvailabilityRuntype,
});

export interface CustomPartPriceBreakDTO extends z.infer<typeof CustomPartPriceBreakRuntype> {}
const CustomPartPriceBreakRuntype = z.object({
    minimum_order_quantity: z.number(),
    minimum_packaging_quantity: z.number(),
    price_per_measurement: MonetaryValueBackendRuntype,
    availability: PriceBreakAvailabilityRuntype,
    description: z.string().nullable(),
});

export interface UnitOfMeasurementDTO extends z.infer<typeof UnitOfMeasurementDTORuntype> {}
export const UnitOfMeasurementDTORuntype = z.object({
    quantity_unit: QuantityUnitDTORuntype,
});

export enum ItemClass {
    Standard = 'Standard',
    NonStandard = 'Non-standard',
}
export const ItemClassEnumRunType = z.nativeEnum(ItemClass);
const OneTimeCostPriceDTORuntype = z.object({
    amount: z.string(),
    currency: CurrencyRuntype,
});

export interface OneTimeCostDTO extends z.infer<typeof OneTimeCostDTORuntype> {}
export const OneTimeCostDTORuntype = z.object({
    description: z.null().or(z.string()),
    price: OneTimeCostPriceDTORuntype,
});

export interface OneTimeCostInputDTO extends z.infer<typeof OneTimeCostInputDTORuntype> {}
const OneTimeCostInputDTORuntype = z.object({
    description: z.undefined().or(z.string()),
    amount: z.string(),
});
/*
The following types represents a hierarchy of part offer data types.
Utility functions for working with those types are defined in isStandardPartOffer.ts

+-----------------------------------------------------------------------+
|                        StandardPartOfferDTO                           |
+-----------------------------------------------------------------------+

+---------------------------------------++----------------------------+
|             StandardPartMarketOfferDTO                              |
|               +-- OffTheShelfMarketOfferDTO                         |
|               +-- InternalPartNumberMarketOfferDTO                  |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             StandardPartInventoryOfferDTO                           |
|               +-- OffTheShelfInventoryOfferDTO                      |
|               +-- InternalPartNumberInventoryOfferDTO               |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             OffTheShelfOfferDTO                                     |
|               +-- OffTheShelfMarketOfferDTO                         |
|               +-- OffTheShelfInventoryOfferDTO                      |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             InternalPartNumberOfferDTO                              |
|               +-- InternalPartNumberMarketOfferDTO                  |
|               +-- InternalPartNumberInventoryOfferDTO               |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             BaseStandardPartOfferDTO                                |
+---------------------------------------++----------------------------+
*/

const BaseStandardPartOfferDTORuntype = z.object({
    id: z.string(),
    origin: AllOriginRuntype,
    price_type: PriceTypeRuntype,
    customer: z.null().or(z.string()),
    rfq: z.null().or(z.string()),
    notes: z.null().or(z.string()),
    creation_date: z.string(),
    valid_from: z.null().or(z.string()),
    valid_until: z.null().or(z.string()),
    item_class: ItemClassEnumRunType.nullable(),
    cancellation_window_in_days: z.number().nullable(),
    one_time_costs: z.array(OneTimeCostDTORuntype),
    offer_number: z.null().or(z.string()),
    available_prices: PriceBreaksDTORuntype,
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    packaging: z.null().or(PackagingRuntype),
    offer_url: z.null().or(z.string()),
    ncnr: z.null().or(z.boolean()),
    attachment: z.string().nullable().optional(),
});

export enum StandardPartOfferLinkedPartType {
    OffTheShelf = 'OffTheShelf',
    InternalPartNumber = 'InternalPartNumber',
}
export const StandardPartOfferLinkedPartTypeRunType = z.nativeEnum(StandardPartOfferLinkedPartType);

export enum StandardPartOfferLinkedLocationType {
    SupplierAndStockLocation = 'SupplierAndStockLocation',
    InventorySite = 'InventorySite',
}
export const StandardPartOfferLinkedLocationTypeRunType = z.nativeEnum(StandardPartOfferLinkedLocationType);

export type OffTheShelfMarketOfferDTO = z.infer<typeof OffTheShelfMarketOfferDTORuntype>;
const OffTheShelfMarketOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal(StandardPartOfferLinkedPartType.OffTheShelf),
        id: z.string(),
    }),
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: z.literal(StandardPartOfferLinkedLocationType.SupplierAndStockLocation),
    }),
    supplier_part_number: z.string().nullable(),
});

export type InternalPartNumberMarketOfferDTO = z.infer<typeof InternalPartNumberMarketOfferDTORuntype>;
const InternalPartNumberMarketOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal(StandardPartOfferLinkedPartType.InternalPartNumber),
        id: z.string(),
    }),
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: z.literal(StandardPartOfferLinkedLocationType.SupplierAndStockLocation),
    }),
    supplier_part_number: z.string().nullable(),
});

export type OffTheShelfInventoryOfferDTO = z.infer<typeof StandardPartInventoryOfferDTORuntype>;
const OffTheShelfInventoryOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal(StandardPartOfferLinkedPartType.OffTheShelf),
        id: z.string(),
    }),
    linked_location: SiteRuntype.extend({
        type: z.literal(StandardPartOfferLinkedLocationType.InventorySite),
    }),
    supplier_part_number: z.string().nullable(),
});

export type InternalPartNumberInventoryOfferDTO = z.infer<typeof StandardPartInventoryOfferDTORuntype>;
const InternalPartNumberInventoryOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal(StandardPartOfferLinkedPartType.InternalPartNumber),
        id: z.string(),
    }),
    linked_location: SiteRuntype.extend({
        type: z.literal(StandardPartOfferLinkedLocationType.InventorySite),
    }),
    supplier_part_number: z.string().nullable(),
});

export type LinkedLocation = StandardPartOfferDTO['linked_location'];

export type StandardPartMarketOfferDTO = z.infer<typeof StandardPartMarketOfferDTORuntype>;
export const StandardPartMarketOfferDTORuntype = z.union([
    OffTheShelfMarketOfferDTORuntype,
    InternalPartNumberMarketOfferDTORuntype,
]);

export type StandardPartInventoryOfferDTO = z.infer<typeof StandardPartInventoryOfferDTORuntype>;
export const StandardPartInventoryOfferDTORuntype = z.union([
    OffTheShelfInventoryOfferDTORuntype,
    InternalPartNumberInventoryOfferDTORuntype,
]);

export type OffTheShelfOfferDTO = z.infer<typeof OffTheShelfOfferDTORuntype>;
const OffTheShelfOfferDTORuntype = z.union([OffTheShelfMarketOfferDTORuntype, OffTheShelfInventoryOfferDTORuntype]);

export type InternalPartNumberOfferDTO = z.infer<typeof InternalPartNumberOfferDTORuntype>;
export const InternalPartNumberOfferDTORuntype = z.union([
    InternalPartNumberMarketOfferDTORuntype,
    InternalPartNumberInventoryOfferDTORuntype,
]);

export type StandardPartOfferDTO = z.infer<typeof StandardPartOfferDTORuntype>;
export const StandardPartOfferDTORuntype = z.union([
    StandardPartMarketOfferDTORuntype,
    StandardPartInventoryOfferDTORuntype,
]);

const AvailabilityInputDTORuntype = z.object({
    stock: z.number().nullable(),
    factory_lead_time_days: z.number().nullable(),
    factory_quantity: z.number().nullable(),
    on_order: z.array(OnOrderRuntype),
});

export interface PriceBreakInputDTO extends z.infer<typeof PriceBreakInputDTORuntype> {}
const PriceBreakInputDTORuntype = z.object({
    moq: z.number(),
    unit_price: z.number().nullable(),
    mpq: z.number(),
});

export interface StandardPartOfferInputDTO extends z.infer<typeof StandardPartOfferInputDTORuntype> {}
export const StandardPartOfferInputDTORuntype = z.object({
    linked_part: z.object({
        type: StandardPartOfferLinkedPartTypeRunType,
        id: z.string(),
    }),
    supplier_and_stock_location: z.string(),
    supplier_part_number: z.string().nullable(),
    price_type: PriceTypeRuntype,
    customer: z.null().or(z.string()),
    rfq: z.null().or(z.string()),
    notes: z.null().or(z.string()),
    valid_until: z.null().or(z.string()),
    one_time_costs: z.array(OneTimeCostInputDTORuntype),
    valid_from: z.null().or(z.string()),
    item_class: ItemClassEnumRunType.nullable(),
    cancellation_window_in_days: z.number().nullable(),
    currency: CurrencyRuntype,
    availability_input: z.null().or(AvailabilityInputDTORuntype),
    price_break_inputs: z.array(PriceBreakInputDTORuntype),
    packaging: z.null().or(PackagingRuntype),
    unit_of_measurement: QuantityUnitDTORuntype,
    offer_number: z.null().or(z.string()),
    offer_url: z.null().or(z.string()).optional(),
    ncnr: z.null().or(z.boolean()),
});

export interface StandardPartOfferUpdateDTO extends z.infer<typeof StandardPartOfferUpdateDTORuntype> {}
export const StandardPartOfferUpdateDTORuntype = StandardPartOfferInputDTORuntype.pick({
    supplier_part_number: true,
    supplier_and_stock_location: true,
    price_type: true,
    customer: true,
    rfq: true,
    notes: true,
    valid_until: true,
    valid_from: true,
    item_class: true,
    cancellation_window_in_days: true,
    one_time_costs: true,
    currency: true,
    availability_input: true,
    price_break_inputs: true,
    packaging: true,
    unit_of_measurement: true,
    offer_number: true,
    ncnr: true,
});

export enum ValidFor {
    EveryCustomer = 'ever-customer',
    ThisCustomer = 'this-customer',
    ThisRfQ = 'this-rfq',
}

export type CustomLinkedPartDTO = z.infer<typeof CustomLinkedPartDTORuntype>;
export const CustomLinkedPartDTORuntype = z.union([
    z.object({
        type: z.literal('CustomPart'),
        id: z.string(),
    }),
    z.object({
        type: z.literal('CustomComponent'),
        id: z.string(),
    }),
]);

const BaseCustomPartOfferDTORuntype = z.object({
    id: z.string(),
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: z.literal(StandardPartOfferLinkedLocationType.SupplierAndStockLocation),
    }),
    origin: AllOriginRuntype,
    creation_date: z.string(),
    has_order_files: z.boolean(),
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    valid_until: z.null().or(z.string()),
    one_time_costs: z.array(OneTimeCostDTORuntype),
    notes: z.null().or(z.string()),
    offer_number: z.null().or(z.string()),
    offer_url: z.null().or(z.string()),
    price_type: PriceTypeRuntype.nullable(),
    specification: PcbPanelSpecificationRuntype.nullable(),
    sourcing_batch_size: z.number().nullable(),
    total_offered_quantity: z.number().nullable(),
    price_breaks: z.array(CustomPartPriceBreakRuntype),
});

export interface CustomPartOfferDTO extends z.infer<typeof CustomPartOfferDTORuntype> {}
export const CustomPartOfferDTORuntype = BaseCustomPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal('CustomPart'),
        id: z.string(),
    }),
});

export interface CustomComponentOfferDTO extends z.infer<typeof CustomComponentOfferDTORuntype> {}
export const CustomComponentOfferDTORuntype = BaseCustomPartOfferDTORuntype.extend({
    linked_part: z.object({
        type: z.literal('CustomComponent'),
        id: z.string(),
    }),
});

export type CustomOptionOfferDTO = z.infer<typeof CustomOptionOfferDTORuntype>;
export const CustomOptionOfferDTORuntype = z.union([CustomPartOfferDTORuntype, CustomComponentOfferDTORuntype]);

export interface CustomPricePointInputDTO extends z.infer<typeof CustomPricePointInputDTORuntype> {}
const CustomPricePointInputDTORuntype = z.object({
    quantity: z.number(),
    amount: z.string(),
    lead_time_days: z.undefined().or(z.number()),
});

export interface CustomPartPriceBreakInputDTO extends z.infer<typeof CustomPartPriceBreakInputDTORuntype> {}
const CustomPartPriceBreakInputDTORuntype = z.object({
    minimum_order_quantity: z.number(),
    minimum_packaging_quantity: z.number(),
    amount: z.string(),
    lead_time_days: z.undefined().or(z.number()),
    description: z.undefined().or(z.string()),
});

export interface CustomPartOfferInputDTO extends z.infer<typeof CustomPartOfferInputDTORuntype> {}
export const CustomPartOfferInputDTORuntype = z.object({
    linked_part: CustomLinkedPartDTORuntype,
    supplier_and_stock_location: z.string(),
    unit_of_measurement: QuantityUnitDTORuntype,
    currency: CurrencyRuntype,
    valid_until: z.undefined().or(z.string()),
    one_time_costs: z.array(OneTimeCostInputDTORuntype),
    price_type: z.undefined().or(PriceTypeRuntype),
    offer_number: z.undefined().or(z.string()),
    notes: z.undefined().or(z.string()),
    sourcing_scenario_id: z.undefined().or(z.string()),
    sourcing_batch_size: z.undefined().or(z.number()).or(z.null()),
    total_offered_quantity: z.number().optional(),
    price_breaks: z.array(CustomPartPriceBreakInputDTORuntype),
});

export interface CustomPartOfferUpdateDTO extends z.infer<typeof CustomPartOfferUpdateDTORuntype> {}
export const CustomPartOfferUpdateDTORuntype = z.object({
    linked_part: CustomLinkedPartDTORuntype,
    supplier_and_stock_location: z.string(),
    unit_of_measurement: QuantityUnitDTORuntype,
    currency: CurrencyRuntype,
    valid_until: z.null().or(z.string()),
    one_time_costs: z.array(OneTimeCostInputDTORuntype),
    notes: z.null().or(z.string()),
    price_type: z.undefined().or(PriceTypeRuntype),
    sourcing_batch_size: z.undefined().or(z.number()).or(z.null()),
    total_offered_quantity: z.number().optional(),
    price_breaks: z.array(CustomPartPriceBreakInputDTORuntype),
});

const BoundingRegion = z.object({
    pageNumber: z.number(),
    polygon: z.array(z.number()),
});

const ObjectTypeCurrencyRuntype = z.object({
    type: z.literal('currency'),
    valueCurrency: z
        .object({
            amount: z.number(),
        })
        .optional(),
    boundingRegions: z.array(BoundingRegion),
    confidence: z.number(),
    content: z.string(),
});

const ObjectTypeNumberRuntype = z.object({
    type: z.literal('number'),
    valueNumber: z.number(),
    boundingRegions: z.array(BoundingRegion),
    confidence: z.number(),
    content: z.string(),
});

const ObjectTypeStringRuntype = z.object({
    type: z.literal('string'),
    valueString: z.string(),
    boundingRegions: z.array(BoundingRegion),
    confidence: z.number(),
    content: z.string(),
});

const ObjectTypeDateRuntype = z.object({
    type: z.literal('date'),
    valueDate: z.string().optional(),
    boundingRegions: z.array(BoundingRegion),
    confidence: z.number(),
    content: z.string(),
});

export interface ObjectTypeObject extends z.infer<typeof ObjectTypeObjectRuntype> {}
const ObjectTypeObjectRuntype = z.object({
    type: z.literal('object'),
    valueObject: z
        .object({
            UnitPrice: ObjectTypeCurrencyRuntype.optional(),
            ProductCode: ObjectTypeStringRuntype.optional(),
            Unit: ObjectTypeStringRuntype.optional(),
            Quantity: ObjectTypeNumberRuntype.optional(),
            Amount: ObjectTypeCurrencyRuntype.optional(),
        })
        .optional(), // very annoyingly so there's been occasions where Azure just skips this
    boundingRegions: z.array(BoundingRegion),
    confidence: z.number(),
    content: z.string(),
});

const ObjectTypeArrayRuntype = z.object({
    type: z.literal('array'),
    valueArray: z.array(ObjectTypeObjectRuntype),
});

export const PartOfferUpdateStatusRuntype = z.union([
    z.literal('InactiveAPI'),
    z.object({
        InProgress: z.object({ progress_id: z.string() }).optional(),
        RateLimited: z.object({ seconds_to_expiry: z.number() }).optional(),
        RecentlyUpdated: z.object({ last_part_status: z.string() }).optional(),
    }),
]);

export interface AzureExtractionResult extends z.infer<typeof AzureExtractionResultRuntype> {}
export const AzureExtractionResultRuntype = z.object({
    analyzeResult: z.object({
        pages: z.array(
            z.object({
                pageNumber: z.number(),
                width: z.number(),
                height: z.number(),
                lines: z.array(
                    z.object({
                        polygon: z.array(z.number()),
                        content: z.string(),
                    }),
                ),
                words: z.array(
                    z.object({
                        polygon: z.array(z.number()),
                        content: z.string(),
                    }),
                ),
            }),
        ),
        tables: z.array(
            z.object({
                cells: z.array(
                    z.object({
                        kind: z.literal('columnHeader').optional(),
                        columnIndex: z.number(),
                        rowIndex: z.number(),
                        columnSpan: z.number().optional(),
                        content: z.string(),
                        boundingRegions: z.array(BoundingRegion),
                    }),
                ),
            }),
        ),
        paragraphs: z
            .array(
                z.object({
                    content: z.string(),
                    boundingRegions: z.array(BoundingRegion),
                }),
            )
            .nullable(),
        keyValuePairs: z.array(
            z.object({
                key: z.object({
                    content: z.string(),
                    boundingRegions: z.array(BoundingRegion),
                }),
                value: z
                    .object({
                        content: z.string(),
                        boundingRegions: z.array(BoundingRegion),
                    })
                    .optional(),
                confidence: z.number(),
            }),
        ),
        documents: z.array(
            z.object({
                docType: z.literal('invoice'),
                boundingRegions: z.array(BoundingRegion),
                fields: z.object({
                    AmountDue: z
                        .object({
                            content: z.string(),
                        })
                        .optional(),
                    DueDate: ObjectTypeDateRuntype.optional(),
                    InvoiceDate: ObjectTypeDateRuntype.optional(),
                    InvoiceId: ObjectTypeStringRuntype.optional(),
                    InvoiceTotal: z
                        .object({
                            content: z.string(),
                        })
                        .optional(),
                    Items: ObjectTypeArrayRuntype.optional(),
                    ServiceEndDate: ObjectTypeDateRuntype.optional(),
                    SubTotal: z
                        .object({
                            content: z.string(),
                        })
                        .optional(),
                }),
            }),
        ),
    }),
});

export type PdfAnalyzeResponse = z.infer<typeof PdfAnalyzeResponseRuntype>;
export const PdfAnalyzeResponseRuntype = z.intersection(
    AzureExtractionResultRuntype.shape.analyzeResult,
    z.object({
        attachment: z.string().optional(),
    }),
);

const QuoteImportEventTypeEnumRuntype = z.union([z.literal('Pdf'), z.literal('Excel'), z.literal('SupplierPortal')]);

export const QuoteImportEventRuntype = z.object({
    type: QuoteImportEventTypeEnumRuntype,
});

export interface StandardPartOfferBulkInputDTO extends z.infer<typeof StandardPartOfferBulkInputDTORuntype> {}
export const StandardPartOfferBulkInputDTORuntype = z.object({
    requested_part: StandardPartDTORuntype,
    linked_part: StandardPartDTORuntype,
    supplier_part_number: z.string().optional(),
    supplier_and_stock_location: z.string(),
    price_type: PriceTypeRuntype,
    customer: z.string().nullable(),
    rfq_id: z.string().nullable(),
    notes: z.string().nullable(),
    valid_until: z.string().nullable(),
    valid_from: z.string().nullable().optional(),
    currency: CurrencyRuntype,
    availability_input: AvailabilityInputDTORuntype.nullable(),
    price_break_inputs: z.array(PriceBreakInputDTORuntype),
    packaging: PackagingRuntype.nullable(),
    unit_of_measurement: QuantityUnitDTORuntype,
    offer_number: z.string().nullable(),
    attachment: z.string().nullable(),
    ncnr: z.boolean().nullable(),
});

export interface NegotiationsStandardPartOfferBulkInputDTO
    extends z.infer<typeof NegotiationsStandardPartOfferBulkInputDTORuntype> {}
export const NegotiationsStandardPartOfferBulkInputDTORuntype = z.object({
    quote_request_line_item_id: z.number().nullable(),
    linked_part: StandardPartDTORuntype,
    supplier_part_number: z.string().nullable(),
    supplier_and_stock_location: z.string(),
    price_type: PriceTypeRuntype,
    notes: z.string().nullable(),
    valid_until: z.string().nullable(),
    valid_from: z.string().nullable(),
    item_class: ItemClassEnumRunType.nullable(),
    cancellation_window_in_days: z.number().nullable(),
    currency: CurrencyRuntype,
    availability_input: AvailabilityInputDTORuntype.nullable(),
    price_break_inputs: z.array(PriceBreakInputDTORuntype),
    packaging: PackagingRuntype.nullable(),
    unit_of_measurement: QuantityUnitDTORuntype,
    offer_number: z.string().nullable(),
    attachment: z.string().nullable(),
    ncnr: z.boolean().nullable(),
    bid: z.boolean().nullable(),
    one_time_costs: z.array(OneTimeCostInputDTORuntype).nullable(),
});

export interface StandardPartOfferWithSolutionsDTO extends z.infer<typeof StandardPartOfferWithSolutionsDTORuntype> {}
export const StandardPartOfferWithSolutionsDTORuntype = z.object({
    offer: StandardPartOfferDTORuntype,
    solutions: z.array(
        z.object({
            quantity: z.number(),
            fastest: SolutionDTORuntype.nullable(),
            best_price: SolutionDTORuntype.nullable(),
        }),
    ),
});

export interface QuoteImporterResponse extends z.infer<typeof QuoteImporterResponseRuntype> {}
export const QuoteImporterResponseRuntype = z.object({
    quote_tracking_id: z.string(),
    ids: z.array(z.string()),
    design_items: z.array(z.string()),
});

export interface ImportOffersRequestBody extends z.infer<typeof ImportOffersRequestBodyRuntype> {}
export const ImportOffersRequestBodyRuntype = z.object({
    part: z.union([
        z.object({
            internal_part_number: z.string(),
        }),
        z.object({
            internal_part_number: z.string().optional(),
            manufacturer_part_number: z.string(),
            manufacturer: z.string(),
        }),
    ]),
    customer: z
        .object({
            name: z.string().optional(),
            number: z.string(),
        })
        .optional(),
    availability: z
        .object({
            available_stock: z.number(),
            total_stock: z.number().optional(),
            lead_time: z.number().optional(),
        })
        .optional(),
    notes: z.string().optional(),
    packaging: PackagingRuntype.optional(),
    price_type: PriceTypeRuntype.optional(),
    unit_of_measurement: QuantityUnitDTORuntype.optional(),
    valid_until: z.string().optional(),
    valid_from: z.string().optional(),
    supplier: z.union([
        z.object({
            type: z.literal('Internal'),
        }),
        z.object({
            type: z.literal('External'),
            supplier_number: z.string(),
            supplier: z.string().optional(),
        }),
    ]),
    prices: z.array(
        z.object({
            moq: z.number().optional(),
            mpq: z.number().optional(),
            unit_price: MonetaryValueBackendRuntype,
        }),
    ),
});
