import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    OtsFullPart,
    PartLite,
    PartLiteTypes,
    StandardPartDTO,
    StandardPartTypes,
} from '@luminovo/http-client';
import { FormFieldsConfiguration } from '../../types';

export function resolveOtsPartsRecursively(
    part: PartLite | undefined | null,
): Array<PartLite & { kind: PartLiteTypes.OffTheShelf }> {
    if (!isPresent(part)) {
        return [];
    }
    if (part.kind === PartLiteTypes.OffTheShelf) {
        return [part];
    }
    if (part.kind === PartLiteTypes.Generic) {
        return part.matches.flatMap((m) => resolveOtsPartsRecursively(m));
    }
    if (part.kind === PartLiteTypes.Ipn) {
        return part.matches.flatMap((m) => resolveOtsPartsRecursively(m));
    }
    if (part.kind === PartLiteTypes.RawSpecification) {
        return [];
    }
    if (part.kind === PartLiteTypes.Custom) {
        return [];
    }
    if (part.kind === PartLiteTypes.CustomComponent) {
        return [];
    }
    if (part.kind === PartLiteTypes.Unknown) {
        return [];
    }
    assertUnreachable(part);
}

export function resolveOtsFullPartsRecursively(part: FullPart | undefined | null): Array<OtsFullPart> {
    if (!isPresent(part)) {
        return [];
    }
    if (isOtsFullPart(part)) {
        return [part];
    }
    if (isGenericFullPart(part)) {
        return part.matches.flatMap((m) => resolveOtsFullPartsRecursively(m));
    }
    if (isOtsComponentFull(part)) {
        return part.matches.flatMap((m) => resolveOtsFullPartsRecursively(m.part.data));
    }
    if (isCustomComponentFull(part)) {
        return [];
    }
    if (isCustomFullPart(part)) {
        return [];
    }
    assertUnreachable(part);
}

export function columnId(col: keyof FormFieldsConfiguration): string {
    return col;
}

export function convertPartLiteToStandardPart(partLite: PartLite): StandardPartDTO {
    switch (partLite.kind) {
        case PartLiteTypes.OffTheShelf:
            return { type: StandardPartTypes.OffTheShelf, data: partLite.id };
        case PartLiteTypes.Generic:
            return { type: StandardPartTypes.Generic, data: partLite.id };
        case PartLiteTypes.Ipn:
            return { type: StandardPartTypes.Ipn, data: partLite.id };
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Custom:
        case PartLiteTypes.CustomComponent:
        case PartLiteTypes.Unknown:
            throw new Error('Cannot convert part lite to standard part');
    }
}

export function convertOtsFullPartToPartLite(part: OtsFullPart): PartLite & { kind: PartLiteTypes.OffTheShelf } {
    return {
        kind: PartLiteTypes.OffTheShelf,
        id: part.id,
        manufacturer: part.manufacturer,
        mpn: part.mpn,
    };
}
