import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import readXlsxFile from 'read-excel-file';
import { ExcelQuoteRequestParser } from '../../../model/file-parsers/ExcelQuoteRequestParser/ExcelQuoteRequestParser';
import { LegacyPdfOfferParser } from '../../../model/file-parsers/LegacyPdfOfferParser';
import { HttpPartLoader } from '../../../model/PartLoader';
import { OfferFileParser } from '../../../types';
import { usePdfViewerState } from '../../PdfViewer';
import { OfferImporterState } from '../state';
import { QuoteRequest } from '../types';
export interface UseMutationAnalyzeFileProps {
    file: File;
    quoteRequest?: QuoteRequest;
}

export function useMutationAnalyzeFile({
    state,
}: {
    state: OfferImporterState;
}): UseMutationResult<null, Error, UseMutationAnalyzeFileProps> {
    const analizers: OfferFileParser[] = [
        new LegacyPdfOfferParser(),
        new ExcelQuoteRequestParser(new HttpPartLoader(), readXlsxFile),
    ];

    const setAnalyzeResult = useSetAtom(state.fields.$analyzeResult);
    const setDefaultCurrency = useSetAtom(state.fields.$defaultCurrency);
    const setAttachment = useSetAtom(state.fields.$attachment);
    const setOfferNumber = useSetAtom(state.fields.$offerNumber);
    const setValidFrom = useSetAtom(state.fields.$validFrom);
    const setValidUntil = useSetAtom(state.fields.$validUntil);
    const setValidFor = useSetAtom(state.fields.$validFor);
    const setRows = useSetAtom(state.fields.$rows);

    const [, dispatch] = usePdfViewerState();

    return useMutation({
        mutationFn: async ({ file, quoteRequest }: UseMutationAnalyzeFileProps) => {
            for (const analyzer of analizers) {
                if (analyzer.supportsFile(file)) {
                    const result = await analyzer.parse({ file, quoteRequest });

                    setAnalyzeResult(result.pdfAnalyzeResponse);
                    setDefaultCurrency(result.defaultCurrency);
                    setAttachment(result.attachment);
                    setOfferNumber(result.offerNumber);
                    setValidFrom(result.validFrom);
                    setValidUntil(result.validUntil);
                    setValidFor(result.validFor);
                    setRows({ type: 'setRows', rows: result.rows });
                    dispatch({ type: 'setPdfDocumentProxy', pdfDocumentProxy: result.pdfDocument });
                    if (result.regionNetwork) {
                        dispatch({ type: 'setRegions', regions: result.regionNetwork });
                    }

                    return null;
                }
            }
            throw new Error('File type not supported');
        },
        throwOnError: true,
    });
}
