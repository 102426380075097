import { getCustomerPortalTenant } from '@/permissions/tenants';
import { Trans, t } from '@lingui/macro';
import { useAuth } from '@luminovo/auth';
import {
    Currency,
    assertPresent,
    assertUnreachable,
    compareByStringKey,
    formatDecimal,
    formatLongDateTime,
    formatRelativeTime,
    groupBy,
    isPresent,
    sortBy,
    throwErrorUnlessProduction,
    transEnum,
    uniqBy,
} from '@luminovo/commons';
import {
    CenteredLayout,
    Flexbox,
    FormItem,
    Link,
    Message,
    NonIdealState,
    SecondaryButton,
    SidebarLayout,
    Tag,
    Text,
    Thumbnail,
    Toolbar,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import {
    AvailabilityType,
    CustomComponentFull,
    CustomFullPart,
    CustomPartPriceBreakDTO,
    CustomPartTypeEnum,
    ExistingPanelDTO,
    FileManagementPreferenceDTO,
    PCBV2,
    PanelDTO,
    QuoteTrackingDTO,
    StepEnum,
    isCustomComponentFull,
    isCustomFullPart,
} from '@luminovo/http-client';
import { QuoteTrackingStateChip, customPartTypeTranslations } from '@luminovo/sourcing-core';
import { Add, DescriptionOutlined, ExitToApp, FolderZipOutlined } from '@mui/icons-material';
import { CircularProgress, Divider } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CommentsBox } from '../../../components/CommentsDrawer/CommentsBox';
import InformationDialogBox from '../../../components/dialogBox/InformationDialogBox';
import { ErrorFallback } from '../../../components/errorHandlers/ErrorBoundary';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import {
    useDownloadPCBFiles,
    useExportPanelSpecificationInPDF,
    useExportPcbSpecificationInPDF,
} from '../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { layerStackTypeTranslations } from '../../../resources/pcb/i18n';
import { isPcbSetupWithoutFiles } from '../../../resources/pcb/pcbFunctions';
import { usePcb } from '../../../resources/pcb/pcbHandlers';
import { useQuoteTracking } from '../../../resources/quoteTracking/quoteTrackingHandler';
import { analytics } from '../../../utils/analytics';
import { extractPcbWidthAndHeight } from '../../Pcb/PanelizationTab/utils/extractPcbWidthAndHeight';
import { FormItemAdditionalFiles } from './components/FormItemAdditionalFiles';
import { FormItemCurrency } from './components/FormItemCurrency';
import { FormItemDueDate } from './components/FormItemDueDate';
import { FormItemOfferNumber } from './components/FormItemOfferNumber';
import { FormItemOfferNumbers } from './components/FormItemOfferNumbers';
import { FormItemParts } from './components/FormItemParts';
import { FormItemValidUntil } from './components/FormItemValidUntil';
import { OneTimeCostsTable } from './components/OneTimeCostsTable';
import { PriceBreaksTable } from './components/PricePointTable';
import { ToolbarSupplierQuotePage } from './components/ToolbarSupplierQuotePage';
import { useSubmitQuote } from './hooks/useSubmitCustomPartQuote';
import { getSupplierPortalMode } from './model/utils';
import { CustomPartQuoteFormValues } from './types';

const calculateValidUntilDate = (defaultValidDate: number | null): string | undefined => {
    if (defaultValidDate === null) {
        return undefined;
    }
    const date = new Date();
    date.setDate(date.getDate() + (defaultValidDate ?? 0));
    return date.toISOString().split('T')[0];
};

export function CustomPartQuoteDetails({ quoteTrackingId }: { quoteTrackingId: string }) {
    const { data: quoteTracking, isLoading } = useQuoteTracking({ quoteTrackingId });
    const { preferredCurrency, isLoading: isCurrencyLoading } = useGlobalCurrency();
    const { data: supplierPortalSettings } = useHttpQuery('GET /organization-settings/supplier-portal-settings', {});
    const [showAdditionalFilesWarning, setShowAdditionalFilesWarning] = React.useState(false);

    const onSubmit = useSubmitQuote({ quoteTrackingId, rfq: quoteTracking?.rfq ?? '' });

    const { logout } = useAuth({
        auth0OrgId: getCustomerPortalTenant()?.auth0OrgId,
    });

    if (isLoading || isCurrencyLoading || !isPresent(supplierPortalSettings)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (!quoteTracking) {
        return (
            <CenteredLayout>
                <NonIdealState
                    title={t`Quote request not found`}
                    description={t`The quote request does not exist.`}
                    action={{
                        onClick: () => {
                            analytics.track('supplier_quote_request_not_found');
                            logout();
                        },
                        variant: 'outlined',
                        color: 'primary',
                        startIcon: <ExitToApp />,
                        children: t`Log out`,
                    }}
                />
            </CenteredLayout>
        );
    }

    const offers: CustomPartQuoteFormValues['offers'] = Object.values(
        groupBy(quoteTracking.request_custom_parts, (p) => p.kind.id),
    ).map((parts) => {
        const part = assertPresent(parts[0]);

        const offer = quoteTracking.custom_part_offers.find((offer) => offer.linked_part.id === part.kind.id);

        const priceBreaks: CustomPartPriceBreakDTO[] = parts
            .map((p) => p.required_quantity)
            .filter(isPresent)
            .map((quantity): CustomPartPriceBreakDTO => {
                return {
                    minimum_order_quantity: quantity,
                    minimum_packaging_quantity: 1,
                    price_per_measurement: { amount: '', currency: Currency.EUR },
                    availability: { type: AvailabilityType.LeadTime, days: 1 },
                    description: null,
                };
            })
            .sort((a, b) => a.minimum_order_quantity - b.minimum_order_quantity);

        if (!offer) {
            return {
                linked_part: { type: 'CustomPart', id: part.kind.id },
                price_breaks: priceBreaks,
                one_time_costs: [],
            };
        }

        return {
            linked_part: { type: 'CustomPart', id: part.kind.id },
            price_breaks: offer.price_breaks,
            one_time_costs: offer.one_time_costs,
        };
    });
    const defaultValues: CustomPartQuoteFormValues = {
        currency: preferredCurrency,
        offerNumber: String(quoteTracking.number),
        offers: sortBy(offers, (offer) => offer.linked_part.id),
        validUntil: calculateValidUntilDate(supplierPortalSettings.default_days_before_offer_invalid),
        supplierAndStockLocation: quoteTracking.supplier_and_stock_location.id,
    };
    const isMissingRequiredAdditionalFiles =
        supplierPortalSettings?.upload_additional_supplier_files === StepEnum.required &&
        quoteTracking.files.length === 0;

    const handleSubmit = async (form: CustomPartQuoteFormValues) => {
        if (isMissingRequiredAdditionalFiles) {
            setShowAdditionalFilesWarning(true);
            return;
        }
        await onSubmit(form);
    };

    return (
        <FormContainer key={getSupplierPortalMode(quoteTracking)} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <InformationDialogBox
                isDialogOpen={showAdditionalFilesWarning}
                title={t`Please upload your quote documents to proceed.`}
                buttonText={t`OK`}
                onReject={() => setShowAdditionalFilesWarning(false)}
            >
                <Text>
                    <Trans>
                        Please upload formal quote documents before submitting your quote, as required by the customer.
                    </Trans>
                </Text>
            </InformationDialogBox>
            <SidebarLayout
                navbar={<ToolbarSupplierQuotePage quoteTracking={quoteTracking} />}
                defaultSidebarWidth="400px"
                contentBackground={colorSystem.neutral[1]}
                content={<Content quoteTracking={quoteTracking} />}
                sidebar={<SupplierQuotePageSidebar quoteTracking={quoteTracking} />}
                fallback={ErrorFallback}
            />
        </FormContainer>
    );
}

function SupplierQuotePageSidebar({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    return (
        <Flexbox flexDirection={'column'} padding="20px" gap="32px">
            <Flexbox gap="16px" flexDirection={'column'}>
                {getSupplierPortalMode(quoteTracking) === 'edit' && (
                    <>
                        <FormItemDueDate quoteTracking={quoteTracking} />
                        <FormItemParts quoteTracking={quoteTracking} />
                        <FormItemOfferNumber />
                        <FormItemValidUntil />
                        <FormItemCurrency />
                    </>
                )}

                {getSupplierPortalMode(quoteTracking) === 'view' && (
                    <>
                        <FormItem label={t`Status`}>
                            <QuoteTrackingStateChip user="supplier" quoteTracking={quoteTracking} />
                        </FormItem>
                        <FormItemParts quoteTracking={quoteTracking} />

                        <FormItemOfferNumbers quoteTracking={quoteTracking} />

                        <FormItem label={t`Submitted on`}>
                            {isPresent(quoteTracking.received_date) ? (
                                <Tooltip title={formatRelativeTime(quoteTracking.received_date)}>
                                    <Text>{formatLongDateTime(quoteTracking.received_date)}</Text>
                                </Tooltip>
                            ) : (
                                <Text>{t`Not submitted yet`}</Text>
                            )}
                        </FormItem>
                    </>
                )}
            </Flexbox>

            <FormItemAdditionalFiles quoteTracking={quoteTracking} />

            <FormItem label={t`Comments`}>
                <CommentsBox
                    rfqId={quoteTracking.rfq}
                    thread={{
                        category: 'Public',
                        commentType: 'QuoteTracking',
                        typeIds: quoteTracking.id,
                        quoteTrackingId: quoteTracking.id,
                        rfqId: quoteTracking.rfq,
                    }}
                />
            </FormItem>

            <div style={{ height: 200 }}></div>
        </Flexbox>
    );
}

function Content({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const requestCustomParts = uniqBy(quoteTracking.request_custom_parts, (part) => part.kind.id).sort(
        compareByStringKey((part) => part.kind.id),
    );

    return (
        <Flexbox
            sx={{
                background: colorSystem.neutral.white,
                padding: '20px',
                maxWidth: 1024,
                margin: '0 auto',
                marginTop: '20px',
                borderRadius: 2,
                flexDirection: 'column',
                gap: 3,
            }}
        >
            {quoteTracking.custom_part_offers.length > 0 && (
                <Message
                    attention="high"
                    size="large"
                    variant="green"
                    title={t`The quote has been successfully submitted.`}
                    message={t`You can see below an overview of your quote prices.`}
                />
            )}
            <Text variant="h1">
                <Trans>Requested parts</Trans>
            </Text>

            {requestCustomParts.map((part, index) => (
                <>
                    <RequestedCustomPart
                        key={part.kind.id}
                        mode={getSupplierPortalMode(quoteTracking)}
                        part={part.kind}
                        fileManagementPreference={quoteTracking.file_management_preference}
                    />
                    {index !== requestCustomParts.length - 1 && <Divider />}
                </>
            ))}
        </Flexbox>
    );
}

function RequestedCustomPart({
    mode,
    part,
    fileManagementPreference,
}: {
    mode: 'view' | 'edit';
    part: CustomFullPart | CustomComponentFull;
    fileManagementPreference: FileManagementPreferenceDTO;
}) {
    const { control } = useFormContext<CustomPartQuoteFormValues>();
    const offer = useWatch({ control, name: `offers` });
    const offerIndex = offer.findIndex((o) => o.linked_part.id === part.id);

    const {
        append: onAddPriceBreak,
        fields: priceBreaks,
        remove: onRemovePriceBreak,
    } = useFieldArray({
        control,
        name: `offers.${offerIndex}.price_breaks`,
    });

    const {
        append: onAddOneTimeCost,
        fields: oneTimeCosts,
        remove: onRemoveOneTimeCost,
    } = useFieldArray({
        control,
        name: `offers.${offerIndex}.one_time_costs`,
    });

    if (offerIndex === -1) {
        throwErrorUnlessProduction(new Error('Supplier portal: offer not found'), { extra: { partId: part.id } });
        return null;
    }

    return (
        <Flexbox sx={{ flexDirection: 'column', gap: 2 }}>
            <CustomPartPreview part={part} fileManagementPreference={fileManagementPreference} />

            <Text variant="h4">{t`Price points`}</Text>
            <PriceBreaksTable
                mode={mode}
                offerIndex={offerIndex}
                priceBreaks={priceBreaks}
                onRemove={onRemovePriceBreak}
            />

            {oneTimeCosts.length > 0 && (
                <>
                    <Text variant="h4">{t`One-time costs`}</Text>
                    <OneTimeCostsTable
                        mode={mode}
                        offerIndex={offerIndex}
                        oneTimeCosts={oneTimeCosts}
                        onRemove={onRemoveOneTimeCost}
                    />
                </>
            )}

            <Flexbox sx={{ gap: 1 }}>
                <SecondaryButton
                    sx={{
                        visibility: mode === 'edit' ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                        onAddPriceBreak({
                            price_per_measurement: {
                                amount: '',
                                currency: Currency.EUR,
                            },
                            availability: { type: AvailabilityType.LeadTime, days: 1 },
                            minimum_order_quantity: 1,
                            minimum_packaging_quantity: 1,
                            description: null,
                        })
                    }
                    startIcon={<Add />}
                    size="small"
                >
                    <Trans>Add price point</Trans>
                </SecondaryButton>
                <SecondaryButton
                    onClick={() =>
                        onAddOneTimeCost({
                            description: '',
                            price: {
                                amount: '',
                                currency: Currency.EUR,
                            },
                        })
                    }
                    sx={{
                        visibility: mode === 'edit' ? 'visible' : 'hidden',
                    }}
                    startIcon={<Add />}
                    size="small"
                >
                    <Trans>Add one-time cost</Trans>
                </SecondaryButton>
            </Flexbox>
        </Flexbox>
    );
}

function CustomPartPreview({
    part,
    fileManagementPreference,
}: {
    part: CustomFullPart | CustomComponentFull;
    fileManagementPreference: FileManagementPreferenceDTO;
}) {
    if (isCustomFullPart(part)) {
        if (part.type.name === CustomPartTypeEnum.PCB && part.type.content) {
            return (
                <PcbPartPreview
                    partDescription={part.description ?? undefined}
                    pcbId={part.type.content}
                    fileManagementPreference={fileManagementPreference}
                />
            );
        }

        return (
            <Flexbox flexDirection={'column'} gap="8px">
                <Text>{transEnum(part.type.name, customPartTypeTranslations)}</Text>
                <Text>{part.description}</Text>
            </Flexbox>
        );
    }

    if (isCustomComponentFull(part)) {
        return (
            <Flexbox flexDirection={'column'} gap="8px">
                <Text>{part.description}</Text>
            </Flexbox>
        );
    }

    assertUnreachable(part);
}

const usePcbValues = (pcbId: string) => {
    const { data: pcb } = usePcb(pcbId);

    if (pcb && isPcbSetupWithoutFiles(pcb)) {
        const { width, height } = extractPcbWidthAndHeight(pcb);
        const specification = pcb.specifications[0]?.settings ?? {
            board: {},
            layerStack: {},
            mechanical: {},
        };
        return {
            pcb,
            url: new URL('', window.location.origin),
            width,
            height,
            numLayers: specification.layerStack.layercount,
            layerStackType: specification.layerStack.layerstackType,
        };
    }

    const path = pcb?.specifications[0]?.previews.front?.path ?? '';
    const key = pcb?.specifications[0]?.previews.front?.key ?? '';
    const url = new URL(path, window.location.origin);
    url.searchParams.set('k', key);

    const specification = pcb?.specifications[0]?.settings;

    return {
        pcb,
        url,
        width: specification?.board?.boardWidth?.value ?? pcb?.properties.board.boardWidth?.value,
        height: specification?.board?.boardHeight?.value ?? pcb?.properties.board.boardHeight?.value,
        numLayers: specification?.layerStack?.layercount ?? pcb?.properties.layerStack.layercount,
        layerStackType: specification?.layerStack?.layerstackType ?? pcb?.properties.layerStack.layerstackType,
    };
};

function PcbPartPreview({
    pcbId,
    fileManagementPreference,
    partDescription,
}: {
    pcbId: string;
    fileManagementPreference: FileManagementPreferenceDTO;
    partDescription?: string;
}) {
    const { url, width, height, numLayers, layerStackType, pcb } = usePcbValues(pcbId);

    return (
        <Flexbox flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
            {pcb && <Toolbar style={{ paddingLeft: 0 }} breadcrumbs={[{ title: pcb.name ?? '' }, { title: t`PCB` }]} />}
            <Flexbox gap="16px">
                <Thumbnail
                    src={url.toString()}
                    width={'64px'}
                    height={'64px'}
                    borderRadius={'8px'}
                    ImageProps={{
                        style: {
                            borderRadius: '4px',
                        },
                    }}
                    style={{
                        padding: 4,
                        borderRadius: 8,
                        boxSizing: 'border-box',
                        border: `1px solid ${colorSystem.neutral[3]}`,
                        background: colorSystem.neutral[1],
                    }}
                />
                <Flexbox flexDirection="column" gap="16px" paddingTop="2px">
                    {partDescription && <Text>{partDescription}</Text>}
                    <Flexbox gap={'8px'} alignItems="center">
                        {layerStackType && (
                            <PcbPropertyChip
                                label={t`Layer stack`}
                                value={transEnum(layerStackType, layerStackTypeTranslations)}
                            />
                        )}
                        <PcbPropertyChip label={t`Width`} value={formatDecimal(width, { suffix: ' mm' })} />
                        <PcbPropertyChip label={t`Height`} value={formatDecimal(height, { suffix: ' mm' })} />
                        <PcbPropertyChip label={t`Layers`} value={formatDecimal(numLayers)} />
                    </Flexbox>
                    {pcb && pcb.assembly && (
                        <DownloadPcbDocuments pcb={pcb} fileManagementPreference={fileManagementPreference} />
                    )}
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
}

function PcbPropertyChip({ label, value }: { label: string; value?: string }) {
    return <Tag color="neutral" attention="low" label={`${label}: ${value}`} />;
}

export const isExistingPanel = (panelData: PanelDTO): panelData is ExistingPanelDTO => {
    return panelData.type === 'Existing';
};

const PanelSpecificationLink = ({ pcb, panel, index }: { pcb: PCBV2; panel: PanelDTO; index: number }) => {
    const { mutateAsync: downloadPanelSpecification } = useExportPanelSpecificationInPDF({
        pcbId: pcb.id,
        panelId: isExistingPanel(panel) ? panel.data.id : panel.data.panel_details.id,
        fileName: `panel-${index}`,
    });

    return (
        <Link
            startIcon={<DescriptionOutlined style={{ fontSize: '16px' }} />}
            onClick={() => downloadPanelSpecification()}
        >
            <Trans>Panel_specification_{index}.pdf</Trans>
        </Link>
    );
};

function DownloadPcbDocuments({
    pcb,
    fileManagementPreference,
}: {
    pcb: PCBV2;
    fileManagementPreference: FileManagementPreferenceDTO;
}) {
    const { data: panels } = useHttpQuery('GET /panels', {
        queryParams: {
            pcb_id: pcb.id,
        },
    });
    const { downloadPCBFiles } = useDownloadPCBFiles({
        pcbId: pcb.id,
    });

    const { mutateAsync: downloadPcbSpecification } = useExportPcbSpecificationInPDF(pcb);

    return (
        <Flexbox alignItems={'center'} gap={'8px'}>
            <Link startIcon={<FolderZipOutlined style={{ fontSize: '16px' }} />} onClick={() => downloadPCBFiles()}>
                <Trans>PCB files</Trans>
            </Link>
            {fileManagementPreference.should_include_pcb_specification && (
                <Link
                    startIcon={<DescriptionOutlined style={{ fontSize: '16px' }} />}
                    onClick={() => downloadPcbSpecification()}
                >
                    <Trans>PCB_specification.pdf</Trans>
                </Link>
            )}
            {fileManagementPreference.should_include_shipping_panel_specification && panels && (
                <>
                    {panels.map((panel, index) => (
                        <PanelSpecificationLink
                            key={isExistingPanel(panel) ? panel.data.id : panel.data.panel_details.id}
                            pcb={pcb}
                            panel={panel}
                            index={index + 1}
                        />
                    ))}
                </>
            )}
        </Flexbox>
    );
}
