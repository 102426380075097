import { getToken } from '@luminovo/auth';
import { http, OtsFullPart, PartLite, PartLiteTypes } from '@luminovo/http-client';
import { convertOtsFullPartToPartLite } from '../components/PdfOfferImporter/model';

interface PartQuery {
    mpn?: string;
    manufacturer?: string;
}

export interface PartLoader {
    /**
     * Adds the query to the batch, but doesn't execute it until the `load` method is called.
     */
    batchQuery(query: PartQuery): PartLoader;

    /**
     * Executes the batch query.
     */
    fetch(query: PartQuery): Promise<PartLite>;
}

export class MockPartLoader implements PartLoader {
    batchQuery(query: PartQuery): PartLoader {
        return this;
    }

    async fetch(query: PartQuery): Promise<PartLite> {
        const mpn = query.mpn ?? '';
        const manufacturer = query.manufacturer ?? '';
        return {
            kind: PartLiteTypes.OffTheShelf,
            id: [manufacturer, mpn].join('|'),
            manufacturer: { id: manufacturer, name: manufacturer },
            mpn,
        };
    }
}

type PartSearchResponse = { [mpn: string]: OtsFullPart[] };
export class HttpPartLoader implements PartLoader {
    private batchPromise: Promise<PartSearchResponse> | undefined;
    constructor(private queries: PartQuery[] = []) {}

    batchQuery(query: PartQuery): PartLoader {
        this.queries.push(query);
        return this;
    }

    private async fetchBatchMaybe(): Promise<PartSearchResponse> {
        if (this.batchPromise) {
            return this.batchPromise;
        }

        const promise: Promise<PartSearchResponse> = http(
            'POST /parts/off-the-shelf/search/mpn/bulk',
            {
                requestBody: {
                    search_external_apis: true,
                    use_elastic: false,
                    items: this.queries.map((q) => q.mpn ?? ''),
                    rfq_context: 'OutsideRfQ',
                },
            },
            getToken(),
        ).then((res): PartSearchResponse => res.data);

        this.batchPromise = promise;
        return promise;
    }

    async fetch(query: PartQuery): Promise<PartLite> {
        const promise = await this.fetchBatchMaybe();

        const mpn = query.mpn ?? '';
        const manufacturer = query.manufacturer ?? '';

        const unknownPart: PartLite = {
            kind: PartLiteTypes.Unknown,
            id: [manufacturer, mpn].join('|'),
            manufacturer: { name: manufacturer },
            mpn,
        };

        const possibleMatches = promise[mpn] ?? [];
        if (possibleMatches.length === 0) {
            return unknownPart;
        }

        const findManufacturerMatch = possibleMatches.find((p) => {
            const names = [p.manufacturer.name, ...p.manufacturer.alternative_names].map((name) =>
                sanitizeManufacturerName(name),
            );

            return names.includes(sanitizeManufacturerName(manufacturer));
        });

        if (!findManufacturerMatch) {
            return unknownPart;
        }
        return convertOtsFullPartToPartLite(findManufacturerMatch);
    }
}

function sanitizeManufacturerName(name: string): string {
    return name.trim().toLowerCase();
}
