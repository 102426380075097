import { useDebugErrorHandler } from '@/resources/http/debugErrorHandler';
import { t } from '@lingui/macro';
import { FieldNumericControlled, Flexbox, FormItem, FormSection, useNavigate } from '@luminovo/design-system';
import { ResourcePostDTO, http } from '@luminovo/http-client';
import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SitesSelect } from '../../../components/SitesSelect';
import { EnabledLabel } from '../../../components/formLayouts/FormComponents';
import {
    RadioGroupController,
    SelectController,
    TextFieldController,
} from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { usePageParams, useToken } from '../../../resources/hooks';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { resourceTypeEnumPublicTranslations } from '../../../resources/resource/resourceBackendTypes';
import { deprecatedYupResolver } from '../../../utils/deprecated_yupResolver';
import { route } from '../../../utils/routes';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { RecursivePartial } from '../../../utils/typingUtils';
import { ManufacturingDatabaseBackAndSave } from '../shared/manufacturingComponents';
import { ResourceFormValues } from './utils/resourceFormValues';

const createParsedData = (values: ResourceFormValues): ResourcePostDTO => {
    const body: ResourcePostDTO = {
        name: values.name,
        internal_number: values.internalNumber ?? null,
        type: values.type,
        cost_per_hour: {
            amount: String(values.costPerHour),
            currency: values.costPerHourCurrency,
        },
        description: values.description ?? null,
        site_id: values.site === undefined ? null : values.site,
    };

    return body;
};

const useResourceFormFunction = (defaultValues: RecursivePartial<ResourceFormValues>, schema: Yup.ObjectSchema) => {
    const { token } = useToken();

    const navigate = useNavigate();
    const { resourceId } = usePageParams<'/manufacturing/resource/:resourceId/edit'>();
    const { enqueueSnackbar } = useSnackbar();
    const formResult = useForm<ResourceFormValues>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: deprecatedYupResolver(schema),
    });

    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();

    const { mutateAsync } = useMutation({
        mutationFn: (info: { token: string; values: ResourcePostDTO }) => {
            return http('POST /resources', { requestBody: info.values }, token);
        },
        onSuccess: () => {
            enqueueSnackbar(t`Resource created`, { variant: 'success' });
            navigate(route('/manufacturing/resource'));
        },
        onError,
    });

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; values: ResourcePostDTO }) => {
            return http(
                'PATCH /resources/:resourceId',
                { requestBody: info.values, pathParams: { resourceId } },
                token,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /resources') });
            enqueueSnackbar(t`Resource updated`, { variant: 'info' });
            navigate(route('/manufacturing/resource'));
        },
        onError,
    });

    const onSubmitPost: SubmitHandler<ResourceFormValues> = async (data) => {
        const parsedData = createParsedData(data);
        return mutateAsync({
            token,
            values: parsedData,
        });
    };

    const onSubmitPatch: SubmitHandler<ResourceFormValues> = async (data) => {
        const parsedData = createParsedData(data);
        return mutateAsyncPatch({
            token,
            values: parsedData,
        });
    };

    return { onSubmitPatch, onSubmitPost, formResult };
};

export const ResourceForm = ({
    onSubmitType,
    defaultValues,
    schema,
    formTitle,
}: {
    onSubmitType: 'PATCH' | 'POST';
    defaultValues: RecursivePartial<ResourceFormValues>;
    schema: Yup.ObjectSchema;
    formTitle: string;
}): JSX.Element => {
    const { onSubmitPatch, onSubmitPost, formResult } = useResourceFormFunction(defaultValues, schema);
    const {
        handleSubmit,
        formState: { isSubmitting, isValid },
        control,
    } = formResult;
    const [isDescriptionEnabled, setIsDescriptionEnabled] = React.useState<boolean>(false);
    const toggleDescriptionEnabled = React.useCallback(() => {
        setIsDescriptionEnabled(!isDescriptionEnabled);
    }, [isDescriptionEnabled]);

    return (
        <form onSubmit={handleSubmit(onSubmitType === 'PATCH' ? onSubmitPatch : onSubmitPost)}>
            <FormSection title={formTitle} style={{ width: '50%' }}>
                <FormItem label={t`Name`} required>
                    <TextFieldController
                        name={'name'}
                        control={control}
                        TextFieldProps={{
                            placeholder: t`e.g. Pick and Place Machine`,
                        }}
                    />
                </FormItem>
                <FormItem label={t`Internal resource number`}>
                    <TextFieldController name={'internalNumber'} control={control} />
                </FormItem>
                <FormItem label={t`Site`}>
                    <SitesSelect name={'site'} control={control} />
                </FormItem>
                <FormItem label={t`Type`} required>
                    <RadioGroupController
                        control={control}
                        name={'type'}
                        options={resourceTypeEnumPublicTranslations}
                    />
                </FormItem>
                <FormItem label={capitalizeFirstLetter(t`cost/hour`)} required>
                    <Grid item xs={4}>
                        <Flexbox gap={4}>
                            <SelectController
                                name={'costPerHourCurrency'}
                                control={control}
                                translations={inputCurrenciesPublicTranslations}
                                SelectProps={{ style: { width: 120, alignSelf: 'flex-start' } }}
                            />
                            <FieldNumericControlled
                                name={'costPerHour'}
                                control={control}
                                required
                                FieldProps={{ placeholder: t`e.g. 70` }}
                            />
                        </Flexbox>
                    </Grid>
                </FormItem>
                <FormItem
                    label={
                        <EnabledLabel
                            isEnabled={isDescriptionEnabled}
                            handleChange={toggleDescriptionEnabled}
                            text={t`Description`}
                        />
                    }
                >
                    {isDescriptionEnabled && (
                        <Grid item xs={4}>
                            <TextFieldController
                                name={'description'}
                                TextFieldProps={{ multiline: true, rows: 10 }}
                                control={control}
                            />
                        </Grid>
                    )}
                </FormItem>
                <Grid item>
                    <ManufacturingDatabaseBackAndSave isSubmitButtonDisabled={!isValid} isSubmitting={isSubmitting} />
                </Grid>
            </FormSection>
        </form>
    );
};
