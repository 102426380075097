import { useDebugErrorHandler } from '@/resources/http/debugErrorHandler';
import { t } from '@lingui/macro';
import { useToken } from '@luminovo/auth';
import { DestructiveTertiaryIconButton } from '@luminovo/design-system';
import { http } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import { invalidateQuery } from '../../../resources/http/useHttpQuery';

export const DeleteActivityConfiguration = ({
    activityConfigurationId,
    manufacturingScenarioId,
}: {
    activityConfigurationId: string;
    manufacturingScenarioId: string;
}): JSX.Element => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();

    const { mutateAsync: deleteOnClick } = useMutation({
        mutationFn: () =>
            http('DELETE /activity-configurations/bulk', { queryParams: { ids: activityConfigurationId } }, token),
        onSuccess: async () => {
            await invalidateQuery(
                'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary',
                {
                    pathParams: { manufacturingScenarioId },
                },
                queryClient,
            );
        },
        onError,
    });

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);

    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsConfirmationDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        await deleteOnClick();
    };

    return (
        <>
            <DestructiveTertiaryIconButton onClick={handleDelete} size="medium">
                <Delete />
            </DestructiveTertiaryIconButton>
            <ConfirmationDialogBox
                text={t`Are you sure you want to remove this activity from this manufacturing scenario?`}
                title={t`Remove activity`}
                onConfirm={() => handleConfirmDelete()}
                onReject={() => setIsConfirmationDialogOpen(false)}
                isDialogOpen={isConfirmationDialogOpen}
                deleteText={t`Yes, remove`}
            />
        </>
    );
};
