import { colorSystem } from '@luminovo/design-system';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

export function LayoutTwoPanels({ left, right }: { left?: React.ReactNode; right: React.ReactNode }): JSX.Element {
    if (!left) {
        return <>{right}</>;
    }

    return (
        <>
            <style>
                {`
                    .panel-resize-handle:hover {
                        background-color: ${colorSystem.blue[7]};
                        width: 3px;
                        }
                    .panel-resize-handle {
                        background-color: ${colorSystem.neutral.white};
                        transition: background-color 0.2s ease-in-out;
                        width: 3px;
                    }
                `}
            </style>
            <PanelGroup direction="horizontal">
                <Panel defaultSize={50}>{left}</Panel>
                <PanelResizeHandle
                    hitAreaMargins={{ coarse: 0, fine: 0 }}
                    className="panel-resize-handle"
                ></PanelResizeHandle>
                <Panel defaultSize={50}>{right}</Panel>
            </PanelGroup>
        </>
    );
}
