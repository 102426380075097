/* eslint-disable */
import { t, Trans } from '@lingui/macro';
import { Currency, formatDays, formatDecimal } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Dropzone,
    FieldDate,
    FieldSelect,
    FieldText,
    FormItem,
    Step,
    Stepper,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { ValidFor } from '@luminovo/http-client';
import { formatValidFor } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { Prompt } from 'react-router-dom';
import { FormFieldsConfiguration, PdfOfferLineItem, ValidatedFormState } from '../../types';
import { DataGridPdfOfferLine } from '../DataGridPdfOfferLine';
import { LayoutTwoPanels } from '../LayoutTwoPanels';
import { PdfViewer, PdfViewerContextProvider, usePdfViewerState } from '../PdfViewer';
import { Region } from '../PdfViewer/model/RegionNetwork/types';
import { PdfOfferImporterState, usePdfOfferImporterState } from './context';
import { useMutationAnalyzeFile } from './hooks/useMutationAnalyzeExcel';
import { createFormState } from './state';
import { QuoteRequest } from './types';

export type PdfOfferImporterProps<TQuoteRequest extends QuoteRequest> = {
    defaultQuoteRequest?: TQuoteRequest;
    quoteRequests: TQuoteRequest[];
    onImport: (props: ValidatedFormState) => Promise<void>;
    returnTo: string;
    onSuccess: (_: { quoteRequest: TQuoteRequest; file: File }) => void;
    onError: () => void;
    initialFieldConfiguration: FormFieldsConfiguration;
};

export function PdfOfferImporter<TQuoteRequest extends QuoteRequest>(
    props: PdfOfferImporterProps<TQuoteRequest>,
): JSX.Element {
    return (
        <PdfViewerContextProvider>
            <PdfOfferImporterState
                initialFormState={createFormState({
                    quoteRequest: props.defaultQuoteRequest,
                    quoteRequests: props.quoteRequests,
                    initialFieldConfiguration: props.initialFieldConfiguration,
                })}
            >
                <PdfOfferImporterInner {...props} />
            </PdfOfferImporterState>
        </PdfViewerContextProvider>
    );
}

function PdfOfferImporterInner<TQuoteRequest extends QuoteRequest>(props: PdfOfferImporterProps<TQuoteRequest>) {
    const { state: formState } = usePdfOfferImporterState();
    const form = useAtomValue(formState.$formState);
    const file = useAtomValue(formState.fields.$file);
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest);
    const defaultCurrency = useAtomValue(formState.fields.$defaultCurrency);
    const validFrom = useAtomValue(formState.fields.$validFrom);
    const validRange = useAtomValue(formState.fields.$validDays);
    const navigate = useNavigate();

    const isUploadEnabled = true;
    const isQuoteEnabled = isUploadEnabled && file.status === 'success';
    const isQuoteDone =
        isQuoteEnabled &&
        quoteRequest.status === 'success' &&
        defaultCurrency.status === 'success' &&
        validFrom.status === 'success' &&
        validRange.status === 'success';
    const isOffersEnabled = isQuoteEnabled && isQuoteDone;

    const steps: Step[] = [
        {
            content: StepUploadFile,
            enabled: isUploadEnabled,
            label: t`Upload`,
            isDone: file.status === 'success',
        },
        {
            content: StepPickQuote,
            enabled: isQuoteEnabled,
            label: t`Quote`,
            isDone: true,
        },
        {
            content: StepImportOffers,
            enabled: isOffersEnabled,
            label: t`Offers`,
            isDone: form.status === 'success',
        },
    ];

    const { enqueueSnackbar } = useSnackbar();

    const [stepIndex, setStepIndex] = useAtom(formState.$stepIndex);

    const { mutateAsync: importOffers, isPending: isImporting } = useMutation({
        mutationFn: async () => {
            if (form.status !== 'success') {
                throw new Error('Form is not valid');
            }
            const validatedForm = form.value;
            await props.onImport(validatedForm);
            enqueueSnackbar(t`${formatDecimal(validatedForm.rows.length)} offers imported successfully`, {
                variant: 'success',
            });
            navigate(props.returnTo);
            props.onSuccess({ quoteRequest: validatedForm.quoteRequest as TQuoteRequest, file: validatedForm.file });
        },
        onError: () => {
            props.onError();
            enqueueSnackbar(t`Error importing offers`, { variant: 'error' });
        },
    });

    return (
        <>
            <Stepper
                hrefBack={props.returnTo}
                onSubmit={async () => {
                    return importOffers();
                }}
                title={t`PDF offer importer`}
                steps={steps}
                onStepIndexChange={(newStepIndex) => setStepIndex(newStepIndex)}
                stepIndex={stepIndex}
            />
            <Prompt when={!isImporting} message={t`Are you sure you want to exit? Changes will be lost`} />
        </>
    );
}

function StepUploadFile({ onStepIndexChange }: { onStepIndexChange: (step: 'next' | 'prev' | number) => void }) {
    const { state: formState } = usePdfOfferImporterState();
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;
    const setFile = useSetAtom(formState.fields.$file);

    const { mutateAsync, isPending } = useMutationAnalyzeFile({ state: formState });

    return (
        <CenteredLayout>
            <Dropzone
                isLoading={isPending}
                overrides={{}}
                onDropAccepted={async ([file]) => {
                    setFile(file);
                    await mutateAsync({ file, quoteRequest });
                    onStepIndexChange('next');
                }}
                accept={{
                    'application/pdf': ['.pdf'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                multiple={false}
                title=""
                persistentFiles={[]}
            />
            <Text variant="body-small" style={{ maxWidth: 400, color: colorSystem.neutral[6], textAlign: 'center' }}>
                <Trans>
                    AI-powered extraction may contain inaccuracies. Please carefully review all extracted values before
                    importing.
                </Trans>
            </Text>
        </CenteredLayout>
    );
}

function StepPickQuote() {
    const [state] = usePdfViewerState();
    const pdfDocumentProxy = state.pdfDocumentProxy;

    const rightPanel = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
            <FormItemQuoteRequestNumber />
            <FormItem
                required
                label={t`Default currency`}
                description={t`Unless specified otherwise, the offers will be imported in this currency`}
                variant="description-inlined"
            >
                <FieldSelectCurrency />
            </FormItem>
            <FormItemOfferNumber />
            <FormItemValidFrom />
            <FormItemValidUntil />
            <FormItemValidFor />
        </Box>
    );

    if (!pdfDocumentProxy) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 2,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                {rightPanel}
            </Box>
        );
    }

    return (
        <LayoutTwoPanels
            left={
                pdfDocumentProxy ? (
                    <Box
                        sx={{ background: colorSystem.neutral[1], minWidth: '1024', height: '100%', overflow: 'auto' }}
                    >
                        <PdfViewer
                            pdf={pdfDocumentProxy}
                            pdfViewerOptions={{
                                actionStyles: {
                                    marginTop: 48,
                                },
                            }}
                        />
                    </Box>
                ) : undefined
            }
            right={rightPanel}
        />
    );
}

function FormItemValidFor() {
    const { state: formState } = usePdfOfferImporterState();
    const [validFor, setValidFor] = useAtom(formState.fields.$validFor);
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;

    const formFieldsConfiguration = useAtomValue(formState.$formFieldsConfiguration);

    const possibleValues = [
        { option: ValidFor.EveryCustomer, isVisible: true },
        { option: ValidFor.ThisCustomer, isVisible: quoteRequest?.customerId !== undefined },
        { option: ValidFor.ThisRfQ, isVisible: quoteRequest?.rfqId !== undefined },
    ];

    const options = possibleValues.filter(({ isVisible }) => isVisible).map(({ option }) => option);

    if (!formFieldsConfiguration.validFor.visible) {
        return <></>;
    }

    return (
        <FormItem label={t`Valid for`} variant="description-inlined" description={t`For whom are the offers valid?`}>
            <FieldSelect
                options={options}
                value={validFor.value ?? null}
                disableClearable
                getOptionLabel={(option) => formatValidFor(option)}
                onChange={(value) => setValidFor(value ?? ValidFor.EveryCustomer)}
            />
        </FormItem>
    );
}

function FormItemValidFrom() {
    const { state: formState } = usePdfOfferImporterState();
    const [validFrom, setValidFrom] = useAtom(formState.fields.$validFrom);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'offerDate' });

    return (
        <FormItem
            required
            label={t`Valid from`}
            variant="description-inlined"
            description={t`The date from which the offer is valid`}
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'offerDate',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
        >
            <FieldDate
                value={validFrom.value ?? null}
                error={validFrom.status === 'error'}
                helperText={validFrom.message}
                onChange={(value) => setValidFrom(value ?? undefined)}
            />
        </FormItem>
    );
}

function FormItemValidUntil() {
    const { state: formState } = usePdfOfferImporterState();
    const [validUntil, setValidUntil] = useAtom(formState.fields.$validUntil);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'dueDate' });
    return (
        <FormItem
            label={t`Valid until`}
            variant="description-inlined"
            description={t`The date until which the offer is valid`}
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'dueDate',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
        >
            <FieldDate
                value={validUntil.value ?? null}
                error={validUntil.status === 'error'}
                helperText={validUntil.message}
                onChange={(value) => setValidUntil(value ?? undefined)}
            />
            <LabelValidDays />
        </FormItem>
    );
}

function LabelValidDays() {
    const { state: formState } = usePdfOfferImporterState();
    const validDays = useAtomValue(formState.fields.$validDays);
    if (validDays.status === 'success' && validDays.value !== undefined) {
        return (
            <Text variant="body-small">
                <Trans comment="Valid for {X days}">Valid for {formatDays(validDays.value)}</Trans>
            </Text>
        );
    }
    if (validDays.status === 'success' && validDays.value === undefined) {
        return <></>;
    }
    if (validDays.status === 'error') {
        return (
            <Text variant="body-small" color={colorSystem.red[7]}>
                {validDays.message}
            </Text>
        );
    }
    return <></>;
}

function FormItemQuoteRequestNumber() {
    const { state: formState } = usePdfOfferImporterState();
    const [quoteRequest, setQuoteRequest] = useAtom(formState.fields.$quoteRequest);
    const quoteRequests = useAtomValue(formState.$quoteRequests);
    const file = useAtomValue(formState.fields.$file).value;
    const rows = useAtomValue(formState.fields.$rows);

    const { mutateAsync: reAnalyzePdf, isPending: isReAnalyzing } = useMutationAnalyzeFile({ state: formState });

    return (
        <FormItem
            label={t`Quote request`}
            required
            description={t`Select the quote request that the offers are linked to`}
            variant="description-inlined"
        >
            <FieldSelect
                disabled={isReAnalyzing}
                groupBy={(group) => group?.status ?? ''}
                autoFocus={true}
                options={quoteRequests}
                getOptionLabel={(option) => {
                    if (!option) {
                        return '';
                    }
                    return `#${option.label}`;
                }}
                error={quoteRequest.status === 'error'}
                helperText={quoteRequest.message}
                value={quoteRequest.value}
                onChange={(newQuoteRequest) => {
                    setQuoteRequest(newQuoteRequest || undefined);
                    if (!newQuoteRequest || !file) {
                        return;
                    }
                    reAnalyzePdf({ quoteRequest: newQuoteRequest, file });
                    return;
                }}
            />
            {rows.length > 0 && getImportMessage(rows, quoteRequest.value)}
        </FormItem>
    );
}

function getImportMessage(rows: PdfOfferLineItem[], quoteRequest?: QuoteRequest) {
    // This case is only relevant for the legacy QuoteTracking importer.
    // Once we get rid of quote tracking, we can get rid of this.
    if (quoteRequest?.disableFetchingQuoteRequestLineItems) {
        return (
            <Text color={colorSystem.green[7]} variant="body-small">
                <Trans>Found {formatDecimal(rows.length)} offers in the file.</Trans>
            </Text>
        );
    }

    const countUnmatchedRows = rows.filter((row) => !row.quoteRequestLineItem).length;
    const formattedRows = formatDecimal(rows.length);
    if (countUnmatchedRows === 0) {
        return (
            <Text color={colorSystem.green[7]} variant="body-small">
                <Trans>Found {formattedRows} offers in the file.</Trans>
            </Text>
        );
    }

    if (countUnmatchedRows === rows.length) {
        return (
            <Text color={colorSystem.yellow[7]} variant="body-small">
                <Trans>Found {formattedRows} offers in the file, but none could be linked to request items.</Trans>
            </Text>
        );
    }

    return (
        <Text color={colorSystem.red[7]} variant="body-small">
            <Trans>Found {formattedRows} offers in the file, but some could not be linked to request items.</Trans>
        </Text>
    );
}

function StepImportOffers() {
    const { state: formState } = usePdfOfferImporterState();
    const [state, pdfViewerDispatch] = usePdfViewerState();
    const pdfDocumentProxy = state.pdfDocumentProxy;
    const rows = useAtomValue(formState.fields.$rows) ?? [];
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;
    const showPdfPanel = useAtomValue(formState.$showPdfPanel);

    const rightPanel = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                padding: 0,
                minWidth: 600,
                height: '100%',
                width: '100%',
                background: colorSystem.neutral[1],
            }}
        >
            <DataGridPdfOfferLine
                formState={formState}
                rows={rows}
                requestedParts={quoteRequest?.resolvedParts ?? []}
            />
        </Box>
    );

    if (!showPdfPanel || !pdfDocumentProxy) {
        return rightPanel;
    }

    return (
        <LayoutTwoPanels
            left={
                <Box sx={{ background: colorSystem.neutral[1], minWidth: '1024', height: '100%', overflow: 'auto' }}>
                    <PdfViewer
                        pdf={pdfDocumentProxy}
                        pdfViewerOptions={{
                            actionStyles: {
                                marginTop: 48,
                            },
                        }}
                    />
                </Box>
            }
            right={rightPanel}
        />
    );
}

function FormItemOfferNumber(): JSX.Element {
    const { state: formState } = usePdfOfferImporterState();
    const [defaultOfferNumber, setDefaultOfferNumber] = useAtom(formState.fields.$offerNumber);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'offerNumber' });

    return (
        <FormItem
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'offerNumber',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
            onMouseLeave={() => {
                pdfViewerDispatch({ type: 'setMode', mode: { type: 'default' } });
            }}
            label={t`Offer number`}
            variant="description-inlined"
            description={t`All offers imported will have this offer number attached to them`}
        >
            <FieldText
                value={defaultOfferNumber.value ?? null}
                error={defaultOfferNumber.status === 'error'}
                helperText={defaultOfferNumber.message}
                onChange={(value) => setDefaultOfferNumber(value || '')}
            />
        </FormItem>
    );
}

function FieldSelectCurrency(): JSX.Element {
    const { state: formState } = usePdfOfferImporterState();
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const [defaultCurrency, setDefaultCurrency] = useAtom(formState.fields.$defaultCurrency);
    const regions = state.regs.findRegions({ attribute: 'currency' });

    return (
        <Box
            onMouseEnter={() => {
                pdfViewerDispatch({
                    type: 'setMode',
                    mode: {
                        type: 'inspect',
                        attribute: 'currency',
                        pageNumber: regions[0]?.pageNumber ?? 1,
                        selectedRegionIds: regions.map((r) => r.id),
                    },
                });
            }}
            onMouseLeave={() => {
                pdfViewerDispatch({ type: 'setMode', mode: { type: 'default' } });
            }}
        >
            <FieldSelect
                placeholder={t`Currency`}
                options={Object.values(Currency)}
                error={defaultCurrency.status === 'error'}
                helperText={defaultCurrency.message}
                getOptionLabel={(option) => option ?? 'None'}
                value={defaultCurrency.value}
                onChange={(newCurrency) => {
                    setDefaultCurrency(newCurrency ?? undefined);
                }}
            />
        </Box>
    );
}
