/* eslint-disable */
import { getToken } from '@luminovo/auth';
import { groupBy } from '@luminovo/commons';
import {
    ComplianceStatus,
    http,
    NegotiationsStandardPartOfferBulkInputDTO,
    PriceType,
    QuantityUnit,
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    StandardPartTypes,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FormFieldsConfiguration, OfferLineItem, ValidatedFormState } from '../../types';
import { convertLeadTimeToDays } from '../PdfViewer/model/RegionNetwork/parsers/LeadTime';
import { resolveOtsPartsRecursively } from './model';
import { PdfOfferImporter } from './PdfOfferImporter';
import { QuoteRequest } from './types';

type QuoteRequestWithQuoteTracking = QuoteRequest & { quoteRequest: QuoteRequestDTO };

export function PdfQuoteRequestImporter(props: {
    quoteRequestId: string;
    returnTo: string;
    onSuccess: (_: { quoteRequestId: string; file: File }) => void;
    onError: () => void;
}): JSX.Element {
    const [progress, setProgress] = useState(0);

    const {
        data: { quoteRequests, defaultQuoteRequest },
    } = useSuspenseQuery({
        queryKey: ['quote-requests', props.quoteRequestId],
        queryFn: async (): Promise<QuoteRequestWithQuoteTracking[]> => {
            const suppliersPromise: Promise<SupplierAndStockLocationDTO[]> = http(
                'GET /suppliers-and-stock-locations',
                {},
                getToken(),
            ).then(({ data }: { data: SupplierAndStockLocationDTO[] }) => data);

            const quoteRequestPromise: Promise<QuoteRequestDTO> = http(
                'GET /quote-request/:id',
                {
                    pathParams: { id: props.quoteRequestId },
                },
                getToken(),
            ).then((data: QuoteRequestDTO) => data);

            const quoteRequestLineItemsPromise: Promise<QuoteRequestLineItemDTO[]> = http(
                'GET /quote-request/:id/line-items',
                {
                    pathParams: { id: props.quoteRequestId },
                },
                getToken(),
            ).then((data) => data.items);

            const [supplierAndStockLocations, quoteRequest, quoteRequestLineItems] = await Promise.all([
                suppliersPromise,
                quoteRequestPromise,
                quoteRequestLineItemsPromise,
            ]);

            const requestedParts = quoteRequestLineItems.flatMap((li) => resolveOtsPartsRecursively(li.requested_part));

            const { items: resolvedParts } = await http(
                'POST /parts/off-the-shelf/bulk',
                {
                    requestBody: {
                        ids: requestedParts.map((p) => p.id),
                        rfq_context: 'OutsideRfQ',
                    },
                },
                getToken(),
            );

            const supplierAndStockLocatationsBySupplierId = groupBy(supplierAndStockLocations, (s) => s.supplier.id);

            const sasls = supplierAndStockLocatationsBySupplierId[quoteRequest.supplier.id] ?? [];

            const quoteRequestsWithSupplier = sasls.map((sasl) => ({
                quoteRequest,
                supplierAndStockLocation: sasl,
            }));

            return quoteRequestsWithSupplier.map(
                ({ quoteRequest, supplierAndStockLocation }): QuoteRequestWithQuoteTracking => {
                    return {
                        id: quoteRequest.id,
                        label: `${formatSupplierAndStockLocationDTO(supplierAndStockLocation)} #${quoteRequest.number}`,
                        supplierAndStockLocation: supplierAndStockLocation.id,
                        status: quoteRequest.status,
                        resolvedParts,
                        quoteRequest,
                        disableFetchingQuoteRequestLineItems: false,
                    };
                },
            );
        },
        select: (
            quoteRequests,
        ): {
            quoteRequests: QuoteRequestWithQuoteTracking[];
            defaultQuoteRequest: QuoteRequestWithQuoteTracking | undefined;
        } => {
            const defaultQuoteRequest = quoteRequests.length > 0 ? quoteRequests[0] : undefined;

            return { quoteRequests, defaultQuoteRequest };
        },
    });

    // const everythingEnabled: FormFieldsConfiguration = {
    //     // Requirements
    //     requestedPart: { visible: false },
    //     partDescription: { visible: false },
    //     requiredQuantity: { visible: false },
    //     potentialQuantity: { visible: false },
    //     recipients: { visible: false },
    //     targetPrice: { visible: false },
    //     customerName: { visible: false },
    //     validFor: { visible: true },

    //     // Offer
    //     bid: { visible: true },
    //     part: { visible: true },
    //     unitPrice: { visible: true },
    //     moq: { visible: true },
    //     mpq: { visible: true },
    //     currency: { visible: true },
    //     packaging: { visible: true },
    //     standardFactoryLeadTime: { visible: true },
    //     standardFactoryLeadTimeUnit: { visible: true },
    //     notes: { visible: true },
    //     ncnr: { visible: true },
    //     stock: { visible: true },
    //     supplierPartNumber: { visible: true },
    //     validFrom: { visible: true },
    //     validUntil: { visible: true },
    //     cancellationWindow: { visible: true },
    //     cancellationTimeUnit: { visible: true },
    //     itemClass: { visible: true },
    //     oneTimeCost: { visible: true },

    //     // Part information
    //     reach: { visible: true },
    //     rohs: { visible: true },
    //     eccnNumbers: { visible: true },
    //     htsCode: { visible: true },
    //     countryOfOrigin: { visible: true },
    // };

    const initialFieldConfiguration: FormFieldsConfiguration = {
        // Requirements
        requestedPart: { visible: true },
        partDescription: { visible: false },
        requiredQuantity: { visible: false },
        potentialQuantity: { visible: false },
        recipients: { visible: false },
        targetPrice: { visible: false },
        customerName: { visible: false },
        validFor: { visible: true },

        // Offer
        bid: { visible: false },
        part: { visible: true },
        unitPrice: { visible: true },
        moq: { visible: true },
        mpq: { visible: true },
        currency: { visible: true },
        packaging: { visible: true },
        standardFactoryLeadTime: { visible: true },
        standardFactoryLeadTimeUnit: { visible: true },
        notes: { visible: true },
        ncnr: { visible: true },
        stock: { visible: true },
        supplierPartNumber: { visible: true },
        validFrom: { visible: false },
        validUntil: { visible: false },
        cancellationWindow: { visible: false },
        cancellationTimeUnit: { visible: false },
        itemClass: { visible: false },
        oneTimeCost: { visible: false },

        // Part information
        reach: { visible: false },
        rohs: { visible: false },
        eccnNumbers: { visible: false },
        htsCode: { visible: false },
        countryOfOrigin: { visible: false },
    };

    return (
        <>
            <PdfOfferImporter
                onSuccess={({ quoteRequest, file }) => props.onSuccess({ quoteRequestId: quoteRequest.id, file })}
                onError={props.onError}
                initialFieldConfiguration={initialFieldConfiguration}
                onImport={async (importProps) => {
                    const { quoteRequest, rows } = importProps;

                    console.log('onImport', rows);

                    await updatePartVotes(rows);

                    await http(
                        'POST /quote-request/:id/add-offers/off-the-shelf',
                        {
                            pathParams: { id: quoteRequest.id.toString() },
                            requestBody: {
                                supplier_and_stock_location: quoteRequest.supplierAndStockLocation,
                                inputs: rows.map((offer) => convertPdfOfferLineItemToOfferInput(offer, importProps)),
                                event_metadata: { type: 'Excel' },
                            },
                        },
                        getToken(),
                    );
                }}
                quoteRequests={quoteRequests}
                defaultQuoteRequest={defaultQuoteRequest}
                returnTo={props.returnTo}
            />
        </>
    );
}

async function updatePartVotes(rows: OfferLineItem[], setProgress: (progress: number) => void = () => {}) {
    const rowsByPartId = groupBy(rows, (row) => row.part.id);

    let progress = 0;
    const total = Object.keys(rowsByPartId).length;

    for (const partId in rowsByPartId) {
        const rows = rowsByPartId[partId];

        const reach = rows[0].reach;
        const rohs = rows[0].rohs;
        const eccnNumbers = rows[0].eccnNumbers;
        const htsCode = rows[0].htsCode;
        const countryOfOrigin = rows[0].countryOfOrigin;

        if (reach === ComplianceStatus.Unknown && rohs === ComplianceStatus.Unknown) {
            continue;
        }

        // We don't care about the result, we just want to know when it's done
        // if it fails, it will fail silently
        await Promise.allSettled([
            http(
                'PATCH /parts/off-the-shelf/:partId',
                {
                    pathParams: { partId },
                    queryParams: {
                        rfq_context: 'OutsideRfQ',
                    },
                    requestBody: {
                        reach_compliant: reach === ComplianceStatus.Unknown ? undefined : reach,
                        rohs_compliant: rohs === ComplianceStatus.Unknown ? undefined : rohs,
                        eccn_numbers: eccnNumbers ? [eccnNumbers] : undefined,
                        hts_code: htsCode,
                        country_of_origin: countryOfOrigin ? [countryOfOrigin] : undefined,
                        origin: { type: 'Manual' as const },
                    },
                },
                getToken(),
            ),
        ]);

        progress++;
        setProgress(progress / total);
    }
}

function convertPdfOfferLineItemToOfferInput(
    offer: OfferLineItem,
    props: ValidatedFormState,
): NegotiationsStandardPartOfferBulkInputDTO {
    const { defaultCurrency } = props;

    const part = offer.part;
    const standardFactoryLeadTime = offer.standardFactoryLeadTime;
    const standardFactoryLeadTimeUnit = offer.standardFactoryLeadTimeUnit;

    const currency = offer.currency;

    const factoryLeadTimeDays = standardFactoryLeadTime
        ? convertLeadTimeToDays({
              value: standardFactoryLeadTime,
              unit: standardFactoryLeadTimeUnit,
          })
        : null;

    const priceBreakInput = {
        moq: offer.moq,
        mpq: offer.mpq,
        unit_price: offer.unitPrice,
        lead_time_days: null,
    };

    console.log(offer.quoteRequestLineItem?.id);

    const offerInput: NegotiationsStandardPartOfferBulkInputDTO = {
        quote_request_line_item_id: offer.quoteRequestLineItem?.id ?? null,
        supplier_part_number: offer.supplierPartNumber ?? '',
        one_time_costs: [],
        valid_until: props.validUntil ?? null,
        cancellation_window_in_days: null,
        item_class: null,
        valid_from: props.validFrom,
        attachment: props.attachment ?? null,

        notes: offer.notes,
        currency: currency ?? defaultCurrency,
        price_type: PriceType.QuotePrice,
        packaging: offer.packaging ?? null,
        availability_input: {
            stock: offer.stock ?? null,
            factory_lead_time_days: factoryLeadTimeDays ?? null,
            factory_quantity: null,
            on_order: [],
        },
        supplier_and_stock_location: props.quoteRequest.supplierAndStockLocation,

        unit_of_measurement: {
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        linked_part: {
            type: StandardPartTypes.OffTheShelf,
            data: part.id,
        },
        price_break_inputs: [priceBreakInput],
        ncnr: offer.ncnr ?? null,
        offer_number: props.offerNumber,
        bid: true,
    };

    return offerInput;
}
