import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { Currency, formatToIso8601Date } from '@luminovo/commons';
import { http, ItemClass, Packaging, QuoteRequestLineItemDTO, ValidFor } from '@luminovo/http-client';
import { parse, Parser } from '@luminovo/parsers';
import { CellValue, Row } from 'read-excel-file';
import { z } from 'zod';
import { QuoteRequest } from '../../../components/PdfOfferImporter/types';
import { LeadTimeUnit } from '../../../components/PdfViewer';
import { OfferFileParser, OfferFileParseResult, PartialOfferLineItem } from '../../../types';
import { PartLoader } from '../../PartLoader';
export interface ParsedRow {
    lumiQuoteId?: string;
    bid?: boolean;
    quoteRequestLineItemId?: number;
    offeredMPN?: string;
    offeredManufacturer?: string;
    unitPrice?: number;
    unitPriceQuantity?: number;
    currency?: Currency;
    supplierPartNumber?: string;
    packaging?: Packaging;
    minimumOrderQuantity?: number;
    minimumPackagingQuantity?: number;
    stdFactoryLeadTime?: number;
    stdFactoryLeadTimeUnit?: LeadTimeUnit;
    cancellationWindow?: number;
    cancellationTimeUnit?: LeadTimeUnit;
    itemClass?: ItemClass;
    ncnr?: boolean;
    stock?: number;
    priceValidFrom?: string;
    priceValidUntil?: string;
    notes?: string;
    oneTimeCost?: number;
}

type ExcelColumnParser<TAttribute extends keyof ParsedRow> = {
    attribute: TAttribute;
    predicate: ({ headerLabel }: { headerLabel: string }) => boolean;
    zodParser: z.ZodType<ParsedRow[TAttribute], any, any>;
    parser?: Parser<ParsedRow[TAttribute]>;
};
export class ExcelQuoteRequestParser implements OfferFileParser {
    constructor(
        private readonly partLoader: PartLoader,
        private readonly readXlsxFile: (file: File) => Promise<Row[]>,
    ) {}

    supportsFile(file: File): boolean {
        return file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    async parse({ file, quoteRequest }: { file: File; quoteRequest?: QuoteRequest }): Promise<OfferFileParseResult> {
        const quoteRequestLineItemsPromise: Promise<QuoteRequestLineItemDTO[]> = quoteRequest
            ? http(
                  'GET /quote-request/:id/line-items',
                  {
                      pathParams: {
                          id: quoteRequest?.id,
                      },
                  },
                  getToken(),
              ).then((res) => res.items)
            : Promise.resolve([]);
        const { parsedRows } = await parseExcelFile(file, this.readXlsxFile);
        const rows = await convertParsedRowsToPartialOfferLineItems({
            parsedRows,
            partLoader: this.partLoader,
            quoteRequestLineItems: await quoteRequestLineItemsPromise,
        });

        return {
            pdfAnalyzeResponse: undefined,
            pdfDocument: undefined,
            regionNetwork: undefined,
            validFor: ValidFor.EveryCustomer,
            defaultCurrency: Currency.EUR,
            offerNumber: '',
            validFrom: formatToIso8601Date(new Date()),

            rows,
        };
    }
}

type AnyParser = Exclude<
    {
        [k in keyof ParsedRow]: ExcelColumnParser<k>;
    }[keyof ParsedRow],
    undefined
>;

/**
 * Analyzes the excel file to extract rows and header labels.
 */
async function analyzeExcelFile(
    file: File,
    readXlsxFile: (file: File) => Promise<Row[]>,
): Promise<{
    rows: Row[];
    headerLabels: string[];
}> {
    const rows = await readXlsxFile(file);
    // Assuming the first row contains headers
    const indexOfHeaderRow = rows.findIndex((row) => row.some((cell) => String(cell).includes('LumiQuote-ID')));
    const headerLabels = rows[indexOfHeaderRow]?.map((cell) => String(cell)) ?? [];
    const dataRows = rows.slice(indexOfHeaderRow + 1);

    return {
        rows: dataRows,
        headerLabels,
    };
}

const dateSchema: z.ZodType<string, any, any> = z
    .date()
    .transform((date) => formatToIso8601Date(date))
    .or(
        z
            .string()
            .regex(/^\d{4}\.\d{2}\.\d{2}$/)
            .transform((date) => date.replace(/\./g, '-')),
    );

async function parseExcelFile(
    file: File,
    readXlsxFile: (file: File) => Promise<Row[]>,
): Promise<{ parsedRows: ParsedRow[] }> {
    function columnsPredicate(...variants: Array<string | RegExp>) {
        return ({ headerLabel }: { headerLabel: string }) => {
            for (const variant of variants) {
                if (typeof variant === 'string') {
                    if (headerLabel.toLowerCase().includes(variant.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (variant.test(headerLabel)) {
                        return true;
                    }
                }
            }
            return false;
        };
    }

    const parsers: AnyParser[] = [
        {
            attribute: 'bid',
            predicate: columnsPredicate(/^bid.?$/i),
            zodParser: z.boolean().optional().default(true),
            parser: parse.boolean(),
        },
        {
            attribute: 'offeredMPN',
            predicate: columnsPredicate('manufacturer part number'),
            zodParser: z.string().optional(),
        },
        {
            attribute: 'offeredManufacturer',
            predicate: columnsPredicate(/^Manufacturer$/i),
            zodParser: z.string().optional(),
        },
        {
            attribute: 'unitPrice',
            predicate: columnsPredicate(/^unit price\*$/i),
            zodParser: z.number(),
            parser: parse.number({ decimalSeparator: '.' }),
        },
        {
            attribute: 'unitPriceQuantity',
            predicate: columnsPredicate(/^unit price quantity$/i),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'currency',
            predicate: columnsPredicate('Currency'),
            zodParser: z.nativeEnum(Currency).optional(),
        },
        {
            attribute: 'supplierPartNumber',
            predicate: columnsPredicate('supplier part number', 'sku'),
            zodParser: z.string().optional(),
        },
        {
            attribute: 'packaging',
            predicate: columnsPredicate(/^packaging$/i),
            zodParser: z.nativeEnum(Packaging).optional(),
            parser: parse.packaging(),
        },
        {
            attribute: 'minimumOrderQuantity',
            predicate: columnsPredicate('moq', 'minimum order quantity'),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'minimumPackagingQuantity',
            predicate: columnsPredicate('mpq', 'minimum packaging quantity'),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'stdFactoryLeadTime',
            predicate: columnsPredicate(/^std\. factory lead time$/i),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'stdFactoryLeadTimeUnit',
            predicate: columnsPredicate(/^std\. factory lead time unit$/i),
            zodParser: z.nativeEnum(LeadTimeUnit).optional(),
        },
        {
            attribute: 'cancellationWindow',
            predicate: columnsPredicate('cancellation window'),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'cancellationTimeUnit',
            predicate: columnsPredicate('cancellation time unit'),
            zodParser: z.nativeEnum(LeadTimeUnit).optional(),
        },
        {
            attribute: 'itemClass',
            predicate: columnsPredicate('item class'),
            zodParser: z.nativeEnum(ItemClass).optional(),
        },
        {
            attribute: 'ncnr',
            predicate: columnsPredicate(/^ncnr$/i),
            zodParser: z.boolean().optional(),
            parser: parse.boolean(),
        },
        {
            attribute: 'stock',
            predicate: columnsPredicate('stock'),
            zodParser: z.number().optional(),
        },
        {
            attribute: 'priceValidFrom',
            predicate: columnsPredicate('valid from', 'price valid from'),
            zodParser: dateSchema,
        },
        {
            attribute: 'priceValidUntil',
            predicate: columnsPredicate('valid until', 'price valid until'),
            zodParser: dateSchema,
        },
        {
            attribute: 'notes',
            predicate: columnsPredicate('notes'),
            zodParser: z.string().optional(),
        },
        {
            attribute: 'oneTimeCost',
            predicate: columnsPredicate(/^one-time cost$/i),
            zodParser: z.number(),
            parser: parse.number({ decimalSeparator: '.' }),
        },
        {
            attribute: 'lumiQuoteId',
            predicate: columnsPredicate('LumiQuote-ID'),
            zodParser: z.string().optional(),
        },
        {
            attribute: 'quoteRequestLineItemId',
            predicate: columnsPredicate('LumiQuote-ID'),
            zodParser: z
                .string()
                .transform((value) => Number(value.split('|')[0]))
                .optional(),
        },
    ];

    const { rows, headerLabels } = await analyzeExcelFile(file, readXlsxFile);

    // Create a map of column index to parser for efficient lookup
    const parsersByColumnIndex = new Map<number, AnyParser[]>();

    // First, determine which parser should be used for each column (do predicate check only once per column)
    for (let columnIndex = 0; columnIndex < headerLabels.length; columnIndex++) {
        const headerLabel = headerLabels[columnIndex];
        const matchingParsers = parsers.filter((parser) => parser.predicate({ headerLabel }));
        parsersByColumnIndex.set(columnIndex, matchingParsers);
    }

    const parsedRows: ParsedRow[] = rows.map((row: Row) => {
        const parsedRow: ParsedRow = {};

        // Process each column with the pre-determined parsers
        for (const [columnIndex, parsersForColumn] of parsersByColumnIndex.entries()) {
            const cell = row[columnIndex];

            // Apply each matching parser for this column
            for (const parser of parsersForColumn) {
                const parsedValue = tryToParseValue(cell, parser);
                // Use type assertion to ensure TypeScript understands the assignment is valid
                // @ts-ignore
                parsedRow[parser.attribute] = parsedValue;
            }
        }

        return parsedRow;
    });

    return {
        parsedRows,
    };
}

function tryToParseValue<TAttribute extends keyof ParsedRow>(
    cell: CellValue,
    parser: ExcelColumnParser<TAttribute>,
): ParsedRow[TAttribute] {
    const zodResult = parser.zodParser.safeParse(cell);
    if (zodResult.success) {
        return zodResult.data;
    }
    if (typeof cell === 'string' && parser.parser) {
        const paraserResult = parser.parser.parse(cell);
        if (paraserResult.ok) {
            return paraserResult.value;
        }
    }
    return undefined;
}

async function convertParsedRowsToPartialOfferLineItems({
    parsedRows,
    partLoader,
    quoteRequestLineItems,
}: {
    parsedRows: ParsedRow[];
    partLoader: PartLoader;
    quoteRequestLineItems: QuoteRequestLineItemDTO[];
}): Promise<PartialOfferLineItem[]> {
    let builder = partLoader;
    for (const parsedRow of parsedRows) {
        if (!parsedRow.offeredMPN || !parsedRow.offeredManufacturer) {
            continue;
        }
        builder = builder.batchQuery({
            mpn: parsedRow.offeredMPN,
            manufacturer: parsedRow.offeredManufacturer,
        });
    }

    const quoteRequestLineItemsById = new Map(quoteRequestLineItems.map((item) => [item.id, item]));

    const promises = parsedRows.map(async (parsedRow, i): Promise<PartialOfferLineItem> => {
        const quoteRequestLineItem = quoteRequestLineItemsById.get(parsedRow.quoteRequestLineItemId ?? -1) ?? undefined;

        // Make the rowId unique by adding the index of the row in the file
        const rowId = `${i}`;

        return {
            rowId,
            bid: parsedRow.bid ?? Boolean(parsedRow.unitPrice),
            quoteRequestLineItem,
            part: await builder.fetch({
                mpn: parsedRow.offeredMPN,
                manufacturer: parsedRow.offeredManufacturer,
            }),
            currency: parsedRow.currency,
            unitPrice: parsedRow.unitPrice,
            moq: parsedRow.minimumOrderQuantity,
            mpq: parsedRow.minimumPackagingQuantity,
            packaging: parsedRow.packaging,
            standardFactoryLeadTime: parsedRow.stdFactoryLeadTime,
            standardFactoryLeadTimeUnit: parsedRow.stdFactoryLeadTimeUnit,
            notes: parsedRow.notes,
            stock: parsedRow.stock,
            ncnr: parsedRow.ncnr,
            supplierPartNumber: parsedRow.supplierPartNumber,
            validFrom: parsedRow.priceValidFrom,
            validUntil: parsedRow.priceValidUntil,
            cancellationWindow: parsedRow.cancellationWindow,
            cancellationTimeUnit: parsedRow.cancellationTimeUnit,
            itemClass: parsedRow.itemClass,
            oneTimeCost: parsedRow.oneTimeCost
                ? [{ amount: parsedRow.oneTimeCost, label: t`One-time cost` }]
                : undefined,
            countryOfOrigin: undefined,
            eccnNumbers: undefined,
            htsCode: undefined,
            reach: undefined,
            rohs: undefined,
            source: undefined,
        };
    });

    return Promise.all(promises);
}
