import { t } from '@lingui/macro';
import { Currency, indexBy } from '@luminovo/commons';
import { Cell, cellSchema, reduceValidated, validateArray, Validated } from '@luminovo/fields';
import {
    ComplianceStatus,
    ItemClass,
    Packaging,
    PartLiteRuntype,
    PartLiteTypes,
    PdfAnalyzeResponse,
    RegionsEnum,
    ValidFor,
} from '@luminovo/http-client';
import { differenceInDays } from 'date-fns';
import { Atom, atom, WritableAtom } from 'jotai';
import { z } from 'zod';
import {
    FormFieldsConfiguration,
    OfferField,
    OfferLineItem,
    PartialOfferLineItem,
    PdfOfferLineItem,
    ValidatedFormState,
} from '../../types';
import { LeadTimeUnit } from '../PdfViewer';
import { QuoteRequest } from './types';

const $rawRows = atom<PartialOfferLineItem[]>([]);

const $rows = atom(
    (get): PdfOfferLineItem[] => {
        const rawRows = get($rawRows);
        return rawRows.map((row): PdfOfferLineItem => {
            // First, create validated fields for each property
            const validatedFields = {
                part: cellSchema(PartLiteRuntype)
                    .validator((z) =>
                        z.refine(
                            (part) =>
                                part.kind === PartLiteTypes.OffTheShelf ||
                                part.kind === PartLiteTypes.Custom ||
                                part.kind === PartLiteTypes.CustomComponent ||
                                part.kind === PartLiteTypes.Ipn,
                        ),
                    )
                    .message(t`Part not found`)
                    .validate(row.part),

                unitPrice: cellSchema(z.number())
                    .validator((z) => z.max(1_000_000).gt(0))
                    .message(t`Invalid unit price`)
                    .validate(row.unitPrice),

                moq: cellSchema(z.number())
                    .validator((z) => z.min(1).max(1_000_000_000_000))
                    .message(t`Invalid MOQ`)
                    .validate(row.moq),

                mpq: cellSchema(z.number())
                    .validator((z) => z.min(1).max(1_000_000_000_000))
                    .message(t`Invalid MPQ`)
                    .validate(row.mpq),

                currency: cellSchema(z.nativeEnum(Currency))
                    .validator((z) => z.optional())
                    .message(t`Invalid currency`)
                    .validate(row.currency),

                packaging: cellSchema(z.nativeEnum(Packaging))
                    .validator((z) => z.optional())
                    .message(t`Invalid packaging`)
                    .validate(row.packaging),

                standardFactoryLeadTime: cellSchema(z.number())
                    .validator((z) => z.min(0).finite().optional())
                    .message(t`Invalid std. factory lead time`)
                    .validate(row.standardFactoryLeadTime),

                standardFactoryLeadTimeUnit: cellSchema(z.nativeEnum(LeadTimeUnit))
                    .validator((z) => z.optional().default(LeadTimeUnit.Weeks))
                    .message(t`Invalid std. factory lead time unit`)
                    .validate(row.standardFactoryLeadTimeUnit),

                notes: cellSchema(z.string())
                    .validator((z) =>
                        z
                            .max(200)
                            .optional()
                            .transform((x) => x ?? ''),
                    )
                    .message(t`Note is too long`)
                    .validate(row.notes),

                stock: cellSchema(z.number())
                    .validator((z) => z.min(0).finite().optional())
                    .message(t`Invalid stock`)
                    .validate(row.stock),

                bid: cellSchema(z.boolean()).validate(row.bid),

                ncnr: cellSchema(z.boolean())
                    .validator((z) => z.optional())
                    .validate(row.ncnr),

                reach: cellSchema(z.nativeEnum(ComplianceStatus))
                    .validator((z) => z.optional().transform((x) => x ?? ComplianceStatus.Unknown))
                    .validate(row.reach),

                rohs: cellSchema(z.nativeEnum(ComplianceStatus))
                    .validator((z) => z.optional().transform((x) => x ?? ComplianceStatus.Unknown))
                    .validate(row.rohs),

                supplierPartNumber: cellSchema(z.string())
                    .validator((z) => z.trim().max(200).optional())
                    .validate(row.supplierPartNumber),

                validFrom: cellSchema(z.string())
                    .validator((z) => z.date().optional())
                    .message(t`Invalid valid from`)
                    .validate(row.validFrom),

                validUntil: cellSchema(z.string())
                    .validator((z) => z.date().optional())
                    .message(t`Invalid valid until`)
                    .validate(row.validUntil),

                cancellationWindow: cellSchema(z.number())
                    .validator((z) => z.optional())
                    .message(t`Invalid cancellation window`)
                    .validate(row.cancellationWindow),

                cancellationTimeUnit: cellSchema(z.nativeEnum(LeadTimeUnit))
                    .validator((z) => z.optional().transform((x) => x ?? LeadTimeUnit.Weeks))
                    .message(t`Invalid cancellation time unit`)
                    .validate(row.cancellationTimeUnit),

                itemClass: cellSchema(z.nativeEnum(ItemClass))
                    .validator((z) => z.optional())
                    .message(t`Invalid item class`)
                    .validate(row.itemClass),

                oneTimeCost: cellSchema(z.array(z.object({ amount: z.number(), label: z.string() })))
                    .validator(() =>
                        z.array(z.object({ amount: z.number().finite().nonnegative(), label: z.string() })).optional(),
                    )
                    .message(t`Invalid one-time cost`)
                    .validate(row.oneTimeCost),

                eccnNumbers: cellSchema(z.string())
                    .validator((z) => z.optional())
                    .message(t`Invalid ECCN numbers`)
                    .validate(row.eccnNumbers),

                htsCode: cellSchema(z.string())
                    .validator((z) =>
                        z
                            .trim()
                            .regex(/^\d{2,}(\.\d+)*$/)
                            .optional(),
                    )
                    .message(t`Invalid HTS code`)
                    .validate(row.htsCode),

                countryOfOrigin: cellSchema(z.nativeEnum(RegionsEnum))
                    .validator((z) => z.optional())
                    .validate(row.countryOfOrigin),
            } satisfies {
                [K in OfferField]: Validated<OfferLineItem[K]>;
            };

            const validatedRow: Validated<OfferLineItem> = reduceValidated({
                rowId: { status: 'success', value: row.rowId },
                source: { status: 'success', value: row.source },
                quoteRequestLineItem: { status: 'success', value: row.quoteRequestLineItem },
                ...validatedFields,
            });

            return {
                rowId: row.rowId,
                source: row.source,
                quoteRequestLineItem: row.quoteRequestLineItem,
                selected: false,
                row: validatedRow,
                ...validatedFields,
            };
        });
    },
    (get, set, action: RowAction) => {
        switch (action.type) {
            case 'setRows':
                set($rawRows, action.rows);
                break;
            case 'duplicateRow': {
                const rawRows = get($rawRows);
                const newRows = rawRows.flatMap((row) => {
                    if (action.rowIds.includes(row.rowId)) {
                        const newRow = {
                            ...row,
                            rowId: crypto.randomUUID(),
                        };
                        return [row, newRow];
                    }
                    return [row];
                });
                set($rawRows, newRows);
                break;
            }
            case 'deleteRow': {
                const rawRows = get($rawRows);
                const filteredRows = rawRows.filter((row) => !action.rowIds.includes(row.rowId));
                set($rawRows, filteredRows);
                break;
            }
            case 'addEmptyRowBelow': {
                const rawRows = get($rawRows);
                const rowIndex = rawRows.findIndex((row) => row.rowId === action.rowId);

                const oldRow = rawRows[rowIndex];

                const newRow: PartialOfferLineItem = {
                    rowId: crypto.randomUUID(),
                    quoteRequestLineItem: oldRow?.quoteRequestLineItem,
                    cancellationTimeUnit: undefined,
                    cancellationWindow: undefined,
                    countryOfOrigin: undefined,
                    currency: undefined,
                    htsCode: undefined,
                    eccnNumbers: undefined,
                    source: undefined,
                    itemClass: undefined,
                    moq: undefined,
                    mpq: undefined,
                    ncnr: undefined,
                    notes: '',
                    oneTimeCost: undefined,
                    packaging: undefined,
                    part: undefined,
                    reach: undefined,
                    rohs: undefined,
                    standardFactoryLeadTime: undefined,
                    standardFactoryLeadTimeUnit: undefined,
                    stock: undefined,
                    supplierPartNumber: undefined,
                    unitPrice: undefined,
                    validFrom: undefined,
                    validUntil: undefined,
                };

                if (rowIndex <= 0) {
                    set($rawRows, [newRow, ...rawRows]);
                } else {
                    const newRows = [...rawRows.slice(0, rowIndex + 1), newRow, ...rawRows.slice(rowIndex + 1)];
                    set($rawRows, newRows);
                }
                break;
            }
            case 'setAttribute': {
                const rawRows = get($rawRows);

                const newRows: PartialOfferLineItem[] = rawRows.map((row): PartialOfferLineItem => {
                    if (row.rowId !== action.rowId) {
                        return row;
                    }
                    return {
                        ...row,
                        [action.attribute]: action.value ?? undefined,
                    };
                });
                set($rawRows, newRows);
                break;
            }
            case 'updateRows': {
                const rawRows = get($rawRows);
                const groupedRows = indexBy(action.rows, (row) => row.rowId);
                const newRows: PartialOfferLineItem[] = rawRows.map((row): PartialOfferLineItem => {
                    const updatedRow = groupedRows.get(row.rowId);
                    if (!updatedRow) {
                        return row;
                    }
                    return updatedRow;
                });
                set($rawRows, newRows);
                break;
            }
        }
    },
);

type SetAttributeAction<TKey extends keyof OfferLineItem> = {
    type: 'setAttribute';
    rowId: string;
    attribute: TKey;
    value: OfferLineItem[TKey] | undefined;
};

export type RowAction =
    | {
          type: 'setRows';
          rows: PartialOfferLineItem[];
      }
    | {
          type: 'duplicateRow';
          // duplicates the row with the given rowId. The new row will be appended right after the original row.
          rowIds: string[];
      }
    | {
          type: 'deleteRow';
          // deletes the row with the given rowId, if not provided, no changes are made
          rowIds: string[];
      }
    | {
          type: 'addEmptyRowBelow';
          // if rowId is provided, the new row will be inserted below the row with the given rowId
          // otherwise, the new row will be appended to the end of the list
          //
          // For the empty row, generate a new rowId, set the source to an empty object, and set all other attributes to undefined or
          // a sane default.
          rowId?: string;
      }
    | {
          type: 'updateRows';
          rows: PartialOfferLineItem[];
      }
    | SetAttributeAction<'bid'>
    | SetAttributeAction<'currency'>
    | SetAttributeAction<'unitPrice'>
    | SetAttributeAction<'moq'>
    | SetAttributeAction<'mpq'>
    | SetAttributeAction<'packaging'>
    | SetAttributeAction<'standardFactoryLeadTime'>
    | SetAttributeAction<'standardFactoryLeadTimeUnit'>
    | SetAttributeAction<'validFrom'>
    | SetAttributeAction<'validUntil'>
    | SetAttributeAction<'cancellationWindow'>
    | SetAttributeAction<'cancellationTimeUnit'>
    | SetAttributeAction<'itemClass'>
    | SetAttributeAction<'oneTimeCost'>
    | SetAttributeAction<'notes'>
    | SetAttributeAction<'stock'>
    | SetAttributeAction<'ncnr'>
    | SetAttributeAction<'part'>
    | SetAttributeAction<'supplierPartNumber'>
    | SetAttributeAction<'reach'>
    | SetAttributeAction<'rohs'>
    | SetAttributeAction<'eccnNumbers'>
    | SetAttributeAction<'htsCode'>
    | SetAttributeAction<'countryOfOrigin'>;

export interface OfferImporterState {
    $formState: Atom<Validated<ValidatedFormState>>;

    $formFieldsConfiguration: Atom<FormFieldsConfiguration>;
    $quoteRequests: Atom<QuoteRequest[]>;
    $stepIndex: WritableAtom<number, [update: number | 'next' | 'prev'], void>;
    $showPdfPanel: WritableAtom<boolean, [update: boolean], void>;

    fields: {
        $file: Cell<File | undefined>;
        $analyzeResult: Cell<PdfAnalyzeResponse | undefined>;
        $validDays: Cell<number | undefined>;
        $defaultCurrency: Cell<Currency>;
        $offerNumber: Cell<string>;
        $quoteRequest: Cell<QuoteRequest>;
        $validFrom: Cell<string>;
        $validUntil: Cell<string | undefined>;
        $validFor: Cell<ValidFor>;
        $attachment: Cell<string | undefined>;
        $rows: WritableAtom<PdfOfferLineItem[], [action: RowAction], void>;
    };
}

function atomStepIndex({ min, max }: { min: number; max: number }) {
    const $stepIndex = atom(0);

    return atom(
        (get) => get($stepIndex),
        (get, set, update: number | 'next' | 'prev') => {
            const oldIndex = get($stepIndex);
            const newIndex = update === 'next' ? oldIndex + 1 : update === 'prev' ? oldIndex - 1 : update;
            const clampedIndex = Math.max(min, Math.min(newIndex, max));
            set($stepIndex, clampedIndex);
        },
    );
}
export function createFormState({
    quoteRequest,
    quoteRequests,
    initialFieldConfiguration,
}: {
    quoteRequest?: QuoteRequest;
    quoteRequests: QuoteRequest[];
    initialFieldConfiguration: FormFieldsConfiguration;
}): OfferImporterState {
    const $stepIndex = atomStepIndex({ min: 0, max: 2 });

    const $showPdfPanel = atom(true);
    const $formFieldsConfiguration = atom<FormFieldsConfiguration>(initialFieldConfiguration);
    const $quoteRequests = atom<QuoteRequest[]>(quoteRequests);
    const $quoteRequest = cellSchema(z.custom<QuoteRequest>((x) => Boolean(x))).cell({ initialValue: quoteRequest });

    const $file = cellSchema(z.instanceof(File)).build();
    const $analyzeResult = cellSchema(z.custom<PdfAnalyzeResponse>().optional()).build();
    const $defaultCurrency = cellSchema(z.nativeEnum(Currency)).build();
    const $offerNumber = cellSchema(z.string())
        .validator((z) => z.trim().max(100))
        .build();
    const $validFrom = cellSchema(z.string())
        .validator((z) => z.date())
        .build();
    const $validUntil = cellSchema(z.string())
        .validator((z) => z.date().optional())
        .build();
    const $validFor = cellSchema(z.nativeEnum(ValidFor))
        .validator((z) => z.optional().transform((x) => x ?? ValidFor.EveryCustomer))
        .build();
    const $attachment = cellSchema(z.string().optional()).build();
    const $validDays: Cell<number | undefined> = atom(
        (get) => {
            const validFrom = get($validFrom);
            const validUntil = get($validUntil);
            if (validFrom.status !== 'success' || validUntil.status !== 'success') {
                return { status: 'success', value: undefined };
            }
            if (validUntil.value === undefined) {
                return { status: 'success', value: undefined };
            }
            const diff = differenceInDays(new Date(validUntil.value), new Date(validFrom.value));
            if (diff < 0) {
                return { status: 'error', message: t`Invalid range` };
            }
            return { status: 'success', value: diff };
        },
        () => {
            // do nothing
        },
    );

    const $formState = atom((get) => {
        const file = get($file);
        const analyzeResult = get($analyzeResult);
        const defaultCurrency = get($defaultCurrency);
        const offerNumber = get($offerNumber);
        const quoteRequest = get($quoteRequest);
        const validFrom = get($validFrom);
        const validUntil = get($validUntil);
        const validDays = get($validDays);
        const attachment = get($attachment);
        const rows: Validated<OfferLineItem[]> = validateArray(get($rows).map((row: PdfOfferLineItem) => row.row));
        const validFor = get($validFor);
        const validatedForm: Validated<ValidatedFormState> = reduceValidated({
            file,
            analyzeResult,
            defaultCurrency,
            offerNumber,
            quoteRequest,
            validFrom,
            validUntil,
            validDays,
            validFor,
            attachment,
            rows,
        });

        return validatedForm;
    });

    return {
        $formState,
        $formFieldsConfiguration,
        $quoteRequests,
        $stepIndex,
        $showPdfPanel,
        fields: {
            $file,
            $analyzeResult,
            $defaultCurrency,
            $offerNumber,
            $quoteRequest,
            $validFrom,
            $validUntil,
            $validDays,
            $validFor,
            $attachment,
            $rows,
        },
    };
}
