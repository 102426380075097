import { t } from '@lingui/macro';
import {
    formatDecimal,
    formatLocalDate,
    formatMonetaryValue,
    formatRelativeTime,
    isEqual,
    isPresent,
    MonetaryValue,
} from '@luminovo/commons';
import {
    Colors,
    colorSystem,
    compareByNumber,
    FieldDate,
    FieldMonetaryValue,
    FieldNumeric,
    FieldSelect,
    FieldText,
    Link,
    MenuButton,
    MenuItem,
    Tag,
    TertiaryIconButton,
} from '@luminovo/design-system';
import { Currency, CustomPartTypeEnum, PartLite, PartLiteTypes } from '@luminovo/http-client';
import * as icons from '@mui/icons-material';
import { CellStyle, CellStyleFunc, GridApi, ValueGetterFunc } from 'ag-grid-community';
import { HeaderComponent } from '../components/HeaderComponent';
import { HeaderComponentEnum } from '../components/HeaderComponentEnum';
import { HeaderComponentNumeric } from '../components/HeaderComponentNumeric';
import {
    DataGridBooleanColumn,
    DataGridColumn,
    DataGridEnumColumn,
    DataGridRow,
    DataGridRowWithActions,
} from '../types';
import { columnBoolean } from './columnBoolean';
import { columnStatus, columnStatusChip, ColumnStatusChipProps } from './columnStatus';
import { formatColumnValue, formatValue } from './getColumnValue';

function columnText<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
    return {
        valueFormatter: (params) => params.value || '-',
        comparator: (a, b) => (a || '').localeCompare(b || ''),
        filterValueGetter: ({ data, column }) => formatColumnValue(data, column),
        headerComponent: HeaderComponent,
        cellEditorPopup: true,
        cellStyle: cellStyleFromValidation({ validate }),
        cellEditor: ({
            value,
            api,
            onValueChange,
        }: {
            value: string | null | undefined;
            onValueChange: (value: string | null) => void;
            api: GridApi;
        }) => {
            // Measure column width to determine if multiline editor is needed
            const columnWidth = api.getColumn(props.colId ?? '')?.getActualWidth() ?? 100;
            return (
                <FieldText
                    autoFocus
                    fullWidth
                    sx={{ minWidth: columnWidth, lineHeight: 1.5 }}
                    onFocus={(e) => e.target.select()}
                    onChange={onValueChange}
                    multiline={value?.includes('\n')}
                    rows={value?.split('\n').length ?? 1}
                    size="small"
                    value={value ?? null}
                />
            );
        },
        ...props,
    };
}

function columnMonetaryValue<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
    return {
        equals: (a, b) => isEqual(a, b),
        comparator: compareByNumber((mv) => parseFloat(mv?.amount ?? '0')),
        valueFormatter: (params) => formatMonetaryValue(params.value),
        headerComponent: HeaderComponent,
        cellStyle: cellStyleFromValidation({ validate, defaultStyle: { textAlign: 'right' } }),
        cellEditorPopup: true,
        cellEditor: ({
            value,
            onValueChange,
        }: {
            value: MonetaryValue | null | undefined;
            onValueChange: (value: MonetaryValue | null) => void;
        }) => {
            return (
                <FieldMonetaryValue
                    defaultCurrency={Currency.EUR}
                    enableCurrencyPicker
                    onChange={onValueChange}
                    size="small"
                    value={value ?? null}
                />
            );
        },
        ...props,
    };
}

function columnNotes<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
    return {
        ...columnText({ ...props }),
        ...props,
    };
}

function columnCurrency<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, Currency>): DataGridColumn<TRow, Currency> {
    return {
        colId: 'currency',
        valueFormatter: ({ value }) => value ?? '-',
        headerComponent: HeaderComponent,
        cellStyle: cellStyleFromValidation({ validate }),
        cellEditor: ({
            value,
            onValueChange,
        }: {
            value: Currency | null | undefined;
            onValueChange: (value: Currency | null) => void;
        }) => {
            return (
                <FieldSelect
                    autoFocus
                    sx={{
                        minWidth: '140px',
                    }}
                    fullWidth
                    autoSelect
                    placeholder={t`Select currency`}
                    options={Object.values(Currency)}
                    onChange={onValueChange}
                    getOptionLabel={(option) => option}
                    getOptionKey={(option) => option}
                    size="small"
                    value={value ?? null}
                />
            );
        },
        ...props,
    };
}

function columnUnitPrice<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
    return {
        ...columnMonetaryValue({ ...props }),
        headerComponent: HeaderComponent,
        valueFormatter: (params) => formatMonetaryValue(params.value, 'unit-price'),
        ...props,
    };
}

function columnUnitPriceDecimal<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        ...columnDecimal({ ...props }),
        colId: 'unitPriceDecimal',
        valueFormatter: (params) => formatDecimal(params.value, { minimumFractionDigits: 5, maximumFractionDigits: 5 }),
        ...props,
    };
}

function columnTotalPrice<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
    return {
        ...columnMonetaryValue({ ...props }),
        headerComponent: HeaderComponent,
        valueFormatter: (params) => formatMonetaryValue(params.value, 'default'),
        ...props,
    };
}

type DateLike = string | Date;

function columnLocalDate<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
    return {
        headerComponent: HeaderComponent,
        valueFormatter: ({ value }) => formatLocalDate(value),
        comparator: (a, b) => (a ?? '').localeCompare(b ?? ''),
        cellStyle: cellStyleFromValidation({ validate }),
        cellEditor: ({
            value,
            onValueChange,
        }: {
            value: string | null | undefined;
            onValueChange: (value: string | null) => void;
        }) => {
            return (
                <FieldDate
                    sx={{
                        minWidth: '140px',
                    }}
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    onChange={onValueChange}
                    size="small"
                    value={value ?? null}
                    slotProps={{
                        input: {
                            autoComplete: 'off',
                        },
                    }}
                />
            );
        },
        ...props,
    };
}

function columnDate<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, DateLike>): DataGridColumn<TRow, DateLike> {
    return {
        headerComponent: HeaderComponent,
        valueFormatter: ({ value }) => formatRelativeTime(value),
        cellStyle: cellStyleFromValidation({ validate }),
        cellEditor: ({
            value,
            onValueChange,
        }: {
            value: string | null | undefined;
            onValueChange: (value: string | null) => void;
        }) => {
            return <FieldDate onChange={onValueChange} size="small" value={value ?? null} />;
        },
        ...props,
    };
}

function columnRelativeDate<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, DateLike>): DataGridColumn<TRow, DateLike> {
    return {
        ...columnDate({ ...props }),
        valueFormatter: ({ value }) => formatRelativeTime(value),
        ...props,
    };
}

function columnLink<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
    return {
        editable: false,
        cellStyle: cellStyleFromValidation({ validate: props.validate }),
        headerComponent: HeaderComponent,
        cellRenderer: ({ value }) => {
            if (!isPresent(value)) {
                return '-';
            }
            return <Link attention="low">{value}</Link>;
        },
        ...props,
    };
}

function cellStyleFromValidation<TRow extends DataGridRow, TValue>({
    validate,
    defaultStyle = {},
}: {
    validate: DataGridColumn<TRow, TValue>['validate'];
    defaultStyle?: CellStyle;
}): CellStyleFunc<TRow, TValue> {
    return ({ value, data }): CellStyle => {
        if (!validate || !data) {
            return defaultStyle;
        }

        const validation = validate(value, data);

        if (validation.status === 'success') {
            return defaultStyle;
        }

        if (validation.status === 'warning') {
            return {
                ...defaultStyle,
                backgroundColor: colorSystem.yellow[1],
                color: colorSystem.yellow[8],
            };
        }

        return {
            ...defaultStyle,
            backgroundColor: colorSystem.red[1],
            color: colorSystem.red[8],
        };
    };
}

function columnDecimal<TRow extends DataGridRow>({
    validate,
    ...props
}: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        headerComponent: HeaderComponentNumeric,
        cellDataType: 'number',
        valueFormatter: (params) => formatDecimal(params.value, { minimumFractionDigits: 5, maximumFractionDigits: 5 }),
        cellStyle: cellStyleFromValidation({ validate, defaultStyle: { textAlign: 'right' } }),
        cellEditor: ({
            value,
            api,
            onValueChange,
        }: {
            value: number | null | undefined;
            onValueChange: (value: number | null) => void;
            api: GridApi;
        }) => {
            // Measure column width to determine if multiline editor is needed
            const columnWidth = api.getColumn(props.colId ?? '')?.getActualWidth() ?? 100;
            return (
                <FieldNumeric
                    autoFocus
                    fullWidth
                    sx={{ minWidth: columnWidth, lineHeight: 1.5 }}
                    onFocus={(e) => e.target.select()}
                    onChange={onValueChange}
                    size="small"
                    value={value ?? null}
                />
            );
        },
        ...props,
    };
}

function columnInteger<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        ...columnDecimal({ ...props }),
        headerComponent: HeaderComponent,
        valueFormatter: (params) => formatDecimal(params.value),
        ...props,
    };
}

function columnLeadTimeDays<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        ...columnDecimal({ ...props }),
        headerComponent: HeaderComponent,
        valueFormatter: (params) => formatDecimal(params.value),
        ...props,
    };
}

function columnMoq<TRow extends DataGridRow>({ ...props }: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        ...columnInteger({ ...props }),
        colId: 'moq',
        ...props,
    };
}

function columnMpq<TRow extends DataGridRow>({ ...props }: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
    return {
        ...columnInteger({ ...props }),
        colId: 'mpq',
        ...props,
    };
}

function columnSelection<TRow extends DataGridRow>({
    ...props
}: DataGridBooleanColumn<TRow>): DataGridBooleanColumn<TRow> {
    return {
        ...columnBoolean({ ...props }),
        width: 44,
        colId: 'selection',
        headerName: '',
        pinned: 'left',
        ...props,
    };
}

function columnEnum<TRow extends DataGridRow, TValue extends string = string>({
    options = [],
    onUpdateRow,
    ...props
}: DataGridEnumColumn<TRow, TValue>): DataGridColumn<TRow, TValue> {
    const colors: Colors[] = [
        // colors
        'neutral',
        'violet',
        'blue',
        'green',
        'yellow',
        'teal',
        'primary',
    ];
    return {
        valueFormatter: ({ value }) => value ?? '-',
        headerComponent: HeaderComponentEnum,
        headerComponentParams: {
            options,
            onUpdateRow,
        },
        filterValueGetter: (params) => {
            const value = props.valueGetter(params);
            return props.valueFormatter ? props.valueFormatter({ value, ...params }) : value;
        },
        comparator: (a, b) => (a ?? '').localeCompare(b ?? ''),
        cellRenderer: ({ value, ...rest }) => {
            if (!isPresent(value)) {
                return '-';
            }
            const formattedValue = props.valueFormatter
                ? // TODO(fhur): fix this, we should try to pass the rest of the params to the valueFormatter
                  // @ts-expect-error
                  props.valueFormatter({ value, ...rest })
                : String(value ?? '-');
            const color = colors[options.indexOf(value)] ?? 'neutral';
            return <Tag attention="low" color={color} label={formattedValue} />;
        },
        cellEditor: ({
            value,
            api,
            onValueChange,
        }: {
            value: TValue | null | undefined;
            onValueChange: (value: TValue | null) => void;
            api: GridApi;
        }) => {
            // Measure column width to determine if multiline editor is needed
            const column = api.getColumn(props.colId ?? '');
            const columnWidth = column?.getActualWidth() ?? 100;
            return (
                <FieldSelect
                    options={options}
                    autoFocus
                    fullWidth
                    getOptionLabel={(value) => {
                        if (value === null || value === undefined) {
                            return '-';
                        }
                        if (!column) {
                            return String(value);
                        }
                        return formatValue(value, column);
                    }}
                    sx={{ minWidth: columnWidth, lineHeight: 1.5 }}
                    onChange={(newValue) => onValueChange(newValue)}
                    size="small"
                    value={value ?? null}
                />
            );
        },
        ...props,
    };
}

function columnActions<TRow extends DataGridRow>({
    label,
    ...props
}: DataGridColumn<TRow, DataGridRowWithActions['actions']>): DataGridColumn<TRow, DataGridRowWithActions['actions']> {
    return {
        filter: false,
        headerName: '',
        pinned: 'right',
        editable: false,
        cellEditor: false,
        flex: 0.1,
        width: 47,
        onCellClicked: (params) => {
            params.event?.stopPropagation();
            params.event?.preventDefault();
        },
        headerComponent: () => '',
        valueFormatter: (params) => '',
        cellRenderer: ({ value }) => {
            if (!value) {
                return '';
            }
            return (
                <MenuButton
                    style={{ minWidth: '24px', marginBottom: 2 }}
                    appearance="tertiary"
                    size="small"
                    icon={<icons.MoreVert fontSize="inherit" />}
                >
                    {value.map(({ icon: Icon, ...action }) => (
                        <MenuItem
                            startIcon={Icon && <Icon />}
                            label={action.label}
                            key={action.label}
                            onClick={action.onClick}
                        />
                    ))}
                </MenuButton>
            );
        },
        ...props,
    };
}

function columnAddColumn<TRow extends DataGridRow>(): DataGridColumn<TRow, string> {
    return {
        headerName: ``,
        editable: false,
        valueFormatter: () => '',
        valueGetter: () => '',
        pinned: 'right',
        width: 24 + 20,
        headerComponent: () => <TertiaryIconButton size="small">{<icons.Add />}</TertiaryIconButton>,
    };
}

function columnPart<TRow extends DataGridRow>({
    ...props
}: DataGridColumn<TRow, PartLite>): DataGridColumn<TRow, PartLite> {
    const valueGetter: ValueGetterFunc<TRow, PartLite> = props.valueGetter;

    const valueGetterFunc: ValueGetterFunc<TRow, string> = (params) => {
        // @ts-ignore
        return formatPart(valueGetter(params));
    };

    return {
        colId: 'part',
        editable: false,
        equals: (a, b) => isEqual(a, b),
        comparator: (a, b) => {
            return (formatPart(a) ?? '').localeCompare(formatPart(b) ?? '');
        },
        valueFormatter: ({ value }) => formatPart(value),
        headerComponent: HeaderComponent,
        filterValueGetter: valueGetterFunc,
        ...props,
    };
}

export function formatPart(part?: PartLite | null | undefined): string {
    if (!isPresent(part)) {
        return '-';
    }
    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return `${part.mpn}, ${part.manufacturer.name}`;
        case PartLiteTypes.Generic:
            return `Generic ${part.content.type}`;
        case PartLiteTypes.Unknown:
            return `Unknown ${part.mpn}, ${part.manufacturer.name}`;
        case PartLiteTypes.RawSpecification:
            if (isPresent(part.manufacturer.name) && isPresent(part.mpn)) {
                return `${part.mpn}, ${part.manufacturer.name}`;
            }
            if (isPresent(part.mpn)) {
                return `${part.mpn}`;
            }
            if (isPresent(part.manufacturer.name)) {
                return `${part.manufacturer.name}`;
            }
            return t`Unknown`;
        case PartLiteTypes.Ipn:
            return part.id;
        case PartLiteTypes.Custom:
            if (part.kind === PartLiteTypes.Custom && part.type.name === CustomPartTypeEnum.PCB) {
                return `PCB`;
            }
            return `Custom ${part.type.name} ${part.description ?? ''}`;
        case PartLiteTypes.CustomComponent:
            return part.id;
    }
}

function columnRowIndex<TRow extends DataGridRow>(): DataGridColumn<TRow, number> {
    return columnInteger({
        colId: 'rowIndex',
        headerName: '',
        editable: false,
        pinned: 'left',
        headerComponent: () => '',
        width: 60,
        cellStyle: { textAlign: 'center', backgroundColor: colorSystem.neutral[0] },
        valueGetter: ({ node }) => (node?.rowIndex ?? 0) + 1,
    });
}

export class ColumnBuilder<TRow extends DataGridRow> {
    constructor(private columns: DataGridColumn<TRow, any>[] = []) {}

    /**
     * Renders a text column.
     */
    text(props: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
        return columnText<TRow>(props);
    }

    /**
     * Renders a currency.
     */
    currency(props: DataGridColumn<TRow, Currency>): DataGridColumn<TRow, Currency> {
        return columnCurrency<TRow>(props);
    }

    /**
     * Renders a monetary value column.
     *
     * @see {@link unitPrice} for unit price columns.
     * @see {@link totalPrice} for total price columns.
     */
    monetaryValue(props: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
        return columnMonetaryValue<TRow>(props);
    }

    /**
     * Renders a unit price column. Unit prices are rendered with the "unit-price" formatter i.e. with 5 decimals.
     */
    unitPrice(props: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
        return columnUnitPrice<TRow>(props);
    }

    unitPriceDecimal(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnUnitPriceDecimal<TRow>(props);
    }

    /**
     * Renders a monetary value column.
     */
    totalPrice(props: DataGridColumn<TRow, MonetaryValue>): DataGridColumn<TRow, MonetaryValue> {
        return columnTotalPrice<TRow>(props);
    }

    /**
     * A column that renders a local date e.g. "2024-01-01".
     */
    localDate(props: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
        return columnLocalDate<TRow>(props);
    }

    /**
     * A column that renders a date. This is different from the local date which doesn't store any time or timezone information.
     */
    date(props: DataGridColumn<TRow, DateLike>): DataGridColumn<TRow, DateLike> {
        return columnDate<TRow>(props);
    }

    /**
     * A column that renders a relative date e.g. "1 day ago".
     */
    relativeDate(props: DataGridColumn<TRow, DateLike>): DataGridColumn<TRow, DateLike> {
        return columnRelativeDate<TRow>(props);
    }

    /**
     * A column that renders a notes field. Use this when you need to render a long text.
     * This is different from a text column which is better suited for short text.
     */
    notes(props: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
        return columnNotes<TRow>(props);
    }

    /**
     * A column that renders a link.
     */
    link(props: DataGridColumn<TRow, string>): DataGridColumn<TRow, string> {
        return columnLink<TRow>(props);
    }

    /**
     * A generic column that renders a decimal number.
     */
    decimal(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnDecimal<TRow>(props);
    }

    /**
     * A generic column that renders an integer.
     */
    integer(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnInteger<TRow>(props);
    }

    /**
     * The lead time in days column.
     */
    leadTimeDays(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnLeadTimeDays<TRow>(props);
    }

    /**
     * A column that renders a checkbox. This is different from the selection column which has special behavior.
     */
    boolean(props: DataGridBooleanColumn<TRow>): DataGridColumn<TRow, boolean> {
        return columnBoolean<TRow>(props);
    }

    /**
     * Use this column whenever you need to render a set of actions for a row.
     */
    actions(
        props: DataGridColumn<TRow, DataGridRowWithActions['actions']>,
    ): DataGridColumn<TRow, DataGridRowWithActions['actions']> {
        return columnActions<TRow>(props);
    }

    /**
     * A selection column for when you need controlled selection. If you want uncontrolled selection, use AgGrid's rowSelection.
     */
    selection(props: DataGridBooleanColumn<TRow>): DataGridColumn<TRow> {
        return columnSelection<TRow>(props);
    }

    rowIndex(): DataGridColumn<TRow, number> {
        return columnRowIndex<TRow>();
    }

    /**
     * A column that renders a fixed set of options.
     */
    enum<TValue extends string = string>(props: DataGridEnumColumn<TRow, TValue>): DataGridColumn<TRow, TValue> {
        return columnEnum<TRow, TValue>(props);
    }

    /**
     * A column that renders a part.
     */
    part(props: DataGridColumn<TRow, PartLite>): DataGridColumn<TRow, PartLite> {
        return columnPart<TRow>(props);
    }

    /**
     * The minimum order quantity column.
     */
    moq(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnMoq<TRow>(props);
    }

    /**
     * The minimum purchase quantity.
     */
    mpq(props: DataGridColumn<TRow, number>): DataGridColumn<TRow, number> {
        return columnMpq<TRow>(props);
    }

    status(props: DataGridColumn<TRow, ColumnStatusChipProps>): DataGridColumn<TRow, ColumnStatusChipProps> {
        return columnStatus<TRow>(props);
    }

    /**
     * Similar to the status column, but renders a StatusChip component.
     */
    statusChip(props: DataGridColumn<TRow, ColumnStatusChipProps>): DataGridColumn<TRow, ColumnStatusChipProps> {
        return columnStatusChip<TRow>(props);
    }

    addColumn(): DataGridColumn<TRow, string> {
        return columnAddColumn<TRow>();
    }
}
