import { PartialOfferLineItem, PdfOfferLineItem } from '../../types';

export const convertPdfOfferLineItemToPartialOfferLineItem = (offer: PdfOfferLineItem): PartialOfferLineItem => ({
    rowId: offer.rowId,
    source: offer.source,
    bid: offer.bid.value,
    quoteRequestLineItem: offer.quoteRequestLineItem,
    cancellationTimeUnit: offer.cancellationTimeUnit.value,
    cancellationWindow: offer.cancellationWindow.value,
    countryOfOrigin: offer.countryOfOrigin.value,
    currency: offer.currency.value,
    eccnNumbers: offer.eccnNumbers.value,
    htsCode: offer.htsCode.value,
    itemClass: offer.itemClass.value,
    moq: offer.moq.value,
    mpq: offer.mpq.value,
    ncnr: offer.ncnr.value,
    notes: offer.notes.value ?? '',
    oneTimeCost: offer.oneTimeCost.value,
    packaging: offer.packaging.value,
    part: offer.part.value,
    reach: offer.reach.value,
    rohs: offer.rohs.value,
    standardFactoryLeadTime: offer.standardFactoryLeadTime.value,
    standardFactoryLeadTimeUnit: offer.standardFactoryLeadTimeUnit.value,
    stock: offer.stock.value,
    supplierPartNumber: offer.supplierPartNumber.value,
    unitPrice: offer.unitPrice.value,
    validFrom: offer.validFrom.value,
    validUntil: offer.validUntil.value,
});
