import * as Sentry from '@sentry/react';
import * as z from 'zod';
import { BACKEND_BASE, HOST_NAME, isProductionEnvironment } from '../../const';
import { printFormattedRuntypeError } from '../../utils';
import { NetworkError } from './NetworkError';
import { assertSuccessfulResponse } from './assertSuccessfulResponse';
import { composeRuntypeAssertionErrorMessage } from './composeRuntypeAssertionErrorMessage';
import { TQueryParamValue } from './endpoint';
import { endpointRegistry } from './endpointRegistry';
import { logToExternalErrorHandlers } from './logToExternalErrorHandlers';
import { parseEndpoint } from './parseEndpoint';
import { RegisteredHttpEndpoint } from './types/httpTypes';
import { ResponseWithMethod } from './types/responseWithMethodType';

type Stringable = string | number | boolean;

export type EndpointRegistry = typeof endpointRegistry;

/**
 * Utility function that returns the type of the requestBody for a given endpoint.
 */
export type ExtractRequestBody<T extends RegisteredHttpEndpoint> = z.infer<EndpointRegistry[T]['requestBody']>;

/**
 * Utility function that returns the type of the queryParams for a given endpoint.
 */
export type ExtractQueryParams<T extends RegisteredHttpEndpoint> = z.infer<EndpointRegistry[T]['queryParams']>;

/**
 * Utility function that returns the type of the requestBody for a given endpoint.
 */
export type ExtractResponseBody<T extends RegisteredHttpEndpoint> = z.infer<EndpointRegistry[T]['responseBody']>;

/**
 * Returns the endpoints that should be **invalidated** by the given endpoint.
 */
export function extractEndpointsToBeInvalidated(endpoint: RegisteredHttpEndpoint): RegisteredHttpEndpoint[] {
    return endpointRegistry[endpoint].invalidates ?? [];
}

/**
 * Returns the endpoints that should be **removed** by the given endpoint.
 */
export function extractEndpointsToBeRemoved(endpoint: RegisteredHttpEndpoint): RegisteredHttpEndpoint[] {
    return endpointRegistry[endpoint].removes ?? [];
}

export function buildUrl({
    endpoint,
    rootUrl,
    pathParams,
    queryParams,
}: {
    endpoint: string | RegisteredHttpEndpoint;
    rootUrl: string;
    pathParams: Record<string, Stringable>;
    queryParams: Record<string, TQueryParamValue>;
}): { method: string; url: string } {
    const { method, pathTemplate } = parseEndpoint(endpoint);

    let pathPart: string = pathTemplate;
    for (const [k, val] of Object.entries(pathParams)) {
        const toBeReplaced = `:${k}`;
        if (!pathPart.includes(toBeReplaced)) {
            throw new Error(
                `Cannot inject ${JSON.stringify(pathParams)} into path template "${pathTemplate}", ` +
                    `which does not contain "${toBeReplaced}" as a substring.`,
            );
        }
        pathPart = pathPart.replace(toBeReplaced, encodeURIComponent(val + ''));
    }

    const searchParams = new URLSearchParams();
    for (const [k, val] of Object.entries(queryParams)) {
        searchParams.set(k, val + '');
    }
    const numberOfSearchParams = Array.from(searchParams.keys()).length;
    const searchPart = numberOfSearchParams === 0 ? '' : `?${searchParams.toString()}`;

    return {
        method: method,
        url: rootUrl + pathPart + searchPart,
    };
}

function assertResponseBodyShape({
    endpoint,
    url,
    method,
    requestBody,
    requestBodyRuntype,
    responseBody,
    responseBodyRuntype,
}: {
    endpoint: string;
    url: string;
    method: string;
    requestBody: unknown;
    requestBodyRuntype: z.ZodTypeAny;
    responseBody: unknown;
    responseBodyRuntype: z.ZodTypeAny;
}) {
    const startTime = performance.now();
    try {
        // We only run it in production do find the issues first, because otherwise we would break dev.
        if (isProductionEnvironment() && Math.random() < 0.1) {
            requestBodyRuntype.parse(requestBody);
        }

        if (!isProductionEnvironment() || Math.random() < 0.1) {
            responseBodyRuntype.parse(responseBody);
        }
    } catch (runtypeError) {
        const msg = composeRuntypeAssertionErrorMessage({
            endpoint,
            url,
            method,
            requestBody,
            responseBody,
            runtypeError,
        });
        const ourError = new Error(msg);
        logToExternalErrorHandlers(ourError, { extra: { zodError: printFormattedRuntypeError(runtypeError) } });

        if (!isProductionEnvironment()) {
            throw ourError;
        }
    } finally {
        const endTime = performance.now();
        Sentry.setMeasurement('httpClient.parsing_time', endTime - startTime, 'millisecond');
    }
}

export type ExtractArguments<T extends RegisteredHttpEndpoint> = {
    pathParams: z.infer<EndpointRegistry[T]['pathParams']>;
    queryParams: z.infer<EndpointRegistry[T]['queryParams']>;
    requestBody: z.infer<EndpointRegistry[T]['requestBody']>;
    requestHeaders?: {
        Authorization: string | null;
    };
};

/**
 * Removes all properties from type `TObject` whose values are of type `TPropertyValue`.
 *
 * Examples (see unit tests for more examples):
 *
 * ```ts
 * type X = RemoveByValue<{ a: number, b: string, c: string}, number>
 * // X will be { b: string, c: string }
 *
 * type Y = RemoveByValue<{ key1: boolean, key2: undefined }, undefined>
 * // Y will be { key1: boolean }
 * ```
 *
 * We use this construct in the signature of the `http` function so that
 *
 * ```ts
 * http(
 *    'GET /simple/endpoint/without/params/and/body',
 *    { pathParams: {}, queryParams: {}, requestBody: {} },
 *    token
 * )
 * ```
 * becomes
 * ```ts
 * http(
 *    'GET /simple/endpoint/without/params/and/body',
 *    {},
 *    token
 * )
 * ```
 */
export type RemoveByValue<TObject, TPropertyValue> = Pick<
    TObject,
    { [Key in keyof TObject]-?: TObject[Key] extends TPropertyValue ? never : Key }[keyof TObject]
>;

function parseContent(response: Response) {
    if (response.headers.get('content-type')?.includes('application/json')) {
        return response.json();
    } else {
        return response.text();
    }
}

async function defaultResponseHandler(response: ResponseWithMethod, endpoint: string, requestBody: unknown) {
    await assertSuccessfulResponse(response, endpoint, requestBody);
    return await parseContent(response);
}

export function deriveHeadersAndBody<T extends RegisteredHttpEndpoint>(
    unsafeOptions: ExtractArguments<T>,
    token?: string,
): { headers: HeadersInit; body: BodyInit | undefined } {
    const { requestBody } = unsafeOptions;
    let body: BodyInit | undefined = undefined;
    let headers: HeadersInit = {};

    // Set the token header if a token is provided.
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    // The user can override the default headers, including the token.
    for (const [k, v] of Object.entries(unsafeOptions.requestHeaders ?? {})) {
        if (v === undefined || v === null) {
            delete headers[k];
        } else {
            headers[k] = v;
        }
    }

    if (requestBody instanceof FormData) {
        body = requestBody;
    } else if (requestBody) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(requestBody);
    }

    return { headers, body };
}

export type HttpOptions<T extends RegisteredHttpEndpoint> = RemoveByValue<ExtractArguments<T>, undefined>;

// eslint-disable-next-line max-params
export async function http<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    options: HttpOptions<T>,
    token?: string,
): Promise<z.infer<EndpointRegistry[T]['responseBody']>> {
    const span = Sentry.getActiveSpan();
    const startTime = performance.now();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const unsafeOptions = options as ExtractArguments<T>;

    const endpointDef = endpointRegistry[endpoint];

    const { method, url } = buildUrl({
        rootUrl: endpointDef.rootUrl ?? BACKEND_BASE,
        endpoint,
        pathParams: unsafeOptions.pathParams ?? {},
        queryParams: unsafeOptions.queryParams ?? {},
    });

    const requestBody = unsafeOptions.requestBody;

    const { headers, body } = deriveHeadersAndBody(unsafeOptions, token);

    const response = await fetch(url, {
        method,
        headers,
        body,
    }).catch((e) => {
        logToExternalErrorHandlers(e, { extra: { endpoint }, fingerprint: ['FETCH_ERROR'] });
        if (e instanceof TypeError) {
            throw new NetworkError({ endpoint });
        } else {
            throw e;
        }
    });

    const responseHandler = endpointDef.handleResponse ?? defaultResponseHandler;
    const responseBody = await responseHandler(Object.assign(response, { method }), endpoint, requestBody);

    // Performance optimization: Cast to 'any' to bypass TypeScript's type checking for large response types
    // This prevents TypeScript from analyzing complex nested types that can significantly slow down the IDE
    assertResponseBodyShape({
        endpoint,
        url,
        method,
        requestBody,
        requestBodyRuntype: (endpointDef as any).requestBody,
        responseBody,
        responseBodyRuntype: (endpointDef as any).responseBody,
    });

    const endTime = performance.now();

    span?.setAttributes({
        'httpClient.method': method,
        'httpClient.endpoint': endpoint,
        'httpClient.code': String(response.status),
        'httpClient.status_text': response.statusText,
        'httpClient.host_name': HOST_NAME,
    });
    Sentry.setMeasurement('httpClient.total_time', endTime - startTime, 'millisecond');

    return responseBody;
}
